import { useMemo } from "react";

import { ServiceGroup, Service } from "../../types";

export const useServiceGroupServices = (
  id: ServiceGroup["id"] | undefined,
  data: Service[]
): Service[] => {
  const services = useMemo(() => {
    if (typeof id === "number") {
      return [...data].filter(({ groupId }) => groupId === id);
    }

    return [];
  }, [id, data]);
  return services;
};
