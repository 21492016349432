let notificationTimeout = null;

/**
 * Actions
 */

export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

/**
 * Action creators
 */

export function showNotification(
    title,
    text,
    timeout = 10000,
    linkUrl = "",
    linkText = ""
) {
    return function (dispatch) {
        dispatch(open({ title, text, linkUrl, linkText }));

        clearTimeout(notificationTimeout);

        notificationTimeout = setTimeout(() => {
            dispatch(close());
        }, timeout);
    };

    function open(payload) {
        return { type: OPEN_NOTIFICATION, payload: payload };
    }
    function close() {
        return { type: CLOSE_NOTIFICATION };
    }
}

export function closeNotification() {
    clearTimeout(notificationTimeout);

    return { type: CLOSE_NOTIFICATION };
}

/**
 * Initial state of the store
 */

export const initialState = {
    show: false,
    title: "",
    text: "",
    linkUrl: "",
    linkText: "",
};

/**
 * Reducer
 */
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_NOTIFICATION: {
            const y = Object.assign({}, state);
            const { title, text, linkUrl, linkText } = action.payload;

            y.show = true;
            y.title = title;
            y.text = text;
            y.linkUrl = linkUrl;
            y.linkText = linkText;

            return y;
        }

        case CLOSE_NOTIFICATION: {
            const y = Object.assign({}, state);

            y.show = false;

            return y;
        }

        default:
            return state;
    }
}
