import React, { useEffect, useMemo, useState, useRef } from "react";
import GoogleMapReact, { Coords } from "google-map-react";

import { Translate } from "react-localize-redux";

import { Barbershop, BarbershopQueueStatus, QueueState } from "../../types";
import { ReactComponent as CloseIcon } from "../../assets/images/icon-cross.svg";
import {
  getGoogleMapsApiKey,
  getGoogleMapsStyles,
  getLocaleCenterPoint,
} from "../../utils/google-maps";
import {
  isEmptyObject,
  shouldForceBarbershopTakeOrders,
} from "../../utils/Helpers";
import BarbershopMarkerButton from "../../components/BarbershopMarkerButton";
import Timer from "../../components/Timer";
import { useUserLocation } from "../user/use-user-location";
import MapMarker from "../../components/MapMarker";

const API_KEY = getGoogleMapsApiKey();
const MAP_STYLES = getGoogleMapsStyles();
const MAP_ZOOM = 12;

const MAP_OPTIONS = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: "greedy",
  backgroundColor: "transparent",
};

const LOCALE_CENTER_POINT = getLocaleCenterPoint();

interface BarbershopsMapProps {
  barbershops: Barbershop[];
  onClose: () => void;
}

const BarbershopsMap: React.FC<BarbershopsMapProps> = ({
  barbershops,
  onClose,
}) => {
  const { lat, lng, isLocated } = useUserLocation();
  const prevLat = useRef(lat);
  const prevLng = useRef(lng);
  const [center, setCenter] = useState<Coords>({ lat, lng });
  const hasCenter = center.lat !== 0 && center.lng !== 0;

  /**
   * If user location data is not available, use locale
   * based center points.
   */
  useEffect(() => {
    if (prevLat.current === 0 || prevLng.current === 0) {
      setCenter(LOCALE_CENTER_POINT);
    }
  }, []);

  /**
   * Only update center point automatically if user
   * location data becomes available when it wasn't before.
   */
  useEffect(() => {
    if (prevLat.current === 0 && lat !== 0) {
      setCenter({ lat, lng });
    }
  }, [lat, lng]);

  /**
   * Get the order status of a barbershop.
   * @NOTE Copied from import { useBarbershopQueueStatus } from "../barbershop/use-barbershop-queue-status";
   * Since can't use the useEffect hooks inside useMemo need to refaactor later
   */
  /**
   * Get the order status of a barbershop.
   */
  const getBarbershopQueueStatus = (
    barbershop: Barbershop
  ): BarbershopQueueStatus => {
    if (shouldForceBarbershopTakeOrders()) {
      return "taking-orders";
    }

    const { queue, closingSoon, queueState, open } = barbershop || {};

    if (isEmptyObject(barbershop)) {
      return "unknown";
    }

    if (typeof queue === "undefined" || queue === null) {
      return "unknown";
    }

    if (!open || closingSoon) {
      return "closed";
    }

    /**
     * API doesn't return a "full" queueState as it should,
     * so we need to check if barbershop is open but queue is not as well.
     */
    if (
      queueState === QueueState.full ||
      (open && queueState !== QueueState.open)
    ) {
      return "full";
    }

    return "taking-orders";
  };

  const barbershopMarkers = useMemo(() => {
    return barbershops.map((barbershop) => {
      if (isNaN(barbershop.coords.lat) || isNaN(barbershop.coords.lng))
        return false;

      const { id, coords, queue } = barbershop || {};
      const { lat, lng } = coords || {};

      const status = getBarbershopQueueStatus(barbershop);

      return (
        <BarbershopMarkerButton
          key={id}
          barbershop={barbershop}
          lat={lat}
          lng={lng}
        >
          {status === "taking-orders" ? (
            <Timer minutes={queue} size="tiny" />
          ) : (
            <div className="c-barbershop-card__message">
              {status === "full" ? (
                <Translate id="list.noOrders" />
              ) : (
                <Translate id="list.closed" />
              )}
            </div>
          )}
        </BarbershopMarkerButton>
      );
    });
  }, [barbershops]);

  return (
    <div className="c-barbershops-map">
      <div className="c-barbershops-map__container">
        {hasCenter && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            center={center}
            zoom={MAP_ZOOM}
            options={{ ...MAP_OPTIONS, styles: MAP_STYLES }}
          >
            {isLocated && <MapMarker lat={lat} lng={lng} />}

            {barbershopMarkers}
          </GoogleMapReact>
        )}
      </div>
      <button
        className="c-barbershops-map__close h-button-wrapper"
        onClick={onClose}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default BarbershopsMap;
