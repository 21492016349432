import data from "../data/messages.json";
import * as userService from "../services/user";

/**
 * Actions
 */

export const MARK_MESSAGE_READ = "MARK_MESSAGE_READ";
export const FILTER_MESSAGES_WITH_LOCALE = "FILTER_MESSAGES_WITH_LOCALE";

/**
 * Action creators
 */

export function markMessageRead(id) {
  return { type: MARK_MESSAGE_READ, id };
}

export function filterMessagesWithLocale(locale) {
  return { type: FILTER_MESSAGES_WITH_LOCALE, locale };
}

/**
 * Initial state of the store
 */

const locale = userService.getLocale();
const read = JSON.parse(localStorage.getItem("readMessages")) || [];
const messages = filterMessages(data, locale);

export const initialState = {
  data,
  messages,
  read,
  unreadCount: countUnreadMessages(messages, read),
};

/**
 * Reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MARK_MESSAGE_READ: {
      const y = Object.assign({}, state);

      if (!y.read.includes(action.id)) {
        y.read.push(action.id);
        y.unreadCount = countUnreadMessages(y.messages, y.read);

        localStorage.setItem("readMessages", JSON.stringify(y.read));
      }

      return y;
    }

    case FILTER_MESSAGES_WITH_LOCALE: {
      const y = Object.assign({}, state);

      y.messages = filterMessages(y.data, action.locale);

      return y;
    }

    default:
      return state;
  }
}

/**
 * Helper functions
 */
function filterMessages(data, locale) {
  if (data && locale && data[locale]) {
    return data[locale].sort((a, b) => {
      // Sort latest message first
      const aDate = Date.parse(a.date);
      const bDate = Date.parse(b.date);

      if (aDate === bDate) {
        return 0;
      } else {
        return aDate > bDate ? -1 : 1;
      }
    });
  }

  return [];
}

function countUnreadMessages(messages, read) {
  const unread = messages.filter((message) => !read.includes(message.id));

  // Loop through all messages and return only the ones that are newer than 1 month
  const filteredUnreads = unread.filter((message) => {
    const messageDate = new Date(message.date);
    const date = new Date();
    const monthAgo = date.setMonth(date.getMonth() - 1);
    return messageDate > monthAgo;
  });

  return filteredUnreads.length;
}

export const selectUnreadMessagesCount = (state) => state.messages.unreadCount;
