import { useMemo } from "react";
import { brandImageUrls } from "./brand-images";

export const useRandomBrandImageUrl = (seed: number): string | false => {
  const url = useMemo(() => {
    if (typeof seed !== "number") {
      return false;
    }

    const index = seed % brandImageUrls.length;
    return brandImageUrls[index];
  }, [seed]);

  return url;
};
