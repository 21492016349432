import React, { useEffect } from "react";

import SlideUp from "../animations/SlideUp";
import ShopMap from "../../components/ShopMap";
import { Barbershop } from "../../types";
import { analytics } from "../../utils/Analytics";

interface BarbershopMapProps {
  className?: string;
  barbershop: false | Barbershop;
  analyticsPathName: string;
  onClick?: () => void;
}

const BarbershopMap: React.FC<BarbershopMapProps> = ({
  className,
  barbershop,
  analyticsPathName,
  onClick,
}) => {
  const { coords } = barbershop || {};
  const hasCoords = !!coords;

  useEffect(() => {
    if (analyticsPathName) {
      analytics.pageView(analyticsPathName, "Barbershop Map");
    }
  }, [analyticsPathName]);

  return (
    <SlideUp className={className}>
      {hasCoords && <ShopMap position={coords} closeClickHandler={onClick} />}
    </SlideUp>
  );
};

export default BarbershopMap;
