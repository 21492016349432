/**
 * These are the constants for the different user account scenarios.
 * They should always be kept in sync with what the API returns.
 */

const userConstants = {
  status: {
    GUEST: 1,
    DEFAULT: 2,
    QUEUE: 3,
    CHECK_IN: 4,
  },
  MEMBERSHIPS: ["Silver", "Gold", "Platinum"],
  ALLOWED_LANGUAGES: ["fi", "en", "no", "sv", "dk", "de"],
};

export default userConstants;
