import gsap from "gsap";

/**
 * @deprecated Use slideOpen Framer Motion instead
 */
export const deprecated__slideOpen = (el, duration = 0.25, delay = 0) => {
  if (el === null) return false;

  gsap.set(el, { opacity: 1, height: "auto" });
  gsap.from(el, {
    duration: duration,
    opacity: 0,
    height: 0,
    delay: delay,
    ease: "expo.out",
  });
};

/**
 * @deprecated Use slideOpen Framer Motion instead
 */
export const deprecated__slideClose = (el, duration = 0.25, delay = 0) => {
  if (el === null) return false;

  gsap.to(el, {
    duration: duration,
    opacity: 0,
    height: 0,
    delay: delay,
    ease: "expo.out",
  });
};
