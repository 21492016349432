import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Spinner = ({ scheme, size }) => {
    const componentClass = classNames({
        "c-spinner": true,
        [`c-spinner--${scheme}`]: scheme,
        [`c-spinner--${size}`]: size
    });

    return <div className={componentClass} />;
};

Spinner.propTypes = {
    scheme: PropTypes.string,
    size: PropTypes.string,
    tease: PropTypes.bool
};

export default Spinner;
