import React from "react";
import ContentLoader from "react-content-loader";
import { Translate } from "react-localize-redux";

import OpeningHours from "../../components/OpeningHours";
import { Barbershop } from "../../types";
import { loaderTheme } from "../../utils/loaders";
import { ReactComponent as ScissorIcon } from "../../assets/images/icon-scissor.svg";

interface BarbershopDetailsProps {
  className?: string;
  barbershop: Barbershop;
}

const BarbershopDetails: React.FC<BarbershopDetailsProps> = ({
  className,
  barbershop,
}) => {
  const { name, city, address, openingHours, chairCount } = barbershop || {};
  const hasData = !!name;
  const hasOpeningHours = !!openingHours;

  /**
   * This is a hacky way to get around a weird
   * TypeScript bug. Should be fixed by typing the
   * OpeningHours component instead.
   */
  const openingHourProps = {
    times: openingHours,
  };

  const titleClass = `${className}__title`;
  const addressClass = `${className}__address`;
  const openingHoursClass = `${className}__opening-hours`;
  const chairCountClass = `${className}__chair-count`;

  if (!hasData) {
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={className}>
      {chairCount && chairCount !== "0" && (
        <div className={chairCountClass}>
          <span className="c-barbershop-card__barbers">
            <ScissorIcon width={15} height={15} />
            {chairCount}
            {chairCount === "1" ? (
              <span>
                <Translate id="barbershop.chairCount" />
              </span>
            ) : (
              <span>
                <Translate id="barbershop.chairCountSuffix" />
              </span>
            )}
          </span>
        </div>
      )}
      <h1 className={titleClass}>{`${name}, ${city}`}</h1>
      <p className={addressClass}>{address}</p>
      {hasOpeningHours && (
        <p className={openingHoursClass}>
          <OpeningHours {...openingHourProps} />
        </p>
      )}
    </div>
  );
};

export default BarbershopDetails;

const Loader = () => (
  <ContentLoader
    viewBox="0 0 315 100"
    {...loaderTheme}
    foregroundOpacity={0.5}
    backgroundOpacity={0.5}
    width={"100%"}
    height={95}
    style={{ width: "100%", display: "block" }}
    preserveAspectRatio="none"
  >
    <rect x="0" y="2" rx="5" ry="5" width="210" height="22" />
    <rect x="0" y="52" rx="3" ry="3" width="135" height="12" />
    <rect x="0" y="87" rx="3" ry="3" width="185" height="12" />
  </ContentLoader>
);
