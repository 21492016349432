import React, { useState } from "react";
import classNames from "classnames";

import { useScrollHandler } from "../utils/use-scroll-handler";

type ChildrenWithIsVisible = (isVisible: boolean) => React.ReactNode;

interface FixedHeaderProps {
  children: React.ReactNode | ChildrenWithIsVisible;
  scrollWrapperRef?: React.RefObject<HTMLDivElement>;
  offset?: number;
  height: number;
}

const FixedHeader: React.FC<FixedHeaderProps> = ({
  scrollWrapperRef,
  offset = 0,
  height,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const shouldBeVisible = isScrolledEnough(scrollWrapperRef, offset + height);

    if (shouldBeVisible !== isVisible) {
      setIsVisible(shouldBeVisible);
    }
  };

  useScrollHandler(handleScroll, scrollWrapperRef);

  const componentClass = classNames("c-fixed-header", {
    "is-visible": isVisible,
  });

  return (
    <div className={componentClass} style={{ height }}>
      {typeof children === "function" ? children(isVisible) : children}
    </div>
  );
};

export default FixedHeader;

const isScrolledEnough = (
  ref?: React.RefObject<HTMLDivElement>,
  offset?: number
): boolean => {
  if (!ref?.current || !offset) {
    return true;
  }

  return ref.current.scrollTop > offset;
};
