import axios from "axios";

import config from "../config/ip-info.json";

/**
 * Fetches generic user location data from ipinfo.io
 *
 * @return {Promise.<object, Error>} Resolves with object containing some location data, rejects with errors
 */
export const getIpInfo = () => {
  return new Promise((resolve, reject) => {
    const { apiKey } = config || {};

    axios
      .get(`https://ipinfo.io/json?token=${apiKey}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
