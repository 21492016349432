import img01 from "../../assets/images/brand/brand-image-01.jpg";
import img02 from "../../assets/images/brand/brand-image-02.jpg";
import img03 from "../../assets/images/brand/brand-image-03.jpg";
import img04 from "../../assets/images/brand/brand-image-04.jpg";
import img05 from "../../assets/images/brand/brand-image-05.jpg";
import img06 from "../../assets/images/brand/brand-image-06.jpg";
import img07 from "../../assets/images/brand/brand-image-07.jpg";
import img08 from "../../assets/images/brand/brand-image-08.jpg";
import img09 from "../../assets/images/brand/brand-image-09.jpg";
import img10 from "../../assets/images/brand/brand-image-10.jpg";
import img11 from "../../assets/images/brand/brand-image-11.jpg";
import img12 from "../../assets/images/brand/brand-image-12.jpg";

export const brandImageUrls = [
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
];
