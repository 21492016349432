import { useState, useEffect } from "react";

import { Barbershop, BarbershopQueueStatus, QueueState } from "../../types";
import {
  isEmptyObject,
  shouldForceBarbershopTakeOrders,
} from "../../utils/Helpers";

export const useBarbershopQueueStatus = (
  barbershop: Barbershop
): BarbershopQueueStatus => {
  const [status, setStatus] = useState<BarbershopQueueStatus>("unknown");

  useEffect(() => {
    setStatus(getBarbershopQueueStatus(barbershop));
  }, [barbershop]);

  return status;
};

/**
 * Get the order status of a barbershop.
 */
export const getBarbershopQueueStatus = (
  barbershop: Barbershop
): BarbershopQueueStatus => {
  if (shouldForceBarbershopTakeOrders()) {
    return "taking-orders";
  }

  const { queue, closingSoon, queueState, open } = barbershop || {};

  if (isEmptyObject(barbershop)) {
    return "unknown";
  }

  if (typeof queue === "undefined" || queue === null) {
    return "unknown";
  }

  if (!open || closingSoon) {
    return "closed";
  }

  /**
   * API doesn't return a "full" queueState as it should,
   * so we need to check if barbershop is open but queue is not as well.
   */
  if (
    queueState === QueueState.full ||
    (open && queueState !== QueueState.open)
  ) {
    return "full";
  }

  return "taking-orders";
};
