import React, { useState, useRef, useEffect } from "react";

export const usePreviousState = <T>(
  initialValue: T
): [T, T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(initialValue);
  const previousValue = useRef<T>(value);

  useEffect(() => {
    previousValue.current = value;
  }, [value]);

  return [value, previousValue.current, setValue];
};
