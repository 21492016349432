import React from "react";
import { TranslateString } from "../../types";

interface BenefitNoticeProps {
  text: TranslateString;
}

const BenefitNotice: React.FC<BenefitNoticeProps> = ({ text }) => {
  return (
    <div className="c-benefit-notice">
      <p className="c-benefit-notice__text">{text}</p>
    </div>
  );
};

export default BenefitNotice;
