import React, { useEffect } from "react";
import { Loading } from "../types";
import { useKeenSlider } from "keen-slider/react";
import { times } from "lodash";

interface SliderProps<T> {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  loaderItem: React.ReactNode;
  loading: Loading;
  noDataAvailable: React.ReactNode;
  slidesPerView?: number;
  spacing?: number;
}

const Slider = <T extends unknown>({
  data,
  renderItem,
  loaderItem,
  loading,
  noDataAvailable,
  slidesPerView = 2,
  spacing = 20,
}: SliderProps<T>) => {
  const [refCallback, slider] = useKeenSlider({
    loop: false,
    selector: ".c-slider__slide",
    rtl: false,
    slides: { perView: slidesPerView, spacing },
  });

  const hasData = !!(data && data.length);

  /**
   * For some reason this is needed or it doesn't
   * render the slider correctly when the children change.
   */
  useEffect(() => {
    if (slider.current) {
      slider.current.update();
    }
  }, [hasData, loading, slider]);

  return (
    <div className="c-slider">
      <div ref={refCallback} className="keen-slider">
        {hasData ? (
          data.map((item, index) => (
            <div className="c-slider__slide" key={`item-${index}`}>
              {renderItem(item)}
            </div>
          ))
        ) : loading === "fulfilled" ? (
          <div className="c-slider__slide" key="no-data-available">
            {noDataAvailable}
          </div>
        ) : (
          times(Math.floor(slidesPerView), (index) => (
            <div className="c-slider__slide" key={`loader-${index}`}>
              {loaderItem}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Slider;
