import React from "react";
import { motion } from "framer-motion";

import { zoomOut } from "./utils";
import { SpringAnimationComponentProps } from "./types";

const ZoomOut: React.FC<SpringAnimationComponentProps> = ({
  children,
  bounce = 0.15,
  duration = 0.5,
  delay = 0,
  ...props
}) => {
  return (
    <motion.div
      {...zoomOut}
      {...props}
      transition={{ type: "spring", bounce, duration, delay }}
    >
      {children}
    </motion.div>
  );
};

export default ZoomOut;
