import React, { useState, useCallback, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { Translate, withLocalize } from "react-localize-redux";

import BackButton from "../components/BackButton";
import TextInput from "../components/TextInput";
import { useProfile } from "../features/user/use-profile";
import { Loading, PageProps } from "../types";
import { loaderTheme } from "../utils/loaders";
import ConfirmButton from "../components/ConfirmButton";
import { useEmailInput } from "../utils/forms";
import { patchProfile } from "../services/user";
import { setPageTitle } from "../utils/seo";
import { analytics } from "../utils/Analytics";

const EmailSettings: React.FC<PageProps> = ({ translate, activeLanguage }) => {
  const { profile, loading, refresh } = useProfile();
  const { email } = profile || {};
  const hasEmail = !!email;

  const { input, setInput, hasNewInput, focus, blur, isValid, hasError } =
    useEmailInput();
  const [sending, setSending] = useState<Loading>("idle");

  const handleClick = useCallback(() => {
    setSending("pending");

    patchProfile({
      email: input,
    })
      .then(() => {
        setSending("fulfilled");
      })
      .catch(() => {
        setSending("rejected");
      });
  }, [input]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
  };

  useEffect(() => {
    if (sending === "fulfilled" && refresh) {
      refresh();
    }
  }, [sending, refresh]);

  useEffect(() => {
    if (sending === "fulfilled" && hasNewInput) {
      setSending("idle");
    }
  }, [sending, hasNewInput]);

  /**
   * SEO & Analytics
   */
  useEffect(() => {
    if (activeLanguage) {
      setPageTitle([
        translate("title.base"),
        translate("title.emailPermission"),
      ]);
    }
  }, [activeLanguage, translate]);

  useEffect(() => {
    analytics.pageView("/profile/email", "Email Settings");
  }, []);

  return (
    <div className="s-settings">
      <div className="s-settings__back">
        <BackButton fallbackRoute="/profile" transition="switch-close" />
      </div>

      <div className="s-settings__header">
        <h1 className="s-settings__title">
          <Translate id="emailSettings.title" />
        </h1>

        <p className="s-settings__subtitle">
          {hasEmail ? (
            email
          ) : loading === "pending" ? (
            <ContentLoader
              viewBox="0 0 200 15"
              width={200}
              height={15}
              {...loaderTheme}
              preserveAspectRatio="none"
            >
              <rect x="0" y="0" rx="3" ry="3" width="200" height="15" />
            </ContentLoader>
          ) : null}
        </p>
      </div>

      <div className="s-settings__content">
        <h2 className="s-settings__content-title">
          {translate("emailSettings.subtitle").toString()}
        </h2>

        <TextInput
          type="email"
          name="email"
          placeholder={translate("emailSettings.placeholder").toString()}
          required
          value={input}
          onChange={handleChange}
          onFocus={focus}
          onBlur={blur}
          hasError={hasError}
          isValid={isValid}
        />

        {hasError && (
          <div className="c-email-form__error-message">
            <Translate id="bonusTeaser.needsEmail.emailError" />
          </div>
        )}

        <div style={{ marginTop: 25 }}>
          <ConfirmButton
            text={translate("emailSettings.cta").toString()}
            loading={sending}
            onClick={handleClick}
            disabled={!isValid}
          />
        </div>
      </div>
    </div>
  );
};

export default withLocalize(EmailSettings);
