import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Barbershop, Loading } from "../../types";
import * as orderService from "../../services/order";
import * as barbershopService from "../../services/barbershop";
import {
  idArrayToDataArray,
  indexedIdArrayToIndexedDataArray,
} from "../../utils/data";
import ApiConstants from "../../constants/api";

const { MROOM_API_ERROR_TIMESLOT } = ApiConstants;

export const fetchBarbershop = createAsyncThunk(
  "order/fetchBarbershop",
  async (id: number, { rejectWithValue }) => {
    const response = barbershopService.getBarbershop(id).then(
      (barbershop) => barbershop as Barbershop,
      (error) => rejectWithValue(error?.data?.error)
    );

    return response;
  }
);

export const sendOrder = createAsyncThunk(
  "order/sendOrder",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const barbershop = selectOrderBarbershop(state);
    const services = selectOrderServices(state);
    const extraServices = selectOrderExtraServices(state);

    const response = orderService
      .sendOrder(barbershop, services, extraServices)
      .then(
        (orderId) => orderId as number,
        (error) => rejectWithValue(error?.data?.error)
      );

    return response;
  }
);

export type OrderStatus =
  | "idle"
  | "pending"
  | "accepted"
  | "confirmed"
  | "failed";

type Error = false | "timeslot" | "unknown";

export interface OrderState {
  loading: Loading;
  barbershop: Barbershop;
  status: OrderStatus;
  serviceIds: number[];
  extraServiceIds: { [serviceId: string]: number[] };
  sending: Loading;
  error: Error;
  errorMessage: string;
}

interface ExtraServicePayload {
  serviceId: number;
  id: number;
}

const initialState: OrderState = {
  loading: "idle",
  barbershop: {} as Barbershop,
  status: "idle",
  serviceIds: [],
  extraServiceIds: {},
  sending: "idle",
  error: false,
  errorMessage: "",
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addService: (state, action: PayloadAction<number>) => {
      state.serviceIds.push(action.payload);
      state.status = "pending";
    },
    removeService: (state, action: PayloadAction<number>) => {
      const { payload: id } = action;

      state.serviceIds = state.serviceIds.filter((each) => each !== id);

      if (state.extraServiceIds[id]) {
        delete state.extraServiceIds[id];
      }

      if (!state.serviceIds.length) {
        state.status = "idle";
      }
    },
    addExtraService: {
      reducer: (state, action: PayloadAction<ExtraServicePayload>) => {
        const { serviceId, id } = action.payload;

        if (state.extraServiceIds[serviceId]) {
          state.extraServiceIds[serviceId].push(id);
        } else {
          state.extraServiceIds[serviceId] = [id];
        }
      },
      prepare: (serviceId: number, id: number) => {
        return { payload: { serviceId, id } };
      },
    },
    removeExtraService: {
      reducer: (state, action: PayloadAction<ExtraServicePayload>) => {
        const { serviceId, id } = action.payload;

        state.extraServiceIds[serviceId] = state.extraServiceIds[
          serviceId
        ].filter((each) => each !== id);

        if (!state.extraServiceIds[serviceId].length) {
          delete state.extraServiceIds[serviceId];
        }
      },
      prepare: (serviceId: number, id: number) => {
        return { payload: { serviceId, id } };
      },
    },
    acceptOrder: (state) => {
      state.status = "accepted";
    },
    editOrder: (state) => {
      state.status = "pending";
    },
    clearOrder: () => initialState,
  },
  extraReducers: (builder) => {
    // order/fetchBarbershop
    builder.addCase(fetchBarbershop.pending, (state, action) => {
      state.loading = "pending";
    });

    builder.addCase(fetchBarbershop.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.barbershop = action.payload as Barbershop;
    });

    builder.addCase(fetchBarbershop.rejected, (state) => {
      state.loading = "rejected";
      state.barbershop = {} as Barbershop;
    });

    // order/sendOrder
    builder.addCase(sendOrder.pending, (state) => {
      state.sending = "pending";
      state.error = false;
      state.errorMessage = "";
    });

    builder.addCase(sendOrder.fulfilled, (state) => {
      state.sending = "fulfilled";
    });

    builder.addCase(sendOrder.rejected, (state, action) => {
      state.sending = "rejected";

      if (action.payload === MROOM_API_ERROR_TIMESLOT) {
        state.error = "timeslot";
      } else {
        state.error = "unknown";
        state.errorMessage = String(action.payload);
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  addService,
  removeService,
  addExtraService,
  removeExtraService,
  acceptOrder,
  editOrder,
  clearOrder,
} = orderSlice.actions;

export default orderSlice.reducer;

export const selectOrderLoading = (state: RootState) => state.order.loading;
export const selectOrderBarbershop = (state: RootState) =>
  state.order.barbershop;

export const selectOrderStatus = (state: RootState) => state.order.status;
export const selectOrderServiceIds = (state: RootState) =>
  state.order.serviceIds;
export const selectOrderExtraServiceIds = (state: RootState) =>
  state.order.extraServiceIds;

export const selectOrderExtraServiceIdsByServiceId = createSelector(
  [selectOrderExtraServiceIds, (_: any, serviceId: number) => serviceId],
  (ids, serviceId) => ids[serviceId] || []
);

export const selectOrderServices = createSelector(
  [selectOrderBarbershop, selectOrderServiceIds],
  ({ services: data }, ids) => idArrayToDataArray(ids, data)
);

/**
 * Extra service ids are stored in an object, where
 * the parent service id is used as the index.
 *
 * We build a similar object but replace the extra service
 * ids with full data instead.
 */
export const selectOrderExtraServices = createSelector(
  [selectOrderBarbershop, selectOrderExtraServiceIds],
  ({ extraServices: data }, indexedIds) =>
    indexedIdArrayToIndexedDataArray(indexedIds, data)
);

export const selectOrderSending = (state: RootState) => state.order.sending;
export const selectOrderError = (state: RootState) => state.order.error;
export const selectOrderErrorMessage = (state: RootState) =>
  state.order.errorMessage;
