import React from "react";
import PropTypes from "prop-types";
import * as helpers from "../utils/Helpers";

class OptionListing extends React.Component {
  buildExtras(extras) {
    return extras.map((extra, index) => {
      const units = extra.price ? `– ${helpers.priceToUnits(extra.price)}` : "";

      return (
        <p key={index} className="c-service-small__extra">
          {`+ ${extra.name} ${units}`}
        </p>
      );
    });
  }

  render() {
    const extras = this.buildExtras(this.props.extras);
    const units = helpers.priceToUnits(this.props.price);

    return (
      <div className="c-service-small">
        <h3 className="c-service-small__title">{this.props.title}</h3>
        <p className="c-service-small__description">{this.props.description}</p>

        {units && <p className="c-service-small__unit">{units}</p>}

        {extras}
      </div>
    );
  }
}

OptionListing.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  extras: PropTypes.array,
};

export default OptionListing;
