import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import * as userService from "../../services/user";
import { locateUser } from "../../ducks/user";
import { getStoredGeolocationPermission } from "../../utils/geolocation-api";

export function useSilentLocationUpdate() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    const checkGeolocationStatus = async () => {
      const permission = await getStoredGeolocationPermission();

      if (permission === "granted") {
        dispatch(locateUser());
      } else if (permission === "denied") {
        userService.clearUserLocation();
      }

      setStatus(permission);
    };

    checkGeolocationStatus();
  }, [dispatch]);

  return status;
}
