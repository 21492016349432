import classNames from "classnames";
import React from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  isValid?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  hasError,
  isValid,
  ...rest
}) => {
  const componentClass = classNames("c-text-input", {
    "has-error": hasError,
    "is-valid": isValid,
  });

  return <input className={componentClass} {...rest} />;
};

export default TextInput;
