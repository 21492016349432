import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuItem from "../components/MenuItem";
import MembershipBadge from "../components/MembershipBadge";
import history from "../utils/navigation";
import * as helpers from "../utils/Helpers";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";
import PageHeader from "../components/PageHeader";

import { showNotification } from "../ducks/notification";
import { syncUserProfile, logout } from "../ducks/user";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.titleSet = false;
    this.blockTransition = false;

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  componentDidMount() {
    // store current location for back buttons
    window.previousLocation = this.props.location;

    // sync profile from API to make sure data is up to date
    this.props.actions.syncUserProfile();

    analytics.pageView("/profile", "Profile");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // if the login has expired, log the user out
    if (!newProps.loggedIn && !this.blockTransition) {
      this.props.actions.logout();
      this.props.actions.showNotification(
        newProps.translate("error.tokenTitle"),
        newProps.translate("error.tokenText")
      );

      this.blockTransition = true;
    }

    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.profile");
      this.titleSet = true;
    }
  }

  handleLogoutClick() {
    this.props.actions.logout();

    history.push({
      pathname: "/",
      state: {
        transition: "instant",
        timeout: 0,
      },
    });
  }

  render() {
    const profile = this.props.profile || {};
    const hasName = profile.firstname && profile.lastname;
    let isMember = false;

    if (profile && profile.membership && profile.membership.type) {
      isMember = true;
    }

    const componentClass = classNames({
      "s-profile": true,
      "h-scrollable": true,
      "s-profile--member": isMember,
    });

    const emailPermissionSubtitle = profile
      ? profile.allow_email
        ? this.props.translate("profile.emailPermissionAllowed")
        : this.props.translate("profile.emailPermissionDisallowed")
      : "-";

    const activeLanguage = this.props.activeLanguage || {};

    return (
      <div className={componentClass}>
        <div className="s-profile__wrapper">
          <PageHeader title={<Translate id="title.profile" />} />

          {hasName && (
            <h2 className="h1 s-profile__name">{`${profile.firstname} ${profile.lastname}`}</h2>
          )}
          <MenuItem
            title={this.props.translate("profile.phoneLabel")}
            subtitle={profile.phone}
          />

          {isMember && (
            <MenuItem
              title={this.props.translate("profile.membershipEndsLabel")}
              subtitle={helpers.convertMembershipDate(
                profile.membership.end_date
              )}
            />
          )}

          {isMember && (
            <MenuItem
              title={this.props.translate("profile.creditsLabel")}
              link="/profile/credits"
            />
          )}

          <MenuItem
            title={this.props.translate("messages.title")}
            link="/profile/messages"
            badge={this.props.unreadCount}
          />

          <MenuItem
            title={this.props.translate("profile.orderHistoryLabel")}
            link="/profile/history"
          />

          <h2 className="s-profile__title">
            {this.props.translate("profile.titleSettings")}
          </h2>

          <MenuItem
            title={this.props.translate("profile.emailLabel")}
            subtitle={profile.email}
            link="/profile/email"
          />

          <MenuItem
            title={this.props.translate("profile.emailPermissionLabel")}
            subtitle={emailPermissionSubtitle}
            link="/profile/email-permission"
          />

          <MenuItem
            title={this.props.translate("profile.notificationsLabel")}
            link="/profile/notifications"
          />

          <MenuItem
            title={this.props.translate("profile.passwordLabel")}
            link="/profile/password"
          />

          <MenuItem
            title={this.props.translate("profile.languageLabel")}
            subtitle={activeLanguage.name}
            link="/profile/language"
          />

          <MenuItem
            title={this.props.translate("profile.logoutLabel")}
            clickHandler={this.handleLogoutClick}
            priority
          />

          {isMember && (
            <div className="s-profile__membership">
              <MembershipBadge
                type={profile.membership.type}
                text="MEMBER"
                shape="ribbon"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  location: PropTypes.object,
  actions: PropTypes.object,
  profile: PropTypes.object,
  loggedIn: PropTypes.bool,
  translate: PropTypes.func,
  unreadCount: PropTypes.number,
  activeLanguage: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loggedIn: state.user.loggedIn,
    profile: state.user.profile,
    latitude: state.user.latitude,
    longitude: state.user.longitude,
    unreadCount: state.messages.unreadCount,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        showNotification,
        syncUserProfile,
        logout,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Profile));
