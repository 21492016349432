import React, { useState, useCallback } from "react";
import { motion, useDragControls, useAnimation, PanInfo } from "framer-motion";
import FadeIn from "../features/animations/FadeIn";
import SlideUp from "../features/animations/SlideUp";

const MAX_DRAG = window.innerHeight * 0.6; // 60vh
const DRAG_THRESHOLD = MAX_DRAG / 3;

interface DrawerProps {
  onClose?: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ onClose, children }) => {
  const dragControls = useDragControls();
  const controls = useAnimation();

  const startDrag = (event: any) => {
    dragControls.start(event);
  };

  const handleDragEnd = (_: any, info: PanInfo) => {
    const offset = info.offset.y;

    if (offset >= DRAG_THRESHOLD && onClose) {
      onClose();
    } else {
      controls.start({ y: 0, transition: { duration: 0.5 } });
    }
  };

  return (
    <div className="c-drawer">
      <FadeIn className="c-drawer__background" onClick={onClose} />
      <SlideUp className="c-drawer__content-wrapper" bounce={0} duration={0.5}>
        <motion.div
          drag={"y"}
          dragControls={dragControls}
          dragConstraints={{ top: 0, bottom: MAX_DRAG }}
          dragElastic={0}
          dragListener={false}
          onDragEnd={handleDragEnd}
          animate={controls}
          className="c-drawer__content"
        >
          <div onPointerDown={startDrag} className="c-drawer__drag-handle" />
          <div className="c-drawer__content-inner h-scrollable">{children}</div>
        </motion.div>
      </SlideUp>
    </div>
  );
};

export default Drawer;

export type DrawerContent = React.ReactElement | null;

export const useDrawerState = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<DrawerContent>(null);

  const open = useCallback((content: DrawerContent) => {
    setContent(content);
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    content,
    open,
    close,
  };
};
