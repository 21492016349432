import { Barbershop } from "../../types";

/**
 * Generate page path and title for analytics events
 */
export const getAnalyticsOptions = (
  id: string | number,
  name: Barbershop["name"]
): {
  path: string;
  title: string;
} => ({
  path: `/barbershop/${id}`,
  title: `Barbershop: ${name}`,
});
