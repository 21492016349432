import React from "react";
import classNames from "classnames";
import { withLocalize, Translate } from "react-localize-redux";
import myMRoomImage from "../assets/images/welcome-what-is-my-mroom.png";

class HelpMyMRoom extends React.Component {
  render() {
    const componentClass = classNames({
      "c-help-screen": true,
    });

    return (
      <div className={componentClass}>
        <div className="c-help-screen__content">
          <h2>
            <Translate id="helpMyMRoom.title" />
          </h2>
          <p>
            <Translate id="helpMyMRoom.description" />
          </p>
        </div>
        <div className="c-help-screen__image">
          <img src={myMRoomImage} alt="My M Room listaus ja jonotusnäkymät" />
        </div>
      </div>
    );
  }
}

export default withLocalize(HelpMyMRoom);
