import ApiConstants from "../constants/api";
import apiConfig from "../config/api.json";
import mockBonusData from "../mocks/bonus.json";
import mockProfileData from "../mocks/profile.json";

const mutableMockBonusData = { ...mockBonusData };

/**
 * Calculates the rough distance between two points on earth.
 *
 * @param origin object containing lat and lng floating numbers
 * @param destination object containing lat and lng floating numbers
 *
 * @returns floating number with single decimal
 */

export function calculateDistance(origin, destination) {
  const distance = getCrowDistance(
    origin.lat,
    origin.lng,
    destination.lat,
    destination.lng
  );

  return Math.round(distance * 10) / 10;
}

// Converts numeric degrees to radians
export function degreesToRadians(Value) {
  return (Value * Math.PI) / 180;
}

//This function takes in latitude and longitude of two locations
// and returns the distance between them as the crow flies (in km)
export function getCrowDistance(lat1, lng1, lat2, lng2) {
  const R = 6371; // km
  const dLat = degreesToRadians(lat2 - lat1);
  const dLng = degreesToRadians(lng2 - lng1);
  const rLat1 = degreesToRadians(lat1);
  const rLat2 = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(rLat1) * Math.cos(rLat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
}

// Converts API membership date formats
export function convertMembershipDate(date) {
  const locale = localStorage.getItem("locale");
  const d = new Date(date);
  const day = ("0" + d.getDate()).slice(-2);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  return locale === "US"
    ? [month, day, year].join(".")
    : [day, month, year].join(".");
}

// This function logs an API call to the console in a stylized way
export function apilog(endpoint, description) {
  // prettier-ignore
  console.log( `%c${endpoint} %c${description}`, "color: black; font-weight:bold;", "color: grey;"); // eslint-disable-line no-console
}

// returns a random value between two values
export function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

// returns a random value from an array
export function randomArrayItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

// removes trailing seconds from a time string
export function removeSeconds(str) {
  return str.substring(0, str.length - 3);
}

// removes the leading zero from a time string
export function removeLeadingZero(str) {
  return str.charAt(0) === "0" ? str.substring(1, str.length) : str;
}

// cleans a time string from unnecessary crap
export function cleanTime(str) {
  // remove seconds
  str = str.substring(0, str.length - 3);

  // remove leading zero
  str = str.charAt(0) === "0" ? str.substring(1, str.length) : str;

  return str;
}

// gets the users wait time from an array of priority queue times
export function getPriorityQueueTime(queueTimes) {
  if (queueTimes === null || !queueTimes.length) return null;

  // sort times by descending priority order
  queueTimes.sort((a, b) => {
    return b.priority - a.priority;
  });

  // first try to find priority matching user profile
  const profile = JSON.parse(localStorage.getItem("profile"));

  // if the profile is not found or user is not a member, return the lowest priority time
  if (!profile || !profile.membership) return queueTimes[0].wait_time;

  const priority = queueTimes.find((time) => {
    return time.priority === profile.membership.priority;
  });

  // return matched priority or lowest time
  return priority ? priority.wait_time : queueTimes[0].wait_time;
}

// updates a localstorage item with new data
export function updateLocalStorage(data, name) {
  const previousData = JSON.parse(localStorage.getItem(name));

  const newData = {
    ...previousData,
    ...data,
  };

  // Joooonaaaaaasss! TO-DO: make a separate function for this.
  if (name === "profile") {
    delete newData["fcm_token"];
  }

  localStorage.setItem(name, JSON.stringify(newData));

  return newData;
}

/**
 * Converts price units to a more readable format
 *
 * @param {number} price - The price in lowest format (e.g. cents)
 * @return {string} Converted price unit
 */

export function priceToUnits(price) {
  const locale = localStorage.getItem("locale");

  if (locale === "US") {
    return (price / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  } else if (locale === "NO") {
    return (price / 100).toLocaleString("nb-NO", {
      style: "currency",
      currency: "NOK",
    });
  } else if (locale === "DK") {
    return (price / 100).toLocaleString("da-DK", {
      style: "currency",
      currency: "DKK",
    });
  } else if (locale === "SE") {
    return (price / 100).toLocaleString("sv-SE", {
      style: "currency",
      currency: "SEK",
    });
  }

  // By default let's use Finnish / European currency
  return (price / 100).toLocaleString("fi-FI", {
    style: "currency",
    currency: "EUR",
  });
}

/**
 * Gets the current locale currency code.
 *
 * @return {string} Currency code
 */

export function getCurrencyCode(locale) {
  if (locale === "US") {
    return "USD";
  } else if (locale === "NO") {
    return "NOK";
  } else if (locale === "DK") {
    return "DKK";
  }

  // By default let's use Finnish / European currency
  return "EUR";
}

/**
 * Sorts an array based on display order and then alphabetically
 *
 * @param {array} arr - Array to be sorted
 * @return {array} Sorted array
 */
export function sortArrayByOrder(arr) {
  return arr.sort((a, b) => {
    // first sort by the display order value
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;

    // then sort alphabetically
    return a.name - b.name;
  });
}

/**
 * Make sure that recovery token is still valid
 */

export function isRecoveryTokenValid(data) {
  if (typeof data.date === "undefined") return false;

  const date = new Date();
  const time = date.getTime();
  const created = data.date;

  return time < created + ApiConstants.RECOVERY_TOKEN_TIMEOUT;
}

/**
 * Checks if the user is in active password recovery process.
 *
 * @returns {boolean}
 */
export function isRecoveringPassword() {
  let recovery = localStorage.getItem("recovery");

  if (!recovery) {
    return false;
  }

  return isRecoveryTokenValid(JSON.parse(recovery));
}

/**
 * Checks if the user has seen the welcome screens.
 *
 * @returns {boolean}
 */
export function hasSeenWelcomeScreens() {
  return localStorage.getItem("welcomeSeen") === "true";
}

/**
 * Generic helper function for pushing to data layer
 */
export function dataLayerPush(data) {
  if (!window.dataLayer) return false;

  window.dataLayer.push(data);
}

/**
 * Find out if this is a device in standalone mode
 */

export function isStandalone() {
  const isInWebAppiOS = window.navigator.standalone === true;
  const isInWebAppChrome = window.matchMedia(
    "(display-mode: standalone)"
  ).matches;

  return isInWebAppiOS || isInWebAppChrome ? true : false;
}

/**
 * Find out if device is an iOS device not in standalone.
 */

export function isIOSDeviceNotInStandalone() {
  if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    if (
      "standalone" in window.navigator &&
      window.navigator.standalone === false
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Find out if device is an Android device not in standalone
 */

export function isAndroidDeviceNotInStandalone() {
  if (/(android)/i.test(navigator.userAgent)) {
    // do a standalone check here
  }

  return false;
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

/**
 * Gets the development api url.
 *
 * This needs to be in sync with what's setup in `setupProxy.js`
 * in the project root.
 *
 * @todo REMOVE FROM HERE
 */
const getLocalhostApiUrl = () => {
  const locale = localStorage.getItem("locale");
  return `/api/${locale}`;
};

/**
 * Gets the api url.
 *
 * @todo REMOVE FROM HERE
 */
export const getApiUrl = () => {
  if (isDev()) {
    return getLocalhostApiUrl();
  }

  if (process.env.REACT_APP_CUSTOM_API_URL) {
    return process.env.REACT_APP_CUSTOM_API_URL;
  }

  const { urls, defaultLocale } = apiConfig || {};
  const locale = localStorage.getItem("locale") || defaultLocale;

  return urls[locale];
};

/**
 * Gets the locale based on country dial code.
 */

export function getLocaleFromDialcode(dialCode, countryData) {
  const selectedCountry = countryData.find((data) => {
    return data.dial_code === dialCode;
  });

  if (selectedCountry) {
    return selectedCountry.code;
  } else {
    return false;
  }
}

/**
 * Gets the dial code based on country code.
 */

export function getDialcodeFromLocale(countryCode, countryData) {
  const selectedCountry = countryData.find((data) => {
    return data.code === countryCode;
  });

  if (selectedCountry) {
    return selectedCountry.dial_code;
  } else {
    return false;
  }
}

/**
 * Gets the initial dial code for country selector dropdowns.
 */

export function getInitialDialcode(locale, countryData) {
  const localeDialcode = getDialcodeFromLocale(locale, countryData);

  return localeDialcode || "+358";
}

/**
 * Get language from country code
 */

export function countryCodeToLanguage(countryCode) {
  let language = false;

  switch (countryCode) {
    case "FI":
      language = "fi";
      break;
    case "US":
      language = "en";
      break;
    case "NO":
      language = "no";
      break;
    case "SE":
      language = "sv";
      break;
    case "DK":
      language = "dk";
      break;
    case "EE":
      language = "en";
      break;
    default:
      language = false;
  }

  return language;
}

/**
 * Check if we have marked devMode to being active via
 * localStorage. This is currently used to reveal our
 * placeholder store in the list etc.
 */
export const isDevMode = () => {
  return !!(
    "true" === process.env.REACT_APP_DEV_MODE || localStorage.getItem("devMode")
  );
};

/**
 * Check if we are running node in development mode
 */
export const isDev = () => {
  return "development" === process.env.NODE_ENV;
};

/**
 * Check if object is empty.
 */
export const isEmptyObject = (obj) => {
  return !obj || Object.keys(obj).length === 0;
};

/**
 * Check if we should force coords if none are available from the api
 */
export const shouldForceBarbershopMockCoords = () => {
  return (
    "true" ===
      process.env.REACT_APP_DEV_MODE_FORCE_BARBERSHOP_COORDS_IF_UNAVAILABLE &&
    isDevMode()
  );
};

/**
 * Check if we should force long descriptions for services
 */
export const shouldForceServiceLongDescription = () => {
  return (
    "true" === process.env.REACT_APP_DEV_MODE_FORCE_SERVICE_LONG_DESCRIPTION &&
    isDevMode()
  );
};

/**
 * Check if we should force all barbershops to take orders
 */
export const shouldForceBarbershopTakeOrders = () => {
  return (
    "true" === process.env.REACT_APP_DEV_MODE_FORCE_TAKING_ORDERS && isDevMode()
  );
};

/**
 * Check if we should disable service worker
 */
export const shouldDisableServiceWorker = () => {
  return "true" === process.env.REACT_APP_DISABLE_SERVICE_WORKER;
};

/**
 * Checks that the array item is false
 */
export const isFalse = (value) => !value;

/**
 * Get price sum from an array of services
 */
export const getServicePriceSum = (services) => {
  return services.reduce((sum, service) => {
    const { price } = service;
    sum += price;
    return sum;
  }, 0);
};

/**
 * Check if we should allow barbershop data to be renamed.
 */
export const shouldAllowBarbershopRename = () => {
  return "true" === process.env.REACT_APP_ALLOW_BARBERSHOP_RENAME;
};

/**
 * Should use mock bonus data
 */
export const shouldMockBonusData = () => {
  return (
    "true" === process.env.REACT_APP_DEV_MODE_MOCK_BONUS_DATA && isDevMode()
  );
};

export const generateMockBonusData = (data) => {
  mutableMockBonusData.amount = randomBetween(1500, 5000);

  return { ...data, ...{ bonus: mutableMockBonusData } };
};

/**
 * Should use mock profile data
 */
export const shouldMockProfileData = () => {
  return "true" === process.env.REACT_APP_DEV_MODE_MOCK_PROFILE && isDevMode();
};

export const generateMockProfileData = () => {
  return mockProfileData;
};

/**
 * Slugify a string
 */
export const slugify = (str) => {
  /* eslint-disable */
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

/**
 * Gets the total vertical offset of an array of refs.
 */
export const getTotalOffsetTop = (refs) => {
  return refs.reduce((total, currentRef) => {
    if (currentRef.current) {
      total += currentRef.current.offsetTop;
    }

    return total;
  }, 0);
};

/**
 * Convert app locale to universal language locale
 */
export const getLanguageLocale = (locale) => {
  const languageLocales = {
    FI: "fi-FI",
    US: "en-US",
    NO: "nb-NO",
    DK: "da-DK",
    SE: "sv-SE",
  };

  return languageLocales[locale] || "fi-FI";
};

/**
 * Validate email field
 */
export const validateEmail = (maybeEmail) => {
  if (!maybeEmail || !/[^@]+@[^@]+\.\w{2,}/.test(maybeEmail)) {
    return false;
  }

  return true;
};

/**
 * Checks if we should remove trailing slash from custom urls.
 */
export const shouldRemoveCustomApiTrailingSlash = () => {
  return "true" === process.env.REACT_APP_CUSTOM_API_REMOVE_TRAILING_SLASH;
};

/**
 * Removes the trailing slash of a string.
 */
export const removeTrailingSlash = (url) => {
  const [base, queryString] = url.split("?");
  const baseWithoutTrailingSlash = base.replace(/\/$/, "");

  if (!queryString) {
    return baseWithoutTrailingSlash;
  }

  return `${baseWithoutTrailingSlash}?${queryString}`;
};
