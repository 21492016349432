import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RouteLink from "../components/RouteLink";
import classNames from "classnames";
import RecoveryForm from "../components/RecoveryForm";
import Spinner from "../components/Spinner";
import BackButton from "../components/BackButton";
import * as helpers from "../utils/Helpers";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import {
  login,
  getRecoveryToken,
  recoverPassword,
  resetRecovery,
} from "../ducks/user";

class Recovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formSubmitted: props.passwordRecovered,
    };

    this.blockTransition = false;
    this.titleSet = false;

    this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }

  componentDidMount() {
    // Store current location for back buttons

    const recovery = JSON.parse(localStorage.getItem("recovery"));

    // check that the recovery process is still within the SMS-timeout
    if (recovery && !this.props.passwordRecovered) {
      if (!helpers.isRecoveryTokenValid(recovery)) {
        this.props.actions.resetRecovery();
      }
    } else {
      this.props.actions.resetRecovery();
    }

    analytics.pageView("/recovery", "Password Recovery");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      !this.props.passwordRecovered &&
      newProps.passwordRecovered &&
      !this.state.formSubmitted
    ) {
      analytics.genericEvent("passwordRecoverySuccessful");

      this.setState(() => {
        return {
          formSubmitted: true,
        };
      });
    }

    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.recovery");
      this.titleSet = true;
    }
  }

  handlePhoneSubmit(data, countryCode, phone) {
    this.props.actions.getRecoveryToken(data, countryCode, phone);
  }

  handlePasswordSubmit(data) {
    this.props.actions.recoverPassword(data);
  }

  render() {
    const componentClass = classNames({
      "s-settings": true,
      "h-scrollable": true,
      "s-settings--loading": this.props.loading,
    });

    const EXIT_ROUTE = "/";

    return (
      <div className={componentClass}>
        <div className="s-settings__back">
          <BackButton fallbackRoute={EXIT_ROUTE} />
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="recovery.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="recovery.subtitle" />
          </p>
        </div>

        <div className="s-settings__content">
          {!this.state.formSubmitted ? (
            <RecoveryForm
              submitPhoneHandler={this.handlePhoneSubmit}
              submitPasswordHandler={this.handlePasswordSubmit}
              showPasswordField={this.props.recoveryTokenSent}
            />
          ) : (
            <React.Fragment>
              <h3 className="s-settings__content-title">
                <Translate id="recovery.successTitle" />
              </h3>
              <p className="s-settings__description">
                <Translate id="recovery.successDescription" />
              </p>
              <p>
                <RouteLink
                  to="/login"
                  transition="slide-left"
                  className="c-btn c-btn--small"
                >
                  <Translate id="recovery.loginPrompt" />
                </RouteLink>
              </p>
            </React.Fragment>
          )}
        </div>

        <div className="s-settings__spinner">
          <Spinner />
        </div>
      </div>
    );
  }
}

Recovery.propTypes = {
  actions: PropTypes.object,
  passwordRecovered: PropTypes.bool,
  loading: PropTypes.bool,
  recoveryTokenSent: PropTypes.bool,
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loading: state.user.loading,
    recoveryTokenSent: state.user.recoveryTokenSent,
    passwordRecovered: state.user.passwordRecovered,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        login,
        getRecoveryToken,
        recoverPassword,
        resetRecovery,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Recovery));
