import { useLayoutEffect, useCallback } from "react";
import { TimerHandler, Delay, useInterval } from "./use-interval";

export const useFocusInterval = (callback: TimerHandler, delay: Delay) => {
  const { refresh, clear } = useInterval(callback, delay);

  const onVisibilityChange = useCallback(() => {
    switch (document.visibilityState) {
      case "visible": {
        refresh();
        break;
      }
      case "hidden": {
        clear();
        break;
      }
      default: {
        return false;
      }
    }
  }, [refresh, clear]);

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, [onVisibilityChange]);

  return { refresh, clear };
};
