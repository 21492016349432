import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import * as helpers from "../utils/Helpers";
import Dropdown from "./Dropdown";
import Button from "./Button";
import countryData from "../data/limitedCountries.json";
import { withLocalize, Translate } from "react-localize-redux";

import { setLocale, setLocaleFromIP } from "../ducks/user";

class RecoveryForm extends React.Component {
  constructor(props) {
    super(props);

    this.countryData = countryData;
    this.countryOptions = this.getCountryOptions(countryData);

    const recovery = JSON.parse(localStorage.getItem("recovery"));

    this.recovery = recovery;

    this.state = {
      countryCode: recovery
        ? recovery.countryCode
        : helpers.getInitialDialcode(this.props.locale, this.countryData),
      phone: recovery ? recovery.phone : "",
      token: "",
      password: "",
    };

    this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    if (!this.recovery && !this.props.locale) {
      this.props.actions.setLocaleFromIP(this.countryData);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.props.locale && newProps.locale) {
      this.setState({
        countryCode: helpers.getDialcodeFromLocale(
          newProps.locale,
          this.countryData
        ),
      });
    }
  }

  handlePhoneSubmit(event) {
    event.preventDefault();

    const data = {
      phone: this.state.countryCode + this.state.phone,
    };

    const date = new Date();

    this.props.submitPhoneHandler(
      data,
      this.state.countryCode,
      this.state.phone,
      date.getTime()
    );
  }

  handlePasswordSubmit(event) {
    event.preventDefault();

    const data = {
      phone: this.state.countryCode + this.state.phone,
      password: this.state.password,
      token: this.state.token,
    };

    this.props.submitPasswordHandler(data);
  }

  changeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryCode: selectedOption,
    });

    this.props.actions.setLocale(
      helpers.getLocaleFromDialcode(selectedOption, this.countryData)
    );
  }

  getCountryOptions(data) {
    return data.map((country) => {
      return {
        value: country.dial_code,
        label: country.name,
      };
    });
  }

  getCountryDataFromCode(code) {
    return this.countryData.find((country) => {
      return country.code === code;
    });
  }

  render() {
    const description = this.props.showPasswordField ? (
      <Translate id="recovery.passwordDescription" />
    ) : (
      <Translate id="recovery.phoneDescription" />
    );

    const phoneClass = classNames({
      "c-recovery-form__phone": true,
      "has-error": this.props.errors.phone,
    });

    const tokenClass = classNames({
      "c-recovery-form__token": true,
      "has-error": this.props.errors.token,
    });

    const passwordClass = classNames({
      "c-recovery-form__password": true,
      "has-error": this.props.errors.password,
    });

    const phoneError = this.props.errors.phone ? (
      <div className="c-recovery-form__error-message">
        <Translate id="recovery.phoneError" />
      </div>
    ) : (
      ""
    );

    const tokenError = this.props.errors.token ? (
      <div className="c-recovery-form__error-message">
        <Translate id="recovery.tokenError" />
      </div>
    ) : (
      ""
    );

    const passwordError = this.props.errors.password ? (
      <div className="c-recovery-form__error-message">
        <Translate id="recovery.passwordError" />
      </div>
    ) : (
      ""
    );

    const phoneNumber = this.props.showPasswordField ? (
      <div className="c-recovery-form__static">
        {this.state.countryCode} {this.state.phone}
      </div>
    ) : (
      ""
    );

    const clickHandler = this.props.showPasswordField
      ? this.handlePasswordSubmit
      : this.handlePhoneSubmit;

    return (
      <div className="c-recovery-form">
        <form onSubmit={this.onSubmit} method="post">
          {phoneNumber}

          <div className="c-recovery-form__description">{description}</div>

          {!this.props.showPasswordField && (
            <div className="c-recovery-form__field">
              <div className="c-recovery-form__country-code">
                <Dropdown
                  changeHandler={this.handleCountryChange}
                  options={this.countryOptions}
                  selectedOption={this.state.countryCode}
                />
              </div>
              <input
                type="tel"
                name="phone"
                placeholder="401234567"
                required
                className={phoneClass}
                value={this.state.phone}
                onChange={this.changeHandler}
              />
              <label htmlFor="phone">
                <Translate id="recovery.phoneLabel" />
              </label>

              {phoneError}
            </div>
          )}

          {this.props.showPasswordField && (
            <React.Fragment>
              <div className="c-recovery-form__field">
                <input
                  type="text"
                  name="token"
                  required
                  className={tokenClass}
                  value={this.state.token}
                  onChange={this.changeHandler}
                />
                <label htmlFor="token">
                  <Translate id="recovery.tokenLabel" />
                </label>
                {tokenError}
              </div>
              <div className="c-recovery-form__field">
                <input
                  type="password"
                  name="password"
                  required
                  className={passwordClass}
                  value={this.state.password}
                  onChange={this.changeHandler}
                />
                <label htmlFor="password">
                  <Translate id="recovery.passwordLabel" />
                </label>
                <div className="c-recovery-form__instructions">
                  <Translate id="recovery.passwordInstructions" />
                </div>
                {passwordError}
              </div>
            </React.Fragment>
          )}

          <div className="c-recovery-form__submit">
            <Button
              size="regular"
              onClick={clickHandler}
              text={this.props.translate("recovery.submitText")}
            />
          </div>
        </form>
      </div>
    );
  }
}

RecoveryForm.propTypes = {
  submitPhoneHandler: PropTypes.func,
  submitPasswordHandler: PropTypes.func,
  showPasswordField: PropTypes.bool,
  errors: PropTypes.object,
  translate: PropTypes.func,
  actions: PropTypes.object,
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

function mapStateToProps(state, ownProps) {
  const y = {
    locale: state.user.locale,
    errors: {
      phone: state.user.errors.recovery.phone,
      password: state.user.errors.recovery.password,
      token: state.user.errors.recovery.token,
    },
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        setLocale,
        setLocaleFromIP,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(RecoveryForm));
