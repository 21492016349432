import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { navigateTo } from "../../utils/navigation";
import FadeInUp from "../animations/FadeInUp";
import FadeIn from "../animations/FadeIn";
import { ReactComponent as IconArrowRight } from "../../assets/images/icon-arrow-right.svg";
import Timer from "../../components/Timer";
import TrackingMessage from "./TrackingMessage";
import {
  selectTrackingCheckedIn,
  selectTrackingLoadingOrder,
  selectTrackingOrder,
  fetchOrder,
} from "./trackingSlice";
import { useActiveOrderId } from "../user/use-active-order-id";
import { useFocusInterval } from "../../utils/use-focus-interval";
import ApiConstants from "../../constants/api";

const { ORDER_REFRESH_TIMEOUT } = ApiConstants;

const TrackingButton = () => {
  const dispatch = useDispatch();

  const { orderId } = useActiveOrderId();

  const loading = useSelector(selectTrackingLoadingOrder);
  const order = useSelector(selectTrackingOrder);
  const checkedIn = useSelector(selectTrackingCheckedIn);
  const { queue } = order || {};

  const maybeFetchOrder = useCallback(() => {
    if (orderId !== null) {
      dispatch(fetchOrder(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    maybeFetchOrder();
  }, [maybeFetchOrder]);

  useFocusInterval(maybeFetchOrder, ORDER_REFRESH_TIMEOUT);

  const handleClick = useCallback(() => {
    navigateTo("/tracking", "slide-right");
  }, []);

  return (
    <div className="c-tracking-button">
      <FadeIn key="tracking-button-background">
        <div className="c-tracking-button__background" />
      </FadeIn>
      <FadeInUp key="tracking-button-button">
        <button onClick={handleClick}>
          <span className="c-tracking-button__timer">
            <Timer size="tiny" minutes={queue} shouldPulse={true} />
          </span>
          <span className="c-tracking-button__message">
            <TrackingMessage
              loading={loading}
              queue={queue}
              checkedIn={checkedIn}
              size="small"
            />
          </span>
          <span className="c-tracking-button__icon">
            <IconArrowRight />
          </span>
        </button>
      </FadeInUp>
    </div>
  );
};

export default TrackingButton;
