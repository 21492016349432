import React from "react";
import { AnimatePresence } from "framer-motion";

import { ReactComponent as SearchIcon } from "../assets/images/icon-search.svg";
import { ReactComponent as CrossIcon } from "../assets/images/icon-cross.svg";
import FadeIn from "../features/animations/FadeIn";

interface SearchInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
  onClear,
}) => {
  return (
    <div className="c-search-input">
      <div className="c-search-input__wrapper">
        <input
          type="text"
          className="c-search-input__input"
          value={value}
          placeholder={placeholder}
          onChange={({ target: { value } }) => onChange(value)}
        />

        <div className="c-search-input__search-icon">
          <SearchIcon />
        </div>

        <AnimatePresence>
          {!!value && (
            <FadeIn>
              <button className="c-search-input__clear-icon" onClick={onClear}>
                <CrossIcon />
              </button>
            </FadeIn>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SearchInput;
