import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { ExtraService } from "../../types";
import { priceToUnits } from "../../utils/Helpers";
import {
  addExtraService,
  removeExtraService,
  selectOrderExtraServiceIdsByServiceId,
} from "./orderSlice";
import { RootState } from "../../store";
import CheckMark from "../../components/CheckMark";

import { ReactComponent as InfoIcon } from "../../assets/images/icon-info.svg";

interface ExtraServiceToggleProps extends ExtraService {
  onInfoClick?: (extraService: ExtraService) => void;
  size?: "regular" | "large";
}

const ExtraServiceToggle: React.FC<ExtraServiceToggleProps> = ({
  onInfoClick,
  size = "regular",
  ...extraService
}) => {
  const { id, serviceId, title, price, longDescription } = extraService;
  const dispatch = useDispatch();
  const hasInfo = !!longDescription;

  const selected = useSelector((state: RootState) =>
    selectOrderExtraServiceIdsByServiceId(state, serviceId)
  );

  const isSelected = useMemo(() => selected.includes(id), [selected, id]);

  const handleClick = useCallback(() => {
    if (!isSelected) {
      dispatch(addExtraService(serviceId, id));
    } else {
      dispatch(removeExtraService(serviceId, id));
    }
  }, [dispatch, isSelected, serviceId, id]);

  const componentClass = classNames("c-extra-service-toggle", {
    "is-selected": isSelected,
    "has-info": hasInfo,
    [`is-size-${size}`]: !!size,
  });

  return (
    <div className={componentClass}>
      <button className="c-extra-service-toggle__button" onClick={handleClick}>
        <span className="c-extra-service-toggle__title">{title}</span>
        <span className="c-extra-service-toggle__price">
          {priceToUnits(price)}
        </span>
        <div className="c-extra-service-toggle__icon">
          <CheckMark
            isSelected={isSelected}
            size={size === "large" ? "regular" : "small"}
          />
        </div>
      </button>

      {hasInfo && onInfoClick && (
        <button
          className="c-extra-service-toggle__info h-button-wrapper"
          onClick={() => onInfoClick(extraService)}
        >
          <InfoIcon />
        </button>
      )}
    </div>
  );
};

export default ExtraServiceToggle;
