import React from "react";
import PropTypes from "prop-types";
import history from "../utils/navigation";

class RouteLink extends React.Component {
  render() {
    const linkClass =
      typeof this.props.className !== "undefined"
        ? this.props.className
        : "c-link";

    return (
      <a
        href={this.props.to}
        className={linkClass}
        onClick={(e) => {
          e.preventDefault();

          if (this.props.onClick) {
            this.props.onClick(this.props.to);
          }

          history.push({
            pathname: this.props.to,
            state: {
              transition: this.props.transition,
            },
          });
        }}
      >
        {this.props.children}
      </a>
    );
  }
}

RouteLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  transition: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default RouteLink;
