import { UnitSystem } from "../types";

/**
 * Converts metric to imperial
 */
export const metricToImperial = (value: number) => {
  return Math.round(value * 0.6214 * 100) / 100;
};

/**
 * Converts always-metric distance values to either
 * imperial or metric based on the provided unit system.
 */
export const metricToUnitSystem = (
  value: number | null,
  unitSystem: UnitSystem
) => {
  if (typeof value !== "number") {
    return value;
  }

  if (unitSystem === "imperial") {
    return metricToImperial(value);
  }

  return value;
};

/**
 * Converts metric unit suffixes to ones
 * based on the provided unit system.
 */
export const metricToUnitSystemSuffix = (
  str: string,
  unitSystem: UnitSystem
) => {
  if (unitSystem === "imperial") {
    return str.replace("km", "mi.");
  }

  return str;
};

/**
 * Formats a distance based on the unit system.
 *
 * @note This function does not convert any values.
 */
export const getFormattedDistance = (
  distance: number | null | undefined,
  unitSystem: UnitSystem
) => {
  if (typeof distance !== "number") {
    return null;
  }

  if (unitSystem === "imperial") {
    return `${distance} mi.`;
  }

  if (distance < 1) {
    return `${distance * 1000} m`;
  }

  return `${distance} km`;
};
