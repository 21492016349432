import { useMemo } from "react";

import { Barbershop } from "../../types";
import { useFavoriteBarbershopIds } from "./use-favorite-barbershop-ids";

export const useFavoriteBarbershops = (
  barbershops: Barbershop[]
): Barbershop[] => {
  const favoriteIds = useFavoriteBarbershopIds();
  const favoriteBarbershops = useMemo(
    () =>
      favoriteIds
        ? barbershops.filter(({ id }) => favoriteIds.includes(id))
        : [],
    [barbershops, favoriteIds]
  );

  return favoriteBarbershops;
};
