import {
  LocalizeContextProps,
  LocalizedElement,
  LocalizedElementMap,
} from "react-localize-redux";

/**
 * Child component for render layouts
 */
export type RenderComponent = React.ReactElement | React.ReactElement[];

/**
 * Basic loading state
 */
export type Loading = "idle" | "pending" | "fulfilled" | "rejected";

/**
 * Barbershop data types
 */
export interface ServiceGroup {
  id: number;
  title: string;
  order: number;
  description: string;
  parentId: number;
}

export interface Service {
  id: number;
  groupId: number;
  title: string;
  order: number;
  description: string;
  longDescription?: string;
  price: number;
  duration: number;
}

export type ServiceInfo = Pick<
  Service,
  "id" | "title" | "price" | "longDescription"
>;

export interface ExtraService {
  id: number;
  serviceId: number;
  title: string;
  order: number;
  description: string;
  longDescription?: string;
  price: number;
  duration: number;
}

export interface ExtraServices {
  [serviceId: string]: ExtraService[];
}

export enum QueueState {
  open = "open",
  closed = "closed",
  full = "full",
}

export interface Barbershop extends LocatableEntity {
  image: string | false;
  city: string;
  queue: number | false;
  closingSoon: boolean;
  queueState: QueueState;
  open: boolean;
  name: string;
  address: string;
  floor: string;
  serviceGroups: ServiceGroup[];
  services: Service[];
  extraServices: ExtraServices;
  groupedServices: any;
  openingHours: string | false;
  chairCount: string;
}

/**
 * Barbershop status (in app)
 */
export type BarbershopQueueStatus =
  | "taking-orders"
  | "full"
  | "closed"
  | "unknown";

/**
 * Generic page props for extending on individual pages.
 */
export interface PageProps extends LocalizeContextProps {
  location?: {
    hash: string;
    pathname: string;
    search: string;
    state: unknown;
  };
}

/**
 * String that can also be a <Translate> component from react-localize-redux
 */
export type TranslateString = string | LocalizedElement | LocalizedElementMap;

/**
 * Tracking data types
 */

export interface TrackingOrder {
  userId: number;
  orderId: number;
  date: string;
  barbershopId: number;
  status: TrackingStatus;
  checkedIn: boolean;
  queue: number;
  services: TrackingOrderService[];
}

export enum TrackingStatus {
  cancelled = "cancelled",
  active = "active",
  serviced = "serviced",
  noShow = "no-show",
}

export interface Service {
  id: number;
  groupId: number;
  title: string;
  order: number;
  description: string;
  price: number;
  duration: number;
}

// @todo make this follow same structure as Service
export interface TrackingOrderService {
  amount: number;
  id: number;
  description: string;
  name: string;
  order: number;
  price: number;
  duration: number;
  groupName: string;
  extraServices: TrackingOrderExtraService[];
}

export interface TrackingOrderExtraService {
  amount: number;
  id: number;
  name: string;
  order: number;
  price: number;
  duration: number;
}
export type UnitSystem = "metric" | "imperial";

export enum StoreSelectedSource {
  GeolocationList = "geolocation list",
  List = "list",
  Search = "search",
  Favorite = "list favorites",
  Uknown = "unknown",
}

export interface Coords {
  lat: number;
  lng: number;
}

export interface LocatableEntity {
  id: number;
  coords: Coords;
}

export interface IndexedUncertainNumbers {
  [key: string]: number | null;
}

export interface IndexedUncertainHTMLDivElements {
  [key: string]: HTMLDivElement | null;
}

export interface IndexedStrings {
  [key: string]: string;
}

export type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
>;

export interface IndexedSVGComponents {
  [key: string]: SVGComponent;
}

export interface Indexed<T> {
  [key: string]: T;
}

export interface Profile {
  active_order_id: number | null;
  allow_email: boolean;
  allow_sms: boolean;
  app_language: string;
  city: string | null;
  date_of_birth: string | null;
  email: string | null;
  firstname: string;
  id: number;
  lastname: string;
  phone: string;
  post_code: string | null;
  sex: string | null;
  street_address: string | null;
  tutorial_seen: boolean;
  bonus: Bonus;
}

export interface Bonus {
  amount: number;
  expirationDate: string;
  benefits: Benefit[];
}

export interface Benefit {
  id: number;
  title: string;
  value: string;
  isRedeemable: boolean;
  redeemCode: string;
  dateIssued: string;
  dateRedeemed?: string;
}
