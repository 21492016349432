import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Animations from "../utils/Animations";
import * as helpers from "../utils/Helpers.js";
import SubOption from "./SubOption";

/**
 * @deprecated Avoid using this component
 */
class Option extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelected: false,
    };

    this.subOptionsEl = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleSubClick = this.handleSubClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.state.isSelected) {
      this.animate();

      this.setState((prevState, props) => {
        return {
          isSelected: !prevState.isSelected,
        };
      });
    }
  }

  animate() {
    if (this.subOptionsEl !== null) {
      if (!this.state.isSelected) {
        Animations.deprecated__slideOpen(this.subOptionsEl, 0.5);
      } else {
        Animations.deprecated__slideClose(this.subOptionsEl, 0.25);
      }
    }
  }

  buildSubOptions(options) {
    // sort by ascending order
    const sorted = options.sort((a, b) => {
      // first sort by the display order value
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;

      // then sort alphabetically
      return a.name - b.name;
    });

    return sorted.map((item, index) => {
      const isSelected = this.props.selectedSubOptions.some(
        (extra) => extra.id === item.id
      );

      return (
        <SubOption
          key={item.id}
          id={item.id}
          selected={isSelected}
          title={item.name}
          price={item.price}
          units={helpers.priceToUnits(item.price)}
          clickHandler={this.handleSubClick}
        />
      );
    });
  }

  handleClick() {
    if (!this.props.disabled) {
      this.props.clickHandler(this.props);
    }
  }

  handleSubClick(subOption) {
    if (!this.props.disabled) {
      this.props.subClickHandler(this.props.id, subOption);
    }
  }

  render() {
    const componentClass = classNames({
      "c-option": true,
      "is-disabled": this.props.disabled,
      "is-selected": this.props.selected,
    });

    const subOptions =
      this.props.subOptions && this.props.subOptions.length > 0 ? (
        <div
          ref={(c) => {
            this.subOptionsEl = c;
          }}
          className="c-suboptions"
        >
          <div className="c-suboptions__wrapper">
            <h3 className="c-suboptions__title">{this.props.subtitle}</h3>
            {this.buildSubOptions(this.props.subOptions)}
          </div>
        </div>
      ) : (
        ""
      );

    return (
      <li className={componentClass}>
        <div className="c-option__wrapper" onClick={this.handleClick}>
          <h3 className="c-option__title">{this.props.title}</h3>
          <p className="c-option__description">{this.props.description}</p>
          <p className="c-option__unit">{this.props.units}</p>
          <i className="c-option__icon" />
        </div>

        {subOptions}
      </li>
    );
  }
}

Option.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  selectedSubOptions: PropTypes.array,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  subOptions: PropTypes.array,
  units: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.number,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clickHandler: PropTypes.func,
  subClickHandler: PropTypes.func,
};

export default Option;
