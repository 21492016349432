import React from "react";
import { Translate } from "react-localize-redux";

import FadeIn from "../animations/FadeIn";

interface ReceiptToggleProps {
  className?: string;
  onClick: () => void;
}

const ReceiptToggle: React.FC<ReceiptToggleProps> = ({
  className,
  onClick,
}) => {
  return (
    <FadeIn className={className}>
      <button className="c-btn c-btn--receipt" onClick={onClick}>
        <Translate id="tracking.recipeToggle" />
      </button>
    </FadeIn>
  );
};

export default ReceiptToggle;
