import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import orderConstants from "../constants/order";
import RouteLink from "../components/RouteLink";
import Receipt from "../components/Receipt";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import { fetchReceipts, sortReceipts } from "../ducks/deprecated__order";

class OrderHistory extends React.Component {
  constructor(props) {
    super(props);

    this.titleSet = false;
  }

  componentDidMount() {
    this.props.actions.fetchReceipts();

    analytics.pageView("/profile/order-history", "Order History");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.orderHistory");
      this.titleSet = true;
    }
  }

  generateList(receipts) {
    if (!receipts || receipts.length === 0) {
      return "";
    }

    const filtered = receipts.filter((receipt) => {
      return receipt.status !== orderConstants.status.CANCELLED;
    });

    return filtered.map((receipt, index) => {
      return (
        <div className="s-settings__content" key={index}>
          <Receipt receipt={receipt} />
        </div>
      );
    });
  }

  render() {
    const receipts = this.generateList(this.props.results);
    const introText = this.props.translate("orderHistory.intro");
    const hasIntro = introText.length ? true : false;

    return (
      <div className="s-settings h-scrollable">
        <div className="s-settings__back">
          <RouteLink to="/profile" transition="switch-close">
            <div className="c-back-button">
              <Translate id="navigation.back" />
            </div>
          </RouteLink>
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="orderHistory.title" />
          </h1>
          {hasIntro && <p className="s-settings__subtitle">{introText}</p>}
        </div>

        {receipts}
      </div>
    );
  }
}

OrderHistory.propTypes = {
  actions: PropTypes.object,
  results: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loading: state.deprecated__order.loading,
    receipts: state.deprecated__order.receipts,
    // orders: state.deprecated__order.orders
  };

  y.results = sortReceipts(state.deprecated__order);

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        fetchReceipts,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(OrderHistory));
