import { useEffect, useRef } from "react";

import { useSessionStorage } from "./use-session-storage";

export const useTrueOncePerSession = (key: string) => {
  const [value, setValue] = useSessionStorage(key);
  const ref = useRef(value);

  useEffect(() => {
    setValue("1");
  }, [setValue]);

  return !ref.current;
};
