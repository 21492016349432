import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout, syncUserProfile } from "../../ducks/user";
import { RootState } from "../../store";
import { Loading, Profile } from "../../types";
import usePageVisibility from "../../utils/use-page-visibility";

const selectProfile = (state: RootState) => state.user.profile;
const selectIsProfileSyncing = (state: RootState) =>
  state.user.profileIsSyncing;
const selectProfileSynced = (state: RootState) => state.user.profileSynced;

export const useProfile = (): {
  profile: Profile;
  loading: Loading;
  refresh: () => void;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<Loading>("idle");
  const profile = useSelector(selectProfile);
  const isProfileSyncing = useSelector(selectIsProfileSyncing);
  const profileSynced = useSelector(selectProfileSynced);

  const updateProfile = useCallback(
    (visible: boolean) => {
      if (visible) {
        setLoading("pending");
        dispatch(syncUserProfile());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (loading === "pending" && !isProfileSyncing) {
      setLoading("fulfilled");

      // Most likely token has expired if profile is not synced
      if (!profileSynced) {
        dispatch(logout());
      }
    }
  }, [loading, isProfileSyncing, dispatch, profileSynced]);

  usePageVisibility(updateProfile);

  const refresh = useCallback(() => updateProfile(true), [updateProfile]);

  return { profile, loading, refresh };
};
