import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RouteLink from "../components/RouteLink";
import RegistrationForm from "../components/RegistrationForm";
import BackButton from "../components/BackButton";
import history from "../utils/navigation";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import { registerUser } from "../ducks/user";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.blockTransition = false;
    this.titleSet = false;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNoticeClick = this.handleNoticeClick.bind(this);
  }

  componentDidMount() {
    analytics.pageView("/register", "Register");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.register");
      this.titleSet = true;
    }

    if (!this.props.registrationSuccess && newProps.registrationSuccess) {
      analytics.genericEvent("registrationSuccessful");
    }
  }

  handleSubmit(data) {
    this.props.actions.registerUser(data);
  }

  handleNoticeClick(e) {
    e.preventDefault();

    history.push({
      pathname: "/recovery",
      state: {
        transition: "slide-left",
      },
    });
  }

  render() {
    const EXIT_ROUTE = "/";

    const errorMessages =
      this.props.registrationErrors &&
      Object.keys(this.props.registrationErrors).map((key) => {
        return (
          <p key={key}>
            {this.props.translate("error.messagePrefix")}{" "}
            {this.props.registrationErrors[key]}
          </p>
        );
      });

    const successMessage = this.props.registrationSuccess && (
      <div className="c-register-form__thank-you">
        <h2>
          <Translate id="register.successText" />
        </h2>
        <p>
          <RouteLink
            to="/login"
            transition="slide-left"
            className="c-btn c-btn--small"
          >
            <Translate id="register.loginPrompt" />
          </RouteLink>
        </p>
      </div>
    );

    return (
      <div className="s-settings h-scrollable">
        <div className="s-settings__back">
          <BackButton fallbackRoute={EXIT_ROUTE} />
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="register.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="register.subtitle" />
          </p>
          {!this.props.registrationSuccess && (
            <div className="s-settings__inline-notice">
              <div className="c-inline-notice">
                <h4>
                  <Translate id="register.noticeTitle" />
                </h4>
                <p>
                  <Translate id="register.noticeText" />
                </p>
                <div className="c-inline-notice__button">
                  <button className="c-link" onClick={this.handleNoticeClick}>
                    <Translate id="register.noticeButton" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="s-settings__content">
          {errorMessages && (
            <div className="c-register-form__errors">
              <h3>
                <Translate id="register.errorOther" />
              </h3>
              {errorMessages}
            </div>
          )}

          {successMessage}

          {!this.props.registrationSuccess && (
            <RegistrationForm
              submitHandler={this.handleSubmit}
              hasSubmitted={this.props.registrationPending}
            />
          )}
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  actions: PropTypes.object,
  registrationPending: PropTypes.bool,
  registrationSuccess: PropTypes.bool,
  registrationErrors: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
  ]),
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    registrationPending: state.user.registrationPending,
    registrationSuccess: state.user.registrationSuccess,
    registrationErrors: state.user.registrationErrors,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        registerUser,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Register));
