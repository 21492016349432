import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

import { fadeInLogin, fadeInUp } from "../features/animations/utils";

interface ModalProps {
  valign?: "top" | "middle" | "bottom";
  offsetNavigation?: boolean;
  contentPadding?: "regular" | "tight";
  onBackgroundClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  valign = "top",
  offsetNavigation,
  contentPadding = "regular",
  children,
  onBackgroundClick,
}) => {
  const componentClass = classNames("c-modal", `c-modal--valign-${valign}`, {
    "offset-navigation": offsetNavigation,
  });

  const contentClass = classNames("c-modal__content", "h-scrollable", {
    [`c-modal__content--padding-${contentPadding}`]: true,
  });

  return (
    <div className={componentClass}>
      <motion.div
        key={"modal-background"}
        className="c-modal__background"
        {...fadeInLogin}
        transition={{ type: "spring", duration: 0.8, bounce: 0 }}
        onClick={onBackgroundClick}
      />
      <motion.div
        key={"modal-content"}
        className={contentClass}
        {...fadeInUp}
        transition={{ type: "spring", duration: 0.5, bounce: 0.35 }}
      >
        <div className="c-modal__wrapper">{children}</div>
      </motion.div>
    </div>
  );
};

export default Modal;
