import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RouteLink from "../components/RouteLink";
import Spinner from "../components/Spinner";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import { showNotification } from "../ducks/notification";
import { syncUserProfile, patchProfile, logout } from "../ducks/user";

class EmailPermission extends React.Component {
  constructor(props) {
    super(props);

    this.blockTransition = false;
    this.titleSet = false;

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // sync profile from API to make sure data is up to date
    this.props.actions.syncUserProfile();

    analytics.pageView("/profile/email-permission", "Email Permission");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // if the login has expired, log the user out
    if (!newProps.loggedIn && !this.blockTransition) {
      this.props.actions.logout();
      this.props.actions.showNotification(
        newProps.translate("error.tokenTitle"),
        newProps.translate("error.tokenText")
      );

      this.blockTransition = true;
    }

    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.register");
      this.titleSet = true;
    }
  }

  handleClick() {
    if (this.props.profile) {
      this.props.actions.patchProfile({
        allow_email: !this.props.profile.allow_email,
      });
    }
  }

  render() {
    const isLoaded = this.props.profile ? true : false;
    const isAllowed = isLoaded ? this.props.profile.allow_email : false;

    const componentClass = classNames({
      "s-settings": true,
      "h-scrollable": true,
      "s-settings--loading": this.props.loading,
    });

    return (
      <div className={componentClass}>
        <div className="s-settings__back">
          <RouteLink to="/profile" transition="switch-close">
            <div className="c-back-button">Back</div>
          </RouteLink>
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="emailPermission.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="emailPermission.text" />
          </p>
          {this.props.translate("links.privacy") &&
            this.props.translate("emailPermission.linkText") && (
              <p className="s-settings__subtitle-link">
                <a
                  href={this.props.translate("links.privacy")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Translate id="emailPermission.linkText" />
                </a>
              </p>
            )}
        </div>

        {isLoaded && (
          <div className="s-settings__content">
            <div className="s-settings__description">
              {isAllowed ? (
                <Translate id="emailPermission.allowedText" />
              ) : (
                <Translate id="emailPermission.disallowedText" />
              )}
            </div>

            <div className="s-settings__submit">
              <button className="c-btn c-btn--small" onClick={this.handleClick}>
                {isAllowed ? (
                  <Translate id="emailPermission.allowedButton" />
                ) : (
                  <Translate id="emailPermission.disallowedButton" />
                )}
              </button>
            </div>
          </div>
        )}

        {this.props.loading && (
          <div className="s-settings__spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

EmailPermission.propTypes = {
  actions: PropTypes.object,
  profile: PropTypes.object,
  loading: PropTypes.bool,
  profilePatched: PropTypes.bool,
  loggedIn: PropTypes.bool,
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loading: state.user.loading,
    profilePatched: state.user.profilePatched,
    loggedIn: state.user.loggedIn,
    profile: state.user.profile,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        showNotification,
        syncUserProfile,
        patchProfile,
        logout,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(EmailPermission));
