/**
 * External dependencies
 */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withLocalize } from "react-localize-redux";

/**
 * Internal dependencies
 */
import { PageProps } from "../types";
import { useIsLoggedIn } from "../features/user/use-is-logged-in";
import * as helpers from "../utils/Helpers";
import history from "../utils/navigation";
import Home from "./Home";
import Spinner from "../components/Spinner";
import FillScreen from "../components/FillScreen";
import { useProfile } from "../features/user/use-profile";
import { clearTracking } from "../features/tracking/trackingSlice";

const InitialRouteSelector: React.FC<PageProps> = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const [hasRoutedUser, setHasRoutedUser] = useState(false);
  const { profile, loading: profileLoading } = useProfile();
  const { active_order_id } = profile || {};

  const navigate = (pathname: string) => {
    history.push({
      pathname,
      search: window.location.search,
      state: {
        transition: "slide-right",
      },
    });
  };

  /**
   * Legacy Back Button support
   */
  useEffect(() => {
    window.previousLocation = props.location;
  });

  /**
   * Check the status of the user and possibly redirect them.
   */
  useEffect(() => {
    if (hasRoutedUser) {
      return;
    }

    // Route logged in users
    if (profileLoading === "fulfilled") {
      // Clear order tracking
      if (null === active_order_id) {
        dispatch(clearTracking());
      }

      // Route to tracking if there's an active order
      if (active_order_id) {
        navigate("/tracking");
        setHasRoutedUser(true);
        return;
      }
    }

    // Maybe route the user to the recovery pipeline
    if (helpers.isRecoveringPassword()) {
      navigate("/recovery");
      setHasRoutedUser(true);
      return;
    }

    // Maybe route the user to the welcome screens
    if (!helpers.hasSeenWelcomeScreens()) {
      navigate("/welcome");
      setHasRoutedUser(true);
      return;
    }

    setHasRoutedUser(true);
  }, [dispatch, isLoggedIn, hasRoutedUser, profileLoading, active_order_id]);

  if (!hasRoutedUser) {
    return (
      <FillScreen>
        <Spinner />
      </FillScreen>
    );
  }

  return <Home {...props} />;
};

export default withLocalize(InitialRouteSelector);
