import { MapTypeStyle } from "google-map-react";
import googleMapsConfig from "../config/google-maps.json";
import { getLocale } from "../services/user";
import { Coords } from "../types";
import placeholderMapUrl from "../assets/images/map-loading-placeholder.jpg";

export const getGoogleMapsApiKey = (): string => {
  return process.env.REACT_APP_GOOGLE_MAPS_APIKEY || googleMapsConfig.apiKey;
};

// prettier-ignore
export const getGoogleMapsStyles = (): MapTypeStyle[] => [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#000000"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#000000"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":17}]}];

// prettier-ignore
export const getStaticGoogleMapsStyles = (): string => "style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x000000%7Clightness:40&style=element:labels.text.stroke%7Ccolor:0x000000%7Clightness:15&style=feature:administrative%7Celement:geometry%7Ccolor:0x000000%7Clightness:25&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:landscape%7Celement:geometry%7Ccolor:0x000000%7Clightness:15&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x000000%7Clightness:20&style=feature:road.highway%7Celement:geometry%7Ccolor:0x000000%7Clightness:20&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000%7Clightness:15&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d";

export const getLocaleCenterPoint = (): Coords => {
  const locale = getLocale();
  const { centerPoints } = googleMapsConfig as any;

  if (locale in centerPoints) {
    return strCoordsToNumberCoords(
      centerPoints[locale as keyof typeof centerPoints]
    );
  }

  return strCoordsToNumberCoords(centerPoints.default);
};

const strCoordsToNumberCoords = ({
  lat,
  lng,
}: {
  lat: string;
  lng: string;
}): Coords => ({ lat: Number(lat), lng: Number(lng) });

export function buildStaticMapURL(coords: Coords, options = {}): string {
  if (!coords || !coords.lat || !coords.lng) return placeholderMapUrl;

  const apiKey = getGoogleMapsApiKey();

  const defaults = {
    width: 345,
    height: 200,
    scale: 2,
    zoom: 15,
    maptype: "roadmap",
  };

  const settings = {
    ...defaults,
    ...options,
  };

  const url = [
    "https://maps.googleapis.com/maps/api/staticmap",
    `?key=${apiKey}`,
    `&center=${coords.lat},${coords.lng}`,
    `&zoom=${settings.zoom}`,
    `&size=${settings.width}x${settings.height}`,
    `&scale=${settings.scale}`,
    `&${getStaticGoogleMapsStyles()}`,
  ];

  return url.join("");
}
