import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import * as helpers from "../utils/Helpers";
import PageDots from "../components/PageDots";
import Flickity from "flickity-imagesloaded";
import history from "../utils/navigation";
import HelpInstall from "../components/HelpInstall";
import HelpMRoom from "../components/HelpMRoom";
import HelpMyMRoom from "../components/HelpMyMRoom";
import Button from "../components/Button";
import ConfirmButton from "../components/ConfirmButton";
import Logo from "../components/Logo";
import { ReactComponent as ChevronRightIcon } from "../assets/images/icon-chevron-right.svg";

/**
 * Removed after a change to business logic.
 * Keeping here for a while if it needs to be put back,
 * can delete at any point though.
 */
// import HelpQueue from "../components/HelpQueue";

import HelpAccount from "../components/HelpAccount";
import RouteLink from "../components/RouteLink";
import * as userService from "../services/user";
import { withLocalize } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

class Welcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
    };

    this.isStandalone = helpers.isStandalone();
    this.titleSet = false;
    this.blockTransition = false;
    this.slideAmount = this.isStandalone ? 3 : 4;
    this.carousel = null;
    this.carouselEl = null;

    this.handleDotsClick = this.handleDotsClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);

    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleBarberClick = this.handleBarberClick.bind(this);
  }

  handleLoginClick() {
    userService.setWelcomeSeen().then(() => {
      this.goToNextScene("/login");
    });
  }

  handleBarberClick() {
    userService.setWelcomeSeen().then(() => {
      this.goToNextScene("/");
    });
  }

  goToNextScene(path) {
    history.push({
      pathname: path,
      state: {
        transition: "slide-right",
      },
    });
  }

  componentDidMount() {
    // store current location for back buttons
    window.previousLocation = this.props.location;

    this.createCarousel();

    analytics.pageView("/welcome", "Welcome");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.welcome");
      this.titleSet = true;
    }
  }

  createCarousel() {
    if (this.carouselEl === null) return false;

    this.carousel = new Flickity(this.carouselEl, {
      cellAlign: "center",
      contain: true,
      prevNextButtons: false,
      imagesLoaded: true,
      setGallerySize: false,
      pageDots: false,
    });

    this.carousel.on("change", (index) => {
      this.changeCurrentSlide(index);
    });
  }

  changeCurrentSlide(index) {
    this.setState((prevState, props) => {
      return {
        currentSlide: index,
      };
    });
  }

  handleDotsClick(index) {
    if (this.carousel === null) return false;

    this.carousel.select(index, false, false);
  }

  handleNextClick() {
    if (this.carousel === null) return false;

    const currentSlide = this.state.currentSlide;
    const nextSlide =
      currentSlide + 1 < this.slideAmount ? currentSlide + 1 : 0;

    this.carousel.select(nextSlide, false, false);
  }

  handleSkipClick() {
    userService.setWelcomeSeen().then(() => {
      history.push({
        pathname: "/login",
        state: {
          transition: "slide-right",
        },
      });
    });
  }

  render() {
    const componentClass = classNames({
      "s-welcome": true,
    });

    return (
      <div className={componentClass}>
        <div className="s-welcome__wrapper">
          <div className="s-welcome__logo">
            <Logo />
          </div>
          <div className="s-welcome__carousel">
            <div
              ref={(c) => {
                this.carouselEl = c;
              }}
              className="c-welcome-carousel"
            >
              <div
                className="c-welcome-carousel__slide"
                onClick={this.handleNextClick}
              >
                <HelpMyMRoom />
              </div>
              <div
                className="c-welcome-carousel__slide"
                onClick={this.handleNextClick}
              >
                <HelpMRoom />
              </div>

              {/*
                        <div
                            className="c-welcome-carousel__slide"
                            onClick={this.handleNextClick}
                        >
                            <HelpQueue />
                        </div>
                        */}
              <div className="c-welcome-carousel__slide">
                <HelpAccount />
              </div>
              {!this.isStandalone && (
                <div
                  className="c-welcome-carousel__slide"
                  onClick={this.handleNextClick}
                >
                  <HelpInstall />
                </div>
              )}
            </div>
          </div>
          <div className="s-welcome__dots">
            <PageDots
              amount={this.slideAmount}
              current={this.state.currentSlide}
              clickHandler={this.handleDotsClick}
            />
          </div>
          <div className="s-welcome__buttons">
            <div className="s-welcome__button">
              <ConfirmButton
                text={this.props.translate("welcome.loginButton")}
                onClick={this.handleLoginClick}
              />
            </div>
            <div className="s-welcome__button">
              <Button
                size="dark"
                icon={<ChevronRightIcon />}
                iconAfter={true}
                text={this.props.translate("welcome.findBarber")}
                onClick={this.handleBarberClick}
              />
            </div>
          </div>

          {/* {this.state.currentSlide < this.slideAmount - 1 && (
            <React.Fragment>
              <div className="s-welcome__skip" onClick={this.handleSkipClick}>
                <Translate id="welcome.skip" />
              </div>

              <div className="s-welcome__next" onClick={this.handleNextClick}>
                <Translate id="welcome.next" />
              </div>
            </React.Fragment>
          )} */}

          <div className="s-welcome__language">
            <RouteLink
              to="/profile/language"
              className="c-language-link"
              transition="switch-open"
            >
              <span>Language</span>
            </RouteLink>
          </div>
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  location: PropTypes.object,
  activeLanguage: PropTypes.object,
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Welcome));
