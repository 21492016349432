/**
 * Checks if geolocation api is available
 */
export const hasGeolocationApi = () => !!(navigator && navigator.geolocation);

/**
 * Checks if permissions api is available
 */
// export const hasPermissionsApi = () => !!(navigator && navigator.permissions);

export const hasPermissionsApi = () => false;

/**
 * Get stored geolocation permission
 */
export const getStoredGeolocationPermission = async (): Promise<string> => {
  // Try the Permissions API (doesn't work on iOS Safari)
  if (hasPermissionsApi()) {
    const permission = await navigator.permissions
      .query({ name: "geolocation" })
      .then((permission) => permission.state);

    return permission;
  }

  return "unknown";
};

/**
 * Checks if user has previously granted Geolocation permissions
 */
export const hasGrantedGeolocationPermission = async () =>
  hasPermissionsApi() &&
  navigator.permissions.query({ name: "geolocation" }).then((permission) => {
    return permission.state === "granted";
  });

/**
 * Checks if user has previously denied Geolocation permissions
 */
export const hasDeniedGeolocationPermission = async () =>
  hasPermissionsApi() &&
  navigator.permissions.query({ name: "geolocation" }).then((permission) => {
    return permission.state === "denied";
  });
