import React from "react";
import { Translate } from "react-localize-redux";
import { motion } from "framer-motion";

import { fadeInDown } from "../animations/utils";

interface CancelOrderButtonProps {
  className?: string;
  onClick: () => void;
}

const CancelOrderButton: React.FC<CancelOrderButtonProps> = ({
  className,
  onClick,
}) => {
  return (
    <motion.div
      className={className}
      {...fadeInDown}
      transition={{ type: "spring", duration: 0.6, bounce: 0.35 }}
    >
      <button className="c-btn c-btn--exit" onClick={onClick}>
        <Translate id="tracking.leave" />
      </button>
    </motion.div>
  );
};

export default CancelOrderButton;
