import React from "react";

import { motion } from "framer-motion";

const NoticeBubble: React.FC = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ type: "spring", bounce: 0.25, duration: 0.5 }}
      className="c-notice-bubble"
    >
      <div className="c-notice-bubble__content">{children}</div>
      <motion.div
        initial={{ opacity: 0, x: "-50%", y: -10, rotate: 45 }}
        animate={{ opacity: 1, x: "-50%", y: 0, rotate: 45 }}
        exit={{ opacity: 0, x: "-50%", y: -10, rotate: 45 }}
        transition={{
          type: "spring",
          bounce: 0.1,
          duration: 0.25,
          delay: 0.05,
        }}
        className="c-notice-bubble__arrow"
      />
    </motion.div>
  );
};

export default NoticeBubble;
