import React from "react";

import Button, { ButtonProps } from "../components/Button";
import { ReactComponent as ChevronRightIcon } from "../assets/images/icon-chevron-right.svg";

interface CriticalCTAButtonProps extends ButtonProps {
  showIcon?: boolean;
}

const CriticalCTAButton: React.FC<CriticalCTAButtonProps> = ({
  children,
  showIcon = true,
  ...props
}) => {
  return (
    <Button
      variant={"critical-cta"}
      icon={showIcon ? <ChevronRightIcon /> : false}
      iconAfter={true}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CriticalCTAButton;
