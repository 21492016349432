import React from "react";

interface MapMarkerProps {
  lat?: number;
  lng?: number;
}

const MapMarker: React.FC<MapMarkerProps> = () => {
  return <div className="c-map-marker" />;
};

export default MapMarker;
