import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class PageDots extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index) {
    this.props.clickHandler(index);
  }

  render() {
    const componentClass = classNames({
      "c-page-dots": true,
      "c-page-dots--tight": this.props.amount > 3,
    });

    let dots = [];

    for (let i = 0; i < this.props.amount; i++) {
      const dotClass = classNames({
        "c-page-dots__dot": true,
        "is-active": i === this.props.current,
      });

      dots.push(
        <div
          key={i}
          className={dotClass}
          onClick={() => {
            this.handleClick(i);
          }}
        />
      );
    }

    return <div className={componentClass}>{dots}</div>;
  }
}

PageDots.propTypes = {
  amount: PropTypes.number,
  current: PropTypes.number,
  clickHandler: PropTypes.func,
};

export default PageDots;
