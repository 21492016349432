import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import history from "../utils/navigation";

class Transitions extends React.Component {
  render() {
    return (
      <TransitionGroup
        childFactory={(child) =>
          React.cloneElement(child, {
            classNames:
              typeof history.location.state !== "undefined" &&
              typeof history.location.state.transition !== "undefined"
                ? "transition transition--" + history.location.state.transition
                : "transition transition--default",
            timeout:
              typeof history.location.state !== "undefined" &&
              typeof history.location.state.timeout !== "undefined"
                ? history.location.state.timeout
                : 1000,
          })
        }
      >
        <CSSTransition
          key={this.props.pageKey}
          className="transition transition--default"
          timeout={1000}
        >
          <div>{this.props.children}</div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

Transitions.propTypes = {
  pageKey: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default Transitions;
