export const slideOpen = {
  initial: {
    height: 0,
  },
  animate: {
    height: "auto",
  },
  exit: {
    height: 0,
  },
  style: {
    overflow: "hidden",
  },
};

export const slideUp = {
  initial: {
    y: "100%",
  },
  animate: {
    y: "0%",
  },
  exit: {
    y: "100%",
  },
};

export const fadeIn = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const fadeInLogin = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 0.8,
  },
  exit: {
    opacity: 0,
  },
};

export const fadeInUp = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 50,
  },
};

export const fadeInUpLogin = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 0.8,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 50,
  },
};

export const fadeInDown = {
  initial: {
    opacity: 0,
    y: -50,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -50,
  },
};

export const bounce = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: 1,
  },
  exit: {
    scale: 0,
  },
};

export const blink = {
  initial: {
    opacity: 0.5,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1.1,
  },
  exit: {
    opacity: 0.5,
    scale: 0.8,
  },
};

export const spin = {
  initial: {
    rotate: 0,
  },
  animate: {
    rotate: 359,
  },
  exit: {
    rotate: 0,
  },
};

export const zoomIn = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.9,
  },
};

export const zoomOut = {
  initial: {
    opacity: 0,
    scale: 1.5,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 1.5,
  },
};
