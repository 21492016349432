import React from "react";

import {
  ExtraService,
  ExtraServices,
  Service,
  RenderComponent,
} from "../../types";
import { priceToUnits } from "../../utils/Helpers";

interface ServiceDisplayProps extends Service {
  data?: ExtraServices;
  render?: (service: ExtraService[], index?: number) => RenderComponent;
}

const ServiceDisplay: React.FC<ServiceDisplayProps> = ({
  id,
  title,
  price,
  description,
  data,
  render,
  children,
}) => {
  const serviceExtraServices = data && data[id] ? data[id] : [];
  const hasExtraServices = !!serviceExtraServices;
  const hasRenderFunction = !!render;

  return (
    <div className="c-service-display">
      <div className="c-service-display__content">
        <h3 className="c-service-display__title">{title}</h3>
        <p className="c-service-display__description">{description}</p>

        {price && (
          <p className="c-service-display__price">{priceToUnits(price)}</p>
        )}
      </div>

      {hasRenderFunction ? (
        hasExtraServices ? (
          <div className="c-service-display__extra-services">
            {render(serviceExtraServices)}
          </div>
        ) : null
      ) : (
        <div className="c-service-display__extra-services">{children}</div>
      )}
    </div>
  );
};

export default ServiceDisplay;
