import React from "react";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";

import { Loading, TranslateString } from "../types";
// import Spinner from "./Spinner";
import { ReactComponent as CheckIcon } from "../assets/images/icon-check.svg";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import Bounce from "../features/animations/Bounce";
import CriticalCTAButton from "../components/CriticalCTAButton";

interface ConfirmButtonProps {
  text: TranslateString;
  loading: Loading;
  disabled?: boolean;
  onClick?: () => void;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  text,
  loading,
  disabled,
  onClick,
}) => {
  const buttonClass = classNames({
    "is-loading": loading === "pending",
    "is-completed": loading === "fulfilled",
  });

  return (
    <CriticalCTAButton
      className={buttonClass}
      onClick={onClick}
      disabled={disabled || loading === "pending" || loading === "fulfilled"}
      showIcon={!(loading === "pending" || loading === "fulfilled")}
    >
      <AnimatePresence>
        {loading === "pending" ? (
          <Spinner className="icon" />
        ) : loading === "fulfilled" ? (
          <Bounce className="icon">
            <CheckIcon />
          </Bounce>
        ) : (
          <span>{text}</span>
        )}
      </AnimatePresence>
    </CriticalCTAButton>
  );
};

export default ConfirmButton;
