import { useMemo } from "react";

import { Service, ExtraServices, ExtraService } from "../../types";

export const useServiceExtraServices = (
  id?: Service["id"],
  data?: ExtraServices
): ExtraService[] => {
  const extraServices = useMemo(() => {
    if (typeof id === "number" && data) {
      return data[id] || [];
    }

    return [];
  }, [id, data]);

  return extraServices;
};
