import React, { useMemo } from "react";
import { Translate } from "react-localize-redux";
import ContentLoader from "react-content-loader";

import { Benefit } from "../../types";
import { toLocalizedDateString } from "../../utils/date";
import { ReactComponent as ChevronTiny } from "../../assets/images/icon-chevron-tiny.svg";
import { getLocale } from "../../services/user";
import { loaderTheme } from "../../utils/loaders";

interface BenefitButtonProps extends Benefit {
  onClick?: () => void;
}

const BenefitButton: React.FC<BenefitButtonProps> = ({
  title,
  value,
  isRedeemable,
  dateRedeemed,
  onClick,
}) => {
  const displayDate = useMemo(() => {
    if (!dateRedeemed) {
      return null;
    }

    const locale = getLocale();
    return toLocalizedDateString(dateRedeemed, locale);
  }, [dateRedeemed]);

  if (!title && !value) {
    return <BenefitButtonLoader />;
  }

  return (
    <button
      className="c-benefit-button h-button-wrapper"
      onClick={onClick}
      disabled={!isRedeemable}
    >
      <h4 className="c-benefit-button__title">{title}</h4>
      <div className="c-benefit-button__value">{value}</div>
      <div className="c-benefit-button__footer">
        {!isRedeemable
          ? displayDate
          : onClick && (
              <span className="c-benefit-button__link">
                <Translate id="benefits.cta" />
                <ChevronTiny />
              </span>
            )}
      </div>
    </button>
  );
};

export default BenefitButton;

export const BenefitButtonLoader = () => (
  <ContentLoader
    width={"100%"}
    height={190}
    {...loaderTheme}
    style={{ width: "100%", display: "block" }}
    preserveAspectRatio="none"
  >
    <rect x="0" y="0" rx="10" ry="10" width="145" height="190" />
  </ContentLoader>
);
