/**
 * These are the constants for the orders.
 * They should always be kept in sync with what the API returns.
 */

const orderConstants = {
  status: {
    CANCELLED: "cancelled",
    ACTIVE: "active",
    COMPLETED: "serviced",
    NOSHOW: "no-show",
  },
};

export default orderConstants;
