import React from "react";
import parse from "html-react-parser";

import { ExtraService } from "../../types";
import ExtraServiceToggle from "./ExtraServiceToggle";

export interface ExtraServiceInfoProps extends ExtraService {}

const ExtraServiceInfo: React.FC<ExtraServiceInfoProps> = ({
  longDescription,
  ...rest
}) => {
  const hasLongDescription = !!longDescription;

  return (
    <div className="c-extra-service-info">
      <div className="c-extra-service-info__toggle">
        <ExtraServiceToggle {...rest} description={""} size={"large"} />
      </div>
      <div className="c-extra-service-info__description h-std">
        {hasLongDescription && parse(longDescription)}
      </div>
    </div>
  );
};

export default ExtraServiceInfo;
