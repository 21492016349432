/**
 * The purpose is this file is to help normalize data format between
 * the application and the M Room API.
 */
import * as helpers from "./Helpers";
import * as userService from "../services/user";
import ApiConstants from "../constants/api";
import { shouldForceBarbershopMockCoords } from "./Helpers";
import deprecated__barbershopPlaceholderImage from "../assets/images/placeholder-barbershop.jpg"; // TO-DO, remove
import { sortByOrder } from "./data";

const MOCK_SERVICE_LONG_DESCRIPTION = `
  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut felis, suspendisse potenti. Lorem ipsum ligula ut hendrerit mollis, ipsum erat vehicula risus, eu suscipit sem libero nec erat. Aliquam erat volutpat. Sed congue augue vitae neque. Nulla consectetuer porttitor pede. Fusce purus morbi tortor magna condimentum vel, placerat id blandit sit amet tortor.</p>
  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut felis, suspendisse potenti. Lorem ipsum ligula ut hendrerit mollis, ipsum erat vehicula risus, eu suscipit sem libero nec erat. Aliquam erat volutpat. Sed congue augue vitae neque. Nulla consectetuer porttitor pede. Fusce purus morbi tortor magna condimentum vel, placerat id blandit sit amet tortor.</p>
  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut felis, suspendisse potenti. Lorem ipsum ligula ut hendrerit mollis, ipsum erat vehicula risus, eu suscipit sem libero nec erat. Aliquam erat volutpat. Sed congue augue vitae neque. Nulla consectetuer porttitor pede. Fusce purus morbi tortor magna condimentum vel, placerat id blandit sit amet tortor.</p>
`;

/**
 * Constants
 */
const {
  FIXED_SERVICE_AMOUNT,
  BARBERSHOP_NAME_RENAME,
  BARBERSHOP_CITY_RENAME,
  BARBERSHOP_ADDRESS_RENAME,
} = ApiConstants;

/**
 * Internal helpers for renaming the mock barbershops
 *
 * @param {string} name - Original barbershop name
 * @return {string} Converted name
 */
const maybeRenameBarbershopName = (name) => {
  if (!helpers.shouldAllowBarbershopRename()) {
    return name;
  }

  if (!(name in BARBERSHOP_NAME_RENAME)) {
    return name;
  }

  return BARBERSHOP_NAME_RENAME[name];
};

/**
 * Internal helpers for renaming the mock barbershops
 *
 * @param {string} city - Original barbershop city
 * @return {string} Converted city
 */
const maybeRenameBarbershopCity = (city) => {
  if (!helpers.shouldAllowBarbershopRename()) {
    return city;
  }

  if (!(city in BARBERSHOP_CITY_RENAME)) {
    return city;
  }

  return BARBERSHOP_CITY_RENAME[city];
};

/**
 * Internal helpers for renaming the mock barbershops
 *
 * @param {string} address - Original barbershop address
 * @return {string} Converted address
 */
const maybeRenameBarbershopAddress = (address) => {
  if (!helpers.shouldAllowBarbershopRename()) {
    return address;
  }

  if (!(address in BARBERSHOP_ADDRESS_RENAME)) {
    return address;
  }

  return BARBERSHOP_ADDRESS_RENAME[address];
};

/**
 * Internal helper for coords
 *
 * @param {object} location - Barbershop location data
 * @return { object } Converted coords
 */
const coords = (location) => {
  const { latitude, longitude } = location || {};

  if (latitude === "") {
    /**
     * Our test pob doesn't have coords set to it
     */
    if (shouldForceBarbershopMockCoords()) {
      return {
        lat: 60.1650069,
        lng: 24.9349736,
      };
    }

    /**
     * Not optimal to use NaN, but currently several parts
     * of the app depend on it.
     */
    return {
      lat: NaN,
      lng: NaN,
    };
  }

  return {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };
};

/**
 * Converts the API login response format to suit the app's syntax.
 *
 * @param {object} data - User id, access token and refresh token
 * @return {object} Modified login data
 */
export function loginResponse(data) {
  return {
    id: data.customer_id,
    token: data.access_token,
    refreshToken: data.refresh_token,
  };
}

/**
 * Converts the API token refresh response format to suit the app's syntax.
 *
 * @param {object} data - User id and access token
 * @return {object} Modified refresh data
 */
export function refreshResponse(data) {
  return {
    id: data.customer_id,
    token: data.access_token,
  };
}

/**
 * Converts the API format to be suitable with the app's syntax.
 * TO-DO: Currently also fills in missing fields in the API with mock data
 *
 * @param {array} data - Compact data of barbershops
 * @return {array} Modified barbershops data
 */

export function barbershopsResponse(data) {
  return data.map((barbershop) => {
    // fetch the wait time that matches users account priority level
    const queueTime = helpers.getPriorityQueueTime(
      barbershop.service_time_estimates
    );

    return {
      id: barbershop.id,
      name: maybeRenameBarbershopName(barbershop.name),
      city: maybeRenameBarbershopCity(barbershop.city),
      address: maybeRenameBarbershopAddress(barbershop.address),
      country: userService.getCurrentLocaleName("Finland"), // TO-DO: This should come from the API instead
      open: barbershop.open,
      openingHours: openingHours(barbershop.open_times),
      openTime: openingHours(barbershop.open_times, "open"),
      closeTime: openingHours(barbershop.open_times, "close"),
      closingSoon: barbershop.closing_soon,
      coords: coords(barbershop.location),
      queue: queueTime,
      queueState: barbershop.queue_state,
      chairCount: barbershop.queue_info
        ? barbershop.queue_info.queue_employees
        : false,
    };
  });
}

/**
 * Converts the API format to be suitable with the app's syntax.
 * TO-DO: Currently also fills in missing fields in the API with mock data
 *
 * @param {object} data - extended data of barbershop
 * @return {object} Converted barbershop data
 */

export function barbershopResponse(barbershop) {
  // fetch the wait time that matches users account priority level
  const queueTime = helpers.getPriorityQueueTime(
    barbershop.service_time_estimates
  );

  const activeServiceGroupIds = barbershop.services
    ? barbershop.services.map(({ group_id }) => group_id)
    : [];

  return {
    id: barbershop.id,
    image: barbershop.image || false,
    name: maybeRenameBarbershopName(barbershop.name),
    city: maybeRenameBarbershopCity(barbershop.city),
    address: maybeRenameBarbershopAddress(barbershop.address),
    country: userService.getCurrentLocaleName("Finland"), // @todo This should come from the API instead
    floor: "", // @todo Should come from API or removed
    open: barbershop.open,
    openingHours: openingHours(barbershop.open_times),
    openTime: openingHours(barbershop.open_times, "open"),
    closeTime: openingHours(barbershop.open_times, "close"),
    closingSoon: barbershop.closing_soon,
    chairCount: barbershop.queue_info
      ? barbershop.queue_info.queue_employees
      : false,
    coords: coords(barbershop.location),
    queue: queueTime,
    queueState: barbershop.queue_state,
    serviceGroups: barbershop.service_groups
      .filter(({ id }) => activeServiceGroupIds.includes(id))
      .map(serviceGroupResponse)
      .sort(sortByOrder),
    services: barbershop.services.map(serviceResponse).sort(sortByOrder),
    extraServices: extraServicesResponse(barbershop.extra_services),
  };
}

const serviceGroupResponse = (group) => ({
  id: group.id,
  title: group.title,
  order: group.display_order,
  description: group.details,
  parentId: group.parent,
});

const serviceResponse = (service) => ({
  id: service.id,
  groupId: service.group_id,
  title: service.title,
  order: service.display_order,
  description: service.details,
  longDescription:
    service.long_description || helpers.shouldForceServiceLongDescription()
      ? MOCK_SERVICE_LONG_DESCRIPTION
      : null,
  price: service.price,
  duration: service.duration,
});

const extraServicesResponse = (data) => {
  const objectIndexedByServiceId = data.reduce((acc, current, index) => {
    const { service_id, services } = current;
    const extraServices = [...services]
      .map((extraService) => extraServiceResponse(extraService, service_id))
      .sort(sortByOrder);

    if (!acc[service_id]) {
      acc[service_id] = extraServices;
    } else {
      acc[service_id] = [...acc[service_id], extraServices];
    }

    return acc;
  }, {});

  return objectIndexedByServiceId;
};

const extraServiceResponse = (extraService, serviceId) => ({
  id: extraService.service_id,
  serviceId,
  title: extraService.title,
  order: extraService.display_order,
  description: extraService.details,
  longDescription:
    extraService.long_description || helpers.shouldForceServiceLongDescription()
      ? MOCK_SERVICE_LONG_DESCRIPTION
      : null,
  price: extraService.price,
  duration: extraService.duration,
});

/**
 * Converts the app service format to be suitable with the API's syntax
 *
 * @param {object} barbershop - Object containing the information of barbershop
 * @param {array} services - Array of the selected services
 * @param {object} extraServices - Service id indexed object of the selected extra services
 * @return {object} Converted order data
 */
export const sendOrderPayload = (barbershop, services, extraServices) => {
  const { id: pob_id } = barbershop;

  const items = services.reduce((result, current) => {
    const service = {
      amount: FIXED_SERVICE_AMOUNT,
      service_id: current.id,
      service_info: {
        duration: current.duration,
        group_id: current.groupId,
        id: current.id,
        price: current.price,
        title: current.title,
      },
      extra_services: extraServices[current.id]
        ? extraServices[current.id].map((extraService) => ({
            amount: FIXED_SERVICE_AMOUNT,
            service_id: extraService.id,
            title: extraService.title,
          }))
        : [],
    };

    result.push(service);

    return result;
  }, []);

  return { pob_id, items };
};

/**
 * Converts the app send order response to be suitable with the API's syntax
 *
 * @note This is mostly for legacy purposes, the response is used in Login.jsx
 * @param {object} data - Response data of order send
 * @return {object} Converted data
 */
export const sendOrderResponse = (data) => ({
  orderId: data.id,
});

/**
 * Converts the API format to be suitable with the app's syntax.
 *
 * @param {object} data - check in data
 * @return {object} Converted check in data
 */

export function checkInPayload(data) {
  return {
    customer_at_pob: data.checkedIn,
  };
}

/**
 * Converts the API order response format to be suitable with the app's syntax.
 *
 * @param {object} data - Response data of order send
 * @param {number} userId - User id
 * @return {object} Returns modified order data
 */
export function orderResponse(data, userId) {
  return {
    userId,
    orderId: data.id,
    date: data.date,
    barbershopId: data.pob_id,
    status: data.status,
    checkedIn: data.customer_at_pob,
    queue: data.service_time_estimate?.wait_time,
    services: data.items.map((service) => {
      return {
        amount: service.amount,
        id: service.service_id,
        description: service.service_info?.details,
        name: service.service_info?.title,
        order: service.service_info?.display_order,
        price: service.service_info?.price,
        duration: service.service_info?.duration,
        groupName: service.service_info?.group,
        extraServices: service.extra_services.map((extraService) => {
          return {
            amount: extraService.amount,
            id: extraService.service_id,
            name: extraService.service_info.title,
            order: extraService.service_info.display_order,
            price: extraService.service_info.price,
            duration: extraService.service_info.duration,
          };
        }),
      };
    }),
  };
}

/**
 * Converts the API orders response format to be suitable with the app's syntax.
 *
 * @param {array} data - Response data of order send
 * @param {number} userId - User id
 * @return {array} Returns modified order data
 */

export function ordersResponse(data, userId) {
  return data.map((order) => {
    return orderResponse(order, userId);
  });
}

/**
 * Converts the API receipt response format to be suitable with the app's syntax.
 *
 * @param {object} data - Response data of receipt send
 * @param {number} userId - User id
 * @return {object} Returns modified receipt data
 */

export function receiptResponse(data, userId) {
  return {
    userId,
    orderId: data.id,
    date: data.created,
    rows: data.rows,
    barbershop: data.pob,
    status: data.status,
    type: data.type,
  };
}

/**
 * Converts the API receipts response format to be suitable with the app's syntax.
 *
 * @param {array} data - Response data of receipts
 * @param {number} userId - User id
 * @return {array} Returns modified receipts data
 */

export function receiptsResponse(data, userId) {
  return data.map((receipt) => {
    return receiptResponse(receipt, userId);
  });
}

/**
 * Converts the API opening hours format to a presentational string.
 *
 * @param {array} data - Opening hours data
 * @param {string} format - Choose if you want to return in "open", "close" or "full" format
 * @return {string} Today's opening hours
 */

export function openingHours(data, format = "full") {
  // get the name of the current day in same format as the API
  const dt = new Date();
  const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const today = weekdays[dt.getDay()];

  const openingHours = data.find((obj) => {
    return obj.weekday === today;
  });

  if (typeof openingHours === "undefined") return false;

  const open = helpers.cleanTime(openingHours.open);
  const close = helpers.cleanTime(openingHours.close);

  switch (format) {
    case "open":
      return `${open}`;
    case "close":
      return `${close}`;
    default:
      return `(${open} - ${close})`;
  }
}

/**
 * -------------------------------------------------------------------
 * Deprecated:
 * -------------------------------------------------------------------
 */

/**
 * Converts the API format to be suitable with the app's syntax.
 * TO-DO: Currently also fills in missing fields in the API with mock data
 *
 * @deprecated Use barbershopResponse instead
 * @param {object} data - extended data of barbershop
 * @return {object} Converted barbershop data
 */

export function deprecated__barbershopResponse(barbershop) {
  // fetch the wait time that matches users account priority level
  const queueTime = helpers.getPriorityQueueTime(
    barbershop.service_time_estimates
  );

  return {
    id: barbershop.id,
    image: deprecated__barbershopPlaceholderImage,
    name: barbershop.name,
    city: barbershop.city,
    address: barbershop.address,
    country: userService.getCurrentLocaleName("Finland"), // @todo This should come from the API instead
    floor: "", // @todo Should come from API or removed
    open: barbershop.open,
    openingHours: openingHours(barbershop.open_times),
    openTime: openingHours(barbershop.open_times, "open"),
    closeTime: openingHours(barbershop.open_times, "close"),
    closingSoon: barbershop.closing_soon,
    chairCount: barbershop.queue_info ? barbershop.queue_info.employees : false,
    coords: coords(barbershop.location),
    queue: queueTime,
    queueState: barbershop.queue_state,
    services: deprecated__barbershopServicesResponse(
      barbershop.service_groups,
      barbershop.services,
      barbershop.extra_services
    ),
  };
}

/**
 * Converts the API barbershop response's services from separate arrays to a
 * single hierarcichal array.
 *
 * @deprecated
 * @param {array} groups - The top levle service groups
 * @param {array} services - An array of the services linked to groups
 * @param {array} extras - The extra services linked to services
 * @return {array} Converted barbershop services
 */
export function deprecated__barbershopServicesResponse(
  groups,
  services,
  extras
) {
  const convertedGroups = groups.map((group) => {
    return {
      id: group.id,
      title: group.title,
      order: group.display_order,
      description: group.details,
      allowMultiple: true, // TO-DO, currently not available from the API
      options: services
        .filter((service) => {
          return service.group_id === group.id;
        })
        .map((service) => {
          return {
            id: service.id,
            name: service.title,
            description: service.details,
            order: service.display_order, // debug: helpers.randomBetween(1, 8)
            duration: service.duration,
            price: service.price,
            available: true, // TO-DO, currently all products returned by API are available
            extras: deprecated__barbershopExtrasResponse(service, extras),
          };
        }),
    };
  });

  // filter out empty groups
  const filteredGroups = convertedGroups.filter((group) => {
    return group.options.length;
  });

  return filteredGroups;
}

/**
 * Finds the correct extra services that are children of the specified
 * service.
 *
 * @deprecated
 * @param {object} service - Selected service
 * @param {array} extras - Array of all the extra services available for a barbershop
 * @return {array} Converted array of extra services
 */
export function deprecated__barbershopExtrasResponse(service, extras) {
  // find the extra service containers that belong under this service
  const filteredExtras = extras.filter((extra) => {
    return extra.service_id === service.id;
  });

  // convert all the extras found under filtered service containers
  const convertedExtras = filteredExtras.map((extra) => {
    // actual extras are under a "services" key
    return extra.services.map((service) => {
      return {
        id: service.service_id,
        name: service.title,
        order: service.display_order,
        duration: service.duration,
        price: service.price,
        description: service.details,
      };
    });
  });

  // flatten all the extras arrays before returning
  return [].concat(...convertedExtras);
}

/**
 * Converts the app service format to be suitable with the API's syntax.
 * The "services" are considered "service groups" by the API, and the "options"
 * are considered as "services" by the API.
 *
 * @deprecated Use orderPayload instead
 * @param {array} services - Array of the selected services
 * @param {object} barbershop - Object containing the information of barbershop
 * @return {object} Converted order data
 */

export function deprecated__orderPayload(services, barbershop) {
  const convertedServices = services.map((service) => {
    return service.options.map((option) => {
      return {
        amount: 1, // All services are currently added only as singles
        service_id: option.id,
        service_info: {
          duration: option.duration,
          group_id: service.id,
          id: option.id,
          price: option.price,
          title: option.name,
        },
        extra_services: option.extras.map((extra) => {
          return {
            amount: 1, // All services are currently added only as singles
            service_id: extra.id,
            title: extra.name,
          };
        }),
      };
    });
  });

  // merge to single array
  const items = [].concat(...convertedServices);

  return {
    pob_id: barbershop.id,
    items: items,
  };
}

/**
 * Converts the API order response format to be suitable with the app's syntax.
 *
 * @deprecated use orderResponse instead
 * @param {object} data - Response data of order send
 * @param {number} userId - User id
 * @return {object} Returns modified order data
 */

export function deprecated__orderResponse(data, userId) {
  const queue = data.service_time_estimate
    ? data.service_time_estimate.wait_time
    : false;

  return {
    userId,
    orderId: data.id,
    date: data.date,
    barbershopId: data.pob_id,
    services: deprecated__orderServicesResponse(data.items),
    status: data.status,
    checkedIn: data.customer_at_pob,
    queue: queue,
  };
}

/**
 * Converts the API orders response format to be suitable with the app's syntax.
 *
 * @deprecated use ordersResponse instead
 * @param {array} data - Response data of order send
 * @param {number} userId - User id
 * @return {array} Returns modified order data
 */

export function deprecated__ordersResponse(data, userId) {
  return data.map((order) => {
    return deprecated__orderResponse(order, userId);
  });
}

/**
 * Converts the services from API format to be suitable with the app's syntax.
 *
 * @deprecated
 * @param {array} services - Array of the selected services
 * @return {array} Returns re-arranged services
 */

export function deprecated__orderServicesResponse(services) {
  // build a new array from unique service group id's found inside services
  const groups = [
    ...new Set(
      services
        .filter((service) => typeof service.service_info !== "undefined")
        .map((service) => service.service_info.group_id)
    ),
  ];

  // if there are no groups, just add an extra level to the array
  if (!groups.length) return services.map((service) => [service]);

  const groupedServices = groups.map((group) => {
    return {
      id: group,
      name: false, // TO-DO: currently service group names unavailable from the API
      options: services
        .filter((service) => {
          return (
            typeof service.service_info !== "undefined" &&
            service.service_info.group_id === group
          );
        })
        .map((service) => {
          return {
            id: service.service_id,
            description: service.service_info.details,
            name: service.service_info.title,
            order: service.service_info.display_order,
            price: service.service_info.price,
            duration: service.service_info.duration,
            category: service.service_info.group,
            extras: service.extra_services.map((extra) => {
              return {
                amount: 1, // All services are currently added only as singles
                id: extra.service_id,
                name: extra.service_info.title,
                order: extra.service_info.display_order,
                price: extra.service_info.price,
                duration: extra.service_info.duration,
              };
            }),
          };
        }),
    };
  });

  return groupedServices;
}
