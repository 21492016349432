/**
 * External dependencies
 */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import { AnimatePresence } from "framer-motion";
import classNames from "classnames";

/**
 * Internal dependencies
 */
import { PageProps, StoreSelectedSource } from "../types";
import {
  fetchBarbershops,
  selectBarbershops,
} from "../features/barbershop/barbershopSlice";
import { analytics } from "../utils/Analytics";
import { setPageTitle } from "../utils/seo";
import { useFavoriteBarbershops } from "../features/user/use-favorite-barbershops";
import { useFocusInterval } from "../utils/use-focus-interval";
import { useGeolocationDataReadyEvent } from "../features/analytics/use-geolocation-data-ready-event";
import { useSilentLocationUpdate } from "../features/user/use-silent-location-update";
import { useBarbershopSearch } from "../features/barbershop/use-barbershop-search";
import { useUserLocation } from "../features/user/use-user-location";
import ApiConstants from "../constants/api";
import BarbershopCard, {
  BarbershopCardLoader,
} from "../features/barbershop/BarbershopCard";
import BarbershopList from "../features/barbershop/BarbershopList";
import BarbershopListByDistance from "../features/barbershop/BarbershopListByDistance";
import ListLabel, { ListLabelLoader } from "../features/barbershop/ListLabel";
import SearchInput from "../components/SearchInput";
import HorizontalButtonGroup from "../components/HorizontalButtonGroup";
import GhostButton from "../components/GhostButton";
import BarbershopsMap from "../features/maps/BarbershopsMap";
import SlideUp from "../features/animations/SlideUp";
import RouteLink from "../components/RouteLink";

/**
 * Assets
 */
import { ReactComponent as LocateIcon } from "../assets/images/icon-locator-yellow.svg";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { ReactComponent as MapIcon } from "../assets/images/icon-map.svg";
import { ReactComponent as FavoriteIcon } from "../assets/images/icon-favorite-active.svg";
import { ReactComponent as GlobeIcon } from "../assets/images/icon-globe.svg";

/**
 * Constants
 */
const { BARBERSHOPS_REFRESH_TIMEOUT } = ApiConstants;
const ANALYTICS_SLUG = "/home";
const ANALYTICS_TITLE = "Home";

interface HomeProps extends PageProps {}

const Home: React.FC<HomeProps> = ({ activeLanguage, translate }) => {
  /**
   * Utils, refs
   */
  const dispatch = useDispatch();

  /**
   * User location & locale
   */
  const {
    isLocated,
    locate,
    loading: locationLoading,
  } = useUserLocation({
    title: translate("geolocation.errorTitle"),
    text: translate("geolocation.errorText"),
  });

  useSilentLocationUpdate();

  /**
   * Load barbershops
   */
  const barbershops = useSelector(selectBarbershops);

  const memoizedFetchBarbershops = useCallback(() => {
    dispatch(fetchBarbershops());
  }, [dispatch]);

  useEffect(() => {
    memoizedFetchBarbershops();
  }, [memoizedFetchBarbershops]);

  useFocusInterval(memoizedFetchBarbershops, BARBERSHOPS_REFRESH_TIMEOUT);

  /**
   * Search
   */
  const {
    results: filteredBarbershops,
    hasResults: hasFilteredBarbershops,
    query,
    setQuery,
    hasQuery,
  } = useBarbershopSearch(barbershops, 500);

  /**
   * Favorites
   */
  const favoriteBarbershops = useFavoriteBarbershops(barbershops);
  const hasFavorites = !!(favoriteBarbershops && favoriteBarbershops.length);

  /**
   * Barbershops map
   */
  const [showMap, setShowMap] = useState(false);

  /**
   * SEO & Analytics
   */
  useEffect(() => {
    if (activeLanguage) {
      setPageTitle([translate("title.base"), translate("title.list")]);
    }
  }, [activeLanguage, translate]);

  useEffect(() => {
    analytics.pageView(ANALYTICS_SLUG, ANALYTICS_TITLE);
  }, []);

  useGeolocationDataReadyEvent(ANALYTICS_SLUG, ANALYTICS_TITLE);

  /**
   * Classnames
   */
  const componentClass = classNames("s-home", {
    "h-scrollable": !showMap,
  });

  return (
    <div className={componentClass}>
      <div className="s-home__wrapper">
        <div className="s-home__header">
          <h3 className="s-home__title">{translate("list.chooseBarber")}</h3>
          <div className="s-home__language">
            <RouteLink
              to="/profile/language"
              className="c-language-link"
              transition="switch-open"
            >
              <span>
                <GlobeIcon width={19} height={19} />
              </span>
            </RouteLink>
          </div>
        </div>
        <div className="s-home__search">
          <SearchInput
            placeholder={translate("search.placeholder").toString()}
            value={query}
            onChange={(value) => setQuery(value)}
            onClear={() => setQuery("")}
          />
        </div>

        <div className="s-home__buttons">
          <HorizontalButtonGroup>
            <GhostButton
              text={translate("list.closestButton")}
              icon={
                locationLoading === "pending" ? (
                  <Spinner width={21} height={21} />
                ) : (
                  <LocateIcon width={21} height={21} />
                )
              }
              onClick={locate}
              disabled={locationLoading === "pending"}
              isActive={isLocated}
            />
            <GhostButton
              text={translate("list.mapButton")}
              icon={<MapIcon width={17} height={17} />}
              onClick={() => setShowMap(true)}
            />
          </HorizontalButtonGroup>
        </div>

        {!hasQuery && hasFavorites && (
          <>
            <ListLabel icon={<FavoriteIcon />}>
              <Translate id="list.favoriteLabel" />
            </ListLabel>

            <BarbershopList
              className={"s-home__list s-home__list--favorites"}
              itemClassName={"s-home__item"}
              barbershops={favoriteBarbershops}
              render={(barbershop) => (
                <BarbershopCard
                  barbershop={barbershop}
                  source={StoreSelectedSource.Favorite}
                />
              )}
            />
          </>
        )}

        {hasQuery && !hasFilteredBarbershops && (
          <h3 className="s-home__no-results">
            <Translate id="search.resultsNotFound" />
          </h3>
        )}

        {isLocated ? (
          <BarbershopListByDistance
            className={"s-home__list s-home__list--distance"}
            itemClassName={"s-home__item"}
            barbershops={filteredBarbershops}
            render={(barbershop, distance, unitSystem) => (
              <BarbershopCard
                barbershop={barbershop}
                distance={distance}
                unitSystem={unitSystem}
                source={
                  hasQuery
                    ? StoreSelectedSource.Search
                    : StoreSelectedSource.GeolocationList
                }
              />
            )}
            loader={
              <>
                <ListLabelLoader />
                <BarbershopCardLoader />
                <ListLabelLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <ListLabelLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
              </>
            }
          />
        ) : (
          <BarbershopList
            className={"s-home__list s-home__list--alphabetical"}
            itemClassName={"s-home__item"}
            barbershops={filteredBarbershops}
            render={(barbershop) => (
              <BarbershopCard
                barbershop={barbershop}
                source={
                  hasQuery
                    ? StoreSelectedSource.Search
                    : StoreSelectedSource.List
                }
              />
            )}
            loader={
              <>
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
                <BarbershopCardLoader />
              </>
            }
          />
        )}
      </div>

      <AnimatePresence>
        {showMap && (
          <SlideUp className="s-home__map">
            <BarbershopsMap
              barbershops={barbershops}
              onClose={() => setShowMap(false)}
            />
          </SlideUp>
        )}
      </AnimatePresence>
    </div>
  );
};

export default withLocalize(Home);
