import {
  TrackingOrderService,
  TrackingOrderExtraService,
  Service,
  ExtraService,
} from "../../types";

/**
 * This is a temporary solution as the tracking data model is slightly different from order.
 *
 * @todo Make tracking use same data model as order
 */
export const trackingOrderServiceToStandardService = ({
  id,
  name,
  order,
  description,
  price,
  duration,
}: TrackingOrderService): Service => ({
  id,
  order,
  description,
  price,
  duration,

  // Deviating types:
  groupId: 0,
  title: name,
});

/**
 * This is a temporary solution as the tracking data model is slightly different from order.
 *
 * @todo Make tracking use same data model as order
 */
export const trackingOrderExtraServiceToStandardExtraService = ({
  id,
  name,
  order,
  price,
  duration,
}: TrackingOrderExtraService): ExtraService => ({
  id,
  order,
  price,
  duration,

  // Deviating types:
  serviceId: 0,
  title: name,
  description: "",
});
