import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoogleMapReact from "google-map-react";
import { withLocalize, Translate } from "react-localize-redux";

import Locator from "../components/Locator";
import MapMarker from "./MapMarker";
import ShopMarker from "./ShopMarker";
import { getGoogleMapsApiKey, getGoogleMapsStyles } from "../utils/google-maps";
import { locateUser } from "../ducks/user";

class ShopMap extends React.Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.settings = {
      key: getGoogleMapsApiKey(),
      center: {
        lat: props.position.lat,
        lng: props.position.lng,
      },
      zoom: 15,
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: "greedy",
        backgroundColor: "transparent",
        styles: getGoogleMapsStyles(),
      },
    };

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.getUserLocation = this.getUserLocation.bind(this);
  }

  handleCloseClick() {
    this.props.closeClickHandler();
  }

  getUserLocation() {
    this.props.actions.locateUser();
  }

  render() {
    const hasShopPosition =
      typeof this.settings.center.lat === "number" &&
      !isNaN(this.settings.center.lat) &&
      typeof this.settings.center.lng === "number" &&
      !isNaN(this.settings.center.lng);

    const userLocated =
      this.props.user.latitude && this.props.user.longitude ? true : false;

    const googleMapsLink = hasShopPosition
      ? `https://www.google.com/maps/dir/?api=1&origin=${this.props.user.latitude},${this.props.user.longitude}&destination=${this.settings.center.lat},${this.settings.center.lng}`
      : "#";

    const componentClass = classNames({
      "c-map": true,
      "c-map--shop": true,
    });

    const userMarker = userLocated ? (
      <MapMarker
        lat={this.props.user.latitude}
        lng={this.props.user.longitude}
      />
    ) : (
      ""
    );

    return (
      <div className={componentClass}>
        {hasShopPosition ? (
          <GoogleMapReact
            bootstrapURLKeys={{ key: this.settings.key }}
            center={this.settings.center}
            zoom={this.settings.zoom}
            options={this.settings.options}
            ref={(map) => {
              this.map = map;
            }}
          >
            {userMarker}
            <ShopMarker
              lat={this.settings.center.lat}
              lng={this.settings.center.lng}
            />
          </GoogleMapReact>
        ) : (
          <div className="c-map__error">
            <h2>
              <Translate id="tracking.shopLocationErrorTitle" />
            </h2>
            <p>
              <Translate id="tracking.shopLocationErrorText" />
            </p>
          </div>
        )}

        {hasShopPosition && userLocated && (
          <div className="c-map__link">
            <a
              href={googleMapsLink}
              target="_blank"
              rel="noreferrer"
              className="c-btn c-btn--small c-btn--rounded"
            >
              <Translate id="tracking.directions" />
            </a>
          </div>
        )}

        {hasShopPosition && (
          <div className="c-map__locator">
            <Locator
              clickHandler={this.getUserLocation}
              loading={this.props.user.loading}
            />
          </div>
        )}

        <div className="c-map__close" onClick={this.handleCloseClick} />
      </div>
    );
  }
}

ShopMap.propTypes = {
  user: PropTypes.object,
  position: PropTypes.object.isRequired,
  closeClickHandler: PropTypes.func,
  actions: PropTypes.object,
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    user: {
      loading: state.user.loading,
      latitude: state.user.latitude,
      longitude: state.user.longitude,
    },
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        locateUser,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ShopMap));
