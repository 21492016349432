import React from "react";

import { TranslateString } from "../../types";
import SlideUp from "../animations/SlideUp";
import ServiceDisplay from "../order/ServiceDisplay";
import ExtraServiceDisplay from "../order/ExtraServiceDisplay";
import {
  trackingOrderServiceToStandardService,
  trackingOrderExtraServiceToStandardExtraService,
} from "./utils";
import { useSelector } from "react-redux";
import { selectTrackingOrder } from "./trackingSlice";

interface OrderReceiptProps {
  className?: string;
  title: TranslateString;
  onClose: () => void;
}

const OrderReceipt: React.FC<OrderReceiptProps> = ({
  className,
  title,
  onClose,
}) => {
  const order = useSelector(selectTrackingOrder);
  const { services } = order || {};
  const hasServices = !!(services && services.length);

  return (
    <SlideUp className={className}>
      <div className="c-order-receipt h-scrollable">
        <div className="c-order-receipt__content">
          <h1 className="c-order-receipt__title">{title}</h1>

          {hasServices && (
            <div className="c-order-receipt__services">
              {services.map((service) => (
                <ServiceDisplay
                  key={service.id}
                  {...trackingOrderServiceToStandardService(service)}
                >
                  {service.extraServices.map((extraService) => (
                    <ExtraServiceDisplay
                      key={extraService.id}
                      {...trackingOrderExtraServiceToStandardExtraService(
                        extraService
                      )}
                    />
                  ))}
                </ServiceDisplay>
              ))}
            </div>
          )}
        </div>

        <div className="c-order-receipt__close" onClick={onClose} />
      </div>
    </SlideUp>
  );
};

export default OrderReceipt;
