const ApiConstants = {
  DEFAULT_LOCALE: "FI",
  MOCK_USER_ID: 999999999999,
  BARBERSHOPS_FETCH_CACHE: 5000,
  BARBERSHOPS_REFRESH_TIMEOUT: 30000,
  BARBERSHOP_REFRESH_TIMEOUT: 30000,
  ORDER_REFRESH_TIMEOUT: 30000,
  RECOVERY_TOKEN_TIMEOUT: 60000 * 60,
  MOCK_BARBERSHOP_NAMES: ["EVERMADE TESTI"],
  MROOM_API_ERROR_TIMESLOT: "Could not allocate time slot for order",
  MROOM_API_ERROR_RECOVERY_PHONE: "Invalid phone number",
  MROOM_API_ERROR_RECOVERY_WEAK_PASSWORD: "Password too weak",
  MROOM_API_ERROR_RECOVERY_MISSING_PASSWORD: "Missing password parameter",
  MROOM_API_ERROR_RECOVERY_MISSING_TOKEN: "Missing token parameter",
  MROOM_API_ERROR_RECOVERY_MISSING_PHONE: "Missing phone parameter",
  FIXED_SERVICE_AMOUNT: 1,

  /**
   * Rename some of the (mock) barbershop data
   * to make it more consistent with others
   * and easier to style.
   */
  BARBERSHOP_NAME_RENAME: {
    "EVERMADE TESTI": "Evermade",
  },
  BARBERSHOP_CITY_RENAME: {
    "M Company": "Helsinki",
  },
  BARBERSHOP_ADDRESS_RENAME: {
    "testikatu 1": "Lönnrotinkatu 18 C2",
  },
};

export default ApiConstants;
