import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import userConstants from "../constants/user";
import * as helpers from "../utils/Helpers";
import RouteLink from "./RouteLink";
import Dropdown from "./Dropdown";
import countryData from "../data/limitedCountries.json";
import { withLocalize, Translate } from "react-localize-redux";

import { setLocale, setLocaleFromIP } from "../ducks/user";
import Checkbox from "./Checkbox";
import ConfirmButton from "./ConfirmButton";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.countryData = countryData;
    this.countryOptions = this.getCountryOptions(countryData);

    this.state = {
      countryCode: helpers.getInitialDialcode(
        this.props.locale,
        this.countryData
      ),
      phone: "",
      password: "",
      rememberLogin: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.rememberLoginHandler = this.rememberLoginHandler.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    if (this.props.locale) {
      const language = localStorage.getItem("language");

      if (!language) {
        this.setLanguageFromCountry(this.props.locale);
      }
    } else {
      this.props.actions.setLocaleFromIP(this.countryData);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.props.locale && newProps.locale) {
      this.setState({
        countryCode: helpers.getDialcodeFromLocale(
          newProps.locale,
          this.countryData
        ),
      });

      const language = localStorage.getItem("language");

      if (!language) {
        this.setLanguageFromCountry(newProps.locale);
      }
    }
  }

  submitHandler(event) {
    event.preventDefault();

    const data = {
      phone: this.state.countryCode + this.state.phone,
      password: this.state.password,
      rememberLogin: this.state.rememberLogin,
    };

    this.props.submitHandler(data);
  }

  changeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  rememberLoginHandler() {
    this.setState({
      rememberLogin: !this.state.rememberLogin,
    });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryCode: selectedOption,
    });

    this.props.actions.setLocale(
      helpers.getLocaleFromDialcode(selectedOption, this.countryData)
    );
  }

  getCountryOptions(data) {
    return data.map((country) => {
      return {
        value: country.dial_code,
        label: country.name,
      };
    });
  }

  getCountryDataFromCode(code) {
    return this.countryData.find((country) => {
      return country.code === code;
    });
  }

  setLanguageFromCountry(countryData) {
    const allowedLanguages = userConstants.ALLOWED_LANGUAGES;
    const countryLanguage = helpers.countryCodeToLanguage(countryData);

    if (countryLanguage && allowedLanguages.includes(countryLanguage)) {
      this.props.setActiveLanguage(countryLanguage);

      localStorage.setItem("language", countryLanguage);
    }
  }

  render() {
    const formClass = classNames({
      "c-login-form": true,
      "c-login-form--filled": this.state.phone && this.state.password,
    });

    const phoneClass = classNames({
      "c-login-form__phone": true,
      "has-error": this.props.errors.phone,
    });

    const passwordClass = classNames({
      "c-login-form__password": true,
      "has-error": this.props.errors.password,
    });

    const phoneError = this.props.errors.phone ? (
      <div className="c-login-form__error-message">
        <Translate id="login.phoneError" />
      </div>
    ) : (
      ""
    );
    const passwordError = this.props.errors.password ? (
      <div className="c-login-form__error-message">
        <Translate id="login.passwordError" />
      </div>
    ) : (
      ""
    );

    const submitLoading = this.props.loggedIn
      ? "fulfilled"
      : this.props.authenticating
      ? "pending"
      : "idle";

    return (
      <div className={formClass}>
        <form onSubmit={this.onSubmit} method="post">
          <div className="c-login-form__field">
            <div className="c-login-form__country-code">
              <Dropdown
                changeHandler={this.handleCountryChange}
                options={this.countryOptions}
                selectedOption={this.state.countryCode}
              />
            </div>
            <input
              type="tel"
              name="phone"
              placeholder="401234567"
              required
              className={phoneClass}
              value={this.state.phone}
              onChange={this.changeHandler}
            />
            <label htmlFor="phone" className="c-login-form__text-input-label">
              <Translate id="login.phoneLabel" />
            </label>
            {phoneError}
          </div>
          <div className="c-login-form__field">
            <input
              type="password"
              name="password"
              required
              className={passwordClass}
              value={this.state.password}
              onChange={this.changeHandler}
            />
            <label
              htmlFor="password"
              className="c-login-form__text-input-label"
            >
              <Translate id="login.passwordLabel" />
            </label>
            {passwordError}
          </div>
          <div className="c-login-form__field c-login-form__field--no-label">
            <Translate>
              {({ translate }) => (
                <Checkbox
                  label={translate("login.rememberLogin")}
                  value={this.state.rememberLogin}
                  onChange={this.rememberLoginHandler}
                />
              )}
            </Translate>
          </div>
          <div className="c-login-form__submit">
            <Translate>
              {({ translate }) => (
                <ConfirmButton
                  onClick={this.submitHandler}
                  loading={submitLoading}
                  disabled={submitLoading === "pending"}
                  text={translate("login.submitText")}
                />
              )}
            </Translate>
          </div>
          <div className="c-login-form__links">
            <RouteLink
              to="/recovery"
              className="c-text-link"
              transition="switch-open"
              onClick={this.props.onLinkClick}
            >
              <span>
                <Translate
                  id="recovery.linkText"
                  options={{ renderInnerHtml: true }}
                />
              </span>
            </RouteLink>
            <RouteLink
              to="/register"
              className="c-text-link"
              transition="switch-open"
              onClick={this.props.onLinkClick}
            >
              <span>
                <Translate
                  id="register.linkText"
                  options={{ renderInnerHtml: true }}
                />
              </span>
            </RouteLink>
          </div>
          <div className="c-login-form__info">
            <span className="h-text-small">
              <Translate
                id="login.vpnInfo"
                options={{ renderInnerHtml: true }}
              />
            </span>
          </div>
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setActiveLanguage: PropTypes.func,
  actions: PropTypes.object,
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  authenticating: PropTypes.bool,
  loggedIn: PropTypes.bool,
  onLinkClick: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    locale: state.user.locale,
    authenticating: state.user.authenticating,
    loggedIn: state.user.loggedIn,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        setLocale,
        setLocaleFromIP,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(LoginForm));
