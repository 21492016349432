import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default history;

/**
 * Keep previous and current pathname in memory.
 */
history.listen((location) => {
  const { pathname } = location || {};
  setPreviousPathname(window.currentPathname);
  setCurrentPathname(pathname);
});

/**
 * Getters and setters used for traversing back history.
 * This is not the best way to do this, but works with the
 * old implementation quite nicely.
 */
export const getPreviousPathname = () => window.previousPathname;

export const setPreviousPathname = (pathname) => {
  window.previousPathname = pathname;
};

export const getCurrentPathname = () => window.currentPathname;

export const setCurrentPathname = () => {
  const {
    location: { pathname },
  } = history || {};
  window.currentPathname = pathname;
};

/**
 * Helper for navigation.
 */
export const navigateTo = (pathname, transition = "slide-right") => {
  history.push({
    pathname,
    state: {
      transition,
    },
  });
};
