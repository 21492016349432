import React from "react";
import PropTypes from "prop-types";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.changeHandler(e.target.value);
  }

  render() {
    const options = this.props.options.map((option, index) => {
      return (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      );
    });

    return (
      <div className="c-dropdown">
        <div className="c-dropdown__placeholder">
          {this.props.selectedOption}
        </div>
        <select
          className="c-dropdown__select"
          defaultValue={this.props.selectedOption}
          onChange={this.handleChange}
        >
          {options}
        </select>
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.array,
  selectedOption: PropTypes.string,
  changeHandler: PropTypes.func,
};

export default Dropdown;
