import React from "react";
import { Translate } from "react-localize-redux";
import { AnimatePresence } from "framer-motion";
import ContentLoader from "react-content-loader";

import { priceToUnits } from "../../utils/Helpers";
import { toLocalizedDateString } from "../../utils/date";
import { getLocale } from "../../services/user";
import FadeIn from "../animations/FadeIn";
import { loaderTheme } from "../../utils/loaders";

interface BonusCounterProps {
  amount?: number;
  expirationDate?: string;
}

const BonusCounter: React.FC<BonusCounterProps> = ({
  amount,
  expirationDate,
}) => {
  const hasAmount = typeof amount === "number";

  return (
    <div className="c-bonus-counter">
      <h2 className="c-bonus-counter__">
        <Translate id="bonusSystem.counter.title" />
      </h2>

      <AnimatePresence exitBeforeEnter>
        {hasAmount ? (
          <FadeIn
            key={amount}
            className="c-bonus-counter__value"
            duration={1}
            exit={{ transition: { duration: 0 } }}
          >
            {priceToUnits(amount)}
          </FadeIn>
        ) : (
          <CounterValueLoader />
        )}
      </AnimatePresence>

      {!!expirationDate && (
        <p className="c-bonus-counter__expiration">
          <Translate id="bonusSystem.counter.expirationPrefix" />{" "}
          {toLocalizedDateString(expirationDate, getLocale())}
        </p>
      )}
    </div>
  );
};

export default BonusCounter;

const CounterValueLoader = () => {
  return (
    <ContentLoader
      width={170}
      height={70}
      {...loaderTheme}
      style={{ display: "block" }}
    >
      <rect x="0" y="10" rx="5" ry="5" width="170" height="50" />
    </ContentLoader>
  );
};
