import { useMemo } from "react";

import { calculateDistance } from "../../utils/Helpers";
import { useUserLocation } from "./use-user-location";
import { IndexedUncertainNumbers, LocatableEntity } from "../../types";

export const useDistancesToUser = (entities: LocatableEntity[]) => {
  const { lat: userLat, lng: userLng } = useUserLocation();

  const distances = useMemo(() => {
    if (!userLat || !userLng || !entities?.length) {
      return null;
    }

    return entities.reduce((result: IndexedUncertainNumbers, entity) => {
      const { id, coords } = entity;

      if (isNaN(coords?.lat) || isNaN(coords?.lng)) {
        result[id] = null;
        return result;
      }

      const distance = calculateDistance(coords, {
        lat: userLat,
        lng: userLng,
      });

      result[id] = !isNaN(distance) ? distance : null;

      return result;
    }, {});
  }, [entities, userLat, userLng]);

  return distances;
};
