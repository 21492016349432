/**
 * The React Router Redirect component throws errors to the console because of our
 * custom TransitionGroup animation solution. This is a simple redirection
 * component that uses the history utility to replace it.
 */

import React from "react";
import PropTypes from "prop-types";
import history from "../utils/navigation";

class RouteRedirect extends React.Component {
  UNSAFE_componentWillMount() {
    history.push({
      pathname: this.props.to,
      state: {
        transition: this.props.transition,
        timeout: this.props.timeout ? this.props.timeout : 1000,
      },
    });
  }

  render() {
    return null;
  }
}

RouteRedirect.propTypes = {
  to: PropTypes.string,
  transition: PropTypes.string,
  timeout: PropTypes.number,
};

export default RouteRedirect;
