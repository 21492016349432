export type IdArray = number[];

export interface IdObject {
  id: number;
}

export interface OrderableData extends IdObject {
  order: number;
  title?: string;
  name?: string;
}

export interface IndexedIdArray {
  [key: string]: IdArray;
}

export interface IndexedDataArray {
  [key: string]: OrderableData[];
}

export const sortByOrder = (a: OrderableData, b: OrderableData) => {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;

  if (a?.title && b?.title) {
    return a.title.localeCompare(b.title);
  }

  if (a?.name && b?.name) {
    return a.name.localeCompare(b.name);
  }

  return 0;
};

export const idArrayToDataArray = <T extends IdArray, K extends IdObject>(
  ids: T,
  data: K[]
): K[] => {
  return data ? data.filter(({ id }) => ids.includes(id)) : [];
};

export const indexedIdArrayToIndexedDataArray = <
  T extends IndexedIdArray,
  K extends Record<keyof T, IdObject[]>
>(
  indexedIds: T,
  indexedData: K
): K => {
  if (!indexedIds) {
    return {} as K;
  }

  const indexes: (keyof T)[] = Object.keys(indexedIds);

  return indexes.reduce((result, index) => {
    const data = indexedData[index].filter(({ id }) =>
      indexedIds[index].includes(id)
    );

    result[index] = data as K[keyof T];
    return result;
  }, {} as K);
};

export const sortOrderableData = <T extends OrderableData>(data: T[]): T[] => {
  return [...data].sort(sortByOrder);
};
