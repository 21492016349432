import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-localize-redux";
import { AnimatePresence } from "framer-motion";

import {
  selectOrderBarbershop,
  selectOrderServices,
  selectOrderExtraServices,
  editOrder,
  sendOrder,
  selectOrderSending,
} from "./orderSlice";
import { analytics } from "../../utils/Analytics";
import { isEmptyObject } from "../../utils/Helpers";
import { useServiceExtraServices } from "./use-service-extra-services";
import { ReactComponent as IconDownIndicator } from "../../assets/images/icon-down-indicator.svg";
import FadeIn from "../animations/FadeIn";
import useOnScreen from "../../utils/use-on-screen";
import StaticGoogleMapsImage from "../../components/StaticGoogleMapsImage";
import LocalizedContent from "../../components/LocalizedContent";
// import OrderReviewNotice from "./OrderReviewNotice";
import ServiceDisplay from "./ServiceDisplay";
import ExtraServiceDisplay from "./ExtraServiceDisplay";
import ConfirmButton from "../../components/ConfirmButton";
import Timer from "../../components/Timer";
import ExtraServiceToggle from "./ExtraServiceToggle";

interface OrderReviewProps {
  onImageClick?: () => void;
  onComplete?: () => void;
}

const OrderReview: React.FC<OrderReviewProps> = ({
  onImageClick,
  onComplete,
}) => {
  const dispatch = useDispatch();

  const barbershop = useSelector(selectOrderBarbershop);
  const { coords, name, address, queue, extraServices } = barbershop || {};

  const sending = useSelector(selectOrderSending);
  const orderServices = useSelector(selectOrderServices);
  const orderExtraServices = useSelector(selectOrderExtraServices);

  const recommendedExtraServices = useServiceExtraServices(
    orderServices[0]?.id,
    extraServices
  );

  const hasServices = !!(orderServices && orderServices.length);
  const hasExtraServices = !isEmptyObject(orderExtraServices);
  const hasRecommendedExtraServices = !!(
    recommendedExtraServices && recommendedExtraServices.length
  );

  /**
   * We want to allow recommendations only based on
   * the conditions when the review was mounted,
   * so we'll store it into ref.
   */
  const allowRecommendations = useRef(
    !hasExtraServices && hasRecommendedExtraServices
  );

  const footerRef = useRef<HTMLDivElement>(null);
  const isFooterOnScreen = useOnScreen(footerRef);

  useEffect(() => {
    if (sending === "fulfilled") {
      analytics.queueConfirmation(
        barbershop,
        orderServices,
        orderExtraServices
      );

      if (onComplete) {
        onComplete();
      }
    }
  }, [
    dispatch,
    sending,
    onComplete,
    orderServices,
    orderExtraServices,
    barbershop,
  ]);

  return (
    <div className="c-order-review">
      <div className="c-order-review__header">
        <StaticGoogleMapsImage
          className="c-order-review__image"
          coords={coords}
          onClick={onImageClick}
        >
          <div className="c-order-review__timer">
            <Timer minutes={queue} size="small" />
          </div>
        </StaticGoogleMapsImage>

        <div className="c-order-review__back">
          <button
            className="c-back-button"
            onClick={() => dispatch(editOrder())}
          >
            <Translate id="navigation.back" />
          </button>
        </div>
      </div>

      <div className="c-order-review__content">
        <div className="c-order-review__info">
          <h2 className="c-order-review__subtitle">
            <Translate id="orderReview.subtitle" />
          </h2>

          <LocalizedContent locales={["FI"]}>
            <p className="c-order-review__button-notice">
              <Translate id="barbershop.cashNotice" />
            </p>
          </LocalizedContent>

          <h1 className="c-order-review__title">{name}</h1>
          <p className="c-order-review__address">{address}</p>

          {/* This has been disabled for now, let's show it as a standalone modal before review instead. */}
          {/* <OrderReviewNotice className="c-order-review__notice" queue={queue} /> */}
        </div>

        {hasServices && (
          <div className="c-order-review__services">
            {orderServices.map((service) => (
              <ServiceDisplay
                {...service}
                key={service.id}
                data={orderExtraServices}
                render={(serviceExtraServices) =>
                  serviceExtraServices.map((extraService) => (
                    <ExtraServiceDisplay
                      key={extraService.id}
                      {...extraService}
                    />
                  ))
                }
              />
            ))}
          </div>
        )}

        {allowRecommendations.current && (
          <div className="c-order-review__recommendations">
            <h2 className="c-order-review__subtitle c-order-review__subtitle--recommendations">
              <Translate id="tracking.recommendationsTitle" />
            </h2>

            {recommendedExtraServices.map((extraService) => (
              <ExtraServiceToggle key={extraService.id} {...extraService} />
            ))}
          </div>
        )}
      </div>

      <div className="c-order-review__footer" ref={footerRef}>
        <ConfirmButton
          text={<Translate id="orderReview.cta" />}
          loading={sending}
          onClick={() => dispatch(sendOrder())}
        />
      </div>

      <AnimatePresence>
        {!isFooterOnScreen && (
          <FadeIn className="c-order-review__footer-arrow">
            <IconDownIndicator />
          </FadeIn>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OrderReview;
