import React from "react";
import { AnimatePresence } from "framer-motion";

import Bounce from "../features/animations/Bounce";

import { ReactComponent as CircleIcon } from "../assets/images/icon-circle.svg";
import { ReactComponent as CheckMarkIcon } from "../assets/images/icon-checkmark.svg";

interface CheckMarkProps {
  isSelected: boolean;
  size?: "regular" | "small";
}

const CheckMark: React.FC<CheckMarkProps> = ({
  isSelected,
  size = "regular",
}) => {
  const iconSize = size === "regular" ? 20 : 17;

  return (
    <div className={"c-checkmark"}>
      <AnimatePresence initial={false}>
        {isSelected ? (
          <Bounce duration={0.5} key={"checkmark"} exit={{}}>
            <CheckMarkIcon width={iconSize} height={iconSize} />
          </Bounce>
        ) : (
          <Bounce duration={0.5} key={"circle"} exit={{}}>
            <CircleIcon width={iconSize} height={iconSize} />
          </Bounce>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CheckMark;
