import { Benefit } from "../../types";

export const sortByIsRedeemable = (a: Benefit, b: Benefit) => {
  if (!a.isRedeemable && !b.isRedeemable) {
    return sortByIssueDate(a, b);
  }

  return a.isRedeemable ? -1 : 1;
};

export const sortByIssueDate = (a: Benefit, b: Benefit) => {
  const dtA = new Date(a.dateIssued);
  const dtB = new Date(b.dateIssued);
  const timeA = dtA.getTime();
  const timeB = dtB.getTime();

  if (timeA === timeB) {
    return 0;
  }

  return timeA < timeB ? -1 : 1;
};
