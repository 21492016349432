import React from "react";
import { motion } from "framer-motion";

import { fadeInUp } from "./utils";
import { SpringAnimationComponentProps } from "./types";

const FadeInUp: React.FC<SpringAnimationComponentProps> = ({
  children,
  bounce = 0.15,
  duration = 0.5,
  delay = 0,
  ...props
}) => {
  return (
    <motion.div
      {...fadeInUp}
      {...props}
      transition={{ type: "spring", bounce, duration, delay }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInUp;
