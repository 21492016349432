import React from "react";
import classNames from "classnames";

import { TranslateString } from "../types";

interface InlineNoticeProps {
  title: TranslateString;
  text: TranslateString;
  isWarning?: boolean;
  isDim?: boolean;
}

const InlineNotice: React.FC<InlineNoticeProps> = ({
  title,
  text,
  isWarning,
  isDim,
}) => {
  const componentClass = classNames("c-inline-notice", {
    "c-inline-notice--warning": isWarning,
    "is-dim": isDim,
  });

  return (
    <div className={componentClass}>
      <p>
        <strong>{title}</strong> {text}
      </p>
    </div>
  );
};

export default InlineNotice;
