import React from "react";
import { TranslateString } from "../types";

interface ConfirmDialogProps {
  title: TranslateString;
  description: TranslateString;
  cancelText: TranslateString;
  confirmText: TranslateString;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  description,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
}) => {
  return (
    <div className="c-confirm-dialog">
      <h2 className="c-confirm-dialog__title">{title}</h2>
      <p className="c-confirm-dialog__description">{description}</p>
      <div className="c-confirm-dialog__buttons">
        <button className="c-btn c-btn--text" onClick={onCancel}>
          {cancelText}
        </button>

        <button className="c-btn c-btn--text" onClick={onConfirm}>
          {confirmText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmDialog;
