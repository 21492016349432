import { isEqual } from "lodash";
import * as userService from "../services/user";
import * as authService from "../services/auth";
import { getIpInfo } from "../services/ip-info";
import ApiConstants from "../constants/api";
import * as helpers from "../utils/Helpers";
import { analytics } from "../utils/Analytics";

import { filterMessagesWithLocale } from "./messages";

/**
 * Actions
 */

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const PROFILE_SYNC_REQUEST = "PROFILE_SYNC_REQUEST";
export const PROFILE_SYNC_SUCCESS = "PROFILE_SYNC_SUCCESS";
export const PROFILE_SYNC_FAILURE = "PROFILE_SYNC_FAILURE";

export const PROFILE_SYNC_CLEAR = "PROFILE_SYNC_CLEAR";

export const LOCATION_REQUEST = "LOCATION_REQUEST";
export const LOCATION_SUCCESS = "LOCATION_SUCCESS";
export const LOCATION_FAILURE = "LOCATION_FAILURE";

export const PATCH_PROFILE_REQUEST = "PATCH_PROFILE_REQUEST";
export const PATCH_PROFILE_SUCCESS = "PATCH_PROFILE_SUCCESS";
export const PATCH_PROFILE_FAILURE = "PATCH_PROFILE_FAILURE";

export const DISABLE_TUTORIAL_REQUEST = "DISABLE_TUTORIAL_REQUEST";
export const DISABLE_TUTORIAL_SUCCESS = "DISABLE_TUTORIAL_SUCCESS";
export const DISABLE_TUTORIAL_FAILURE = "DISABLE_TUTORIAL_FAILURE";

export const ENABLE_PUSH_REQUEST = "ENABLE_PUSH_REQUEST";
export const ENABLE_PUSH_SUCCESS = "ENABLE_PUSH_SUCCESS";
export const ENABLE_PUSH_FAILURE = "ENABLE_PUSH_FAILURE";

export const DISABLE_PUSH_REQUEST = "DISABLE_PUSH_REQUEST";
export const DISABLE_PUSH_SUCCESS = "DISABLE_PUSH_SUCCESS";
export const DISABLE_PUSH_FAILURE = "DISABLE_PUSH_FAILURE";

export const DELETE_FCM_TOKEN_REQUEST = "DELETE_FCM_TOKEN_REQUEST";
export const DELETE_FCM_TOKEN_SUCCESS = "DELETE_FCM_TOKEN_SUCCESS";
export const DELETE_FCM_TOKEN_FAILURE = "DELETE_FCM_TOKEN_FAILURE";

export const CLEAR_FCM_TOKEN_REQUEST = "CLEAR_FCM_TOKEN_REQUEST";
export const CLEAR_FCM_TOKEN_SUCCESS = "CLEAR_FCM_TOKEN_SUCCESS";
export const CLEAR_FCM_TOKEN_FAILURE = "CLEAR_FCM_TOKEN_FAILURE";

export const RECOVERY_TOKEN_REQUEST = "RECOVERY_TOKEN_REQUEST";
export const RECOVERY_TOKEN_SUCCESS = "RECOVERY_TOKEN_SUCCESS";
export const RECOVERY_TOKEN_FAILURE = "RECOVERY_TOKEN_FAILURE";

export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";

export const RESET_RECOVERY = "RESET_RECOVERY";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_FORCED_LOGOUT = "USER_FORCED_LOGOUT";

export const CLEAR_USER = "CLEAR_USER";

export const UPDATE_FAVORITE_BARBERSHOP = "UPDATE_FAVORITE_BARBERSHOP";

export const SET_LOCALE = "SET_LOCALE";

export const SET_LOCALE_FROM_IP = "SET_LOCALE_FROM_IP";

/**
 * Action creators
 */

/**
 * Send user credentials and get access token. See userService for
 * further details.
 *
 * @param {string} phone - User inputted phone number with prefix
 * @param {string} password - User inputted password
 * @param {boolean} rememberLogin - User has indicated they want to stay signed in
 */
export function login(phone, password, rememberLogin) {
  return function (dispatch) {
    dispatch(request());

    authService.login(phone, password, rememberLogin).then(
      (user) => {
        dispatch(success(user));
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return { type: LOGIN_REQUEST };
  }
  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function failure(errors) {
    return { type: LOGIN_FAILURE, errors };
  }
}

/**
 * Log the user out of the app.
 */
export function logout() {
  authService.logout();
  return { type: USER_LOGOUT };
}

export function forcedLogout() {
  authService.logout();
  return { type: USER_FORCED_LOGOUT };
}

/**
 * Registers the user to the API system. See userService for further
 * details.
 *
 * @param {object} data - User inputted data
 */
export function registerUser(data) {
  return function (dispatch) {
    dispatch(request());

    authService.register(data).then(
      (response) => {
        dispatch(success(response));
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return { type: REGISTER_REQUEST };
  }
  function success(response) {
    return { type: REGISTER_SUCCESS, response };
  }
  function failure(errors) {
    return { type: REGISTER_FAILURE, errors };
  }
}

/**
 * Requests a password reset token from the API.
 * Also triggers a SMS-message to be sent from the backend.
 *
 * @param {object} data - User inputted data
 */
export function getRecoveryToken(data, countryCode, phone) {
  return function (dispatch) {
    dispatch(request());

    authService.getRecoveryToken(data, countryCode, phone).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: RECOVERY_TOKEN_REQUEST };
  }
  function success(response) {
    return { type: RECOVERY_TOKEN_SUCCESS, response };
  }
  function failure(error) {
    return { type: RECOVERY_TOKEN_FAILURE, error };
  }
}

/**
 * Requests a password reset from the API.
 *
 * @param {object} data - User inputted data
 */
export function recoverPassword(data) {
  return function (dispatch) {
    dispatch(request());

    authService.recoverPassword(data).then(
      (response) => {
        authService.resetRecovery();
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: RECOVER_PASSWORD_REQUEST };
  }
  function success(response) {
    return { type: RECOVER_PASSWORD_SUCCESS, response };
  }
  function failure(error) {
    return { type: RECOVER_PASSWORD_FAILURE, error };
  }
}

/**
 * Reset the recovery process
 */
export function resetRecovery() {
  authService.resetRecovery();
  return { type: RESET_RECOVERY };
}

/**
 * Syncs the localStorage user & profile with data from the API.
 * See userService for further details.
 */

export function syncUserProfile() {
  return function (dispatch, getState) {
    const { user } = getState();

    if (user.profileIsSyncing) return false;

    dispatch(request());

    userService.getProfile().then(
      (profile) => {
        dispatch(success(profile));
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return { type: PROFILE_SYNC_REQUEST };
  }
  function success(profile) {
    return { type: PROFILE_SYNC_SUCCESS, profile };
  }
  function failure(error) {
    return { type: PROFILE_SYNC_FAILURE, error };
  }
}

/**
 * Resets the profile sync status, telling the app that it
 * needs to resync before important actions.
 */
export function clearProfileSync() {
  return { type: PROFILE_SYNC_CLEAR };
}

/**
 * Asks the user for location permissions.
 */
export function locateUser() {
  return function (dispatch) {
    dispatch(request());

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(success(position));
        },
        (error) => {
          dispatch(failure());
        },
        {
          timeout: 10000,
        }
      );
    } else {
      dispatch(failure());
    }
  };

  function request() {
    return { type: LOCATION_REQUEST };
  }
  function success(position) {
    userService.setUserLatitude(position?.coords?.latitude);
    userService.setUserLongitude(position?.coords?.longitude);

    return { type: LOCATION_SUCCESS, position };
  }
  function failure() {
    return { type: LOCATION_FAILURE };
  }
}

/**
 * Sends information to the API that the user has been through the
 * tutorial. See userService for more details.
 */
export function disableTutorial() {
  return function (dispatch) {
    dispatch(request());

    userService.disableTutorial().then(
      () => {
        dispatch(success());
      },
      () => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: DISABLE_TUTORIAL_REQUEST };
  }
  function success() {
    return { type: DISABLE_TUTORIAL_SUCCESS };
  }
  function failure() {
    return { type: DISABLE_TUTORIAL_FAILURE };
  }
}

/**
 * Patches the user profile.
 *
 * @param {object} data - Data to be patched
 */
export function patchProfile(data) {
  return function (dispatch) {
    dispatch(request());

    userService.patchProfile(data).then(
      (profile) => {
        dispatch(success(profile));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: PATCH_PROFILE_REQUEST };
  }
  function success(profile) {
    return { type: PATCH_PROFILE_SUCCESS, profile };
  }
  function failure(error) {
    return { type: PATCH_PROFILE_FAILURE, error };
  }
}

/**
 * Enables push notifications through Firebase. See userService for more
 * details.
 * TO-DO: Currently quite complex, let's see if we could simplify this.
 */
export function enablePushNotifications() {
  return function (dispatch) {
    dispatch(request());

    if ("firebase" in window) {
      const messaging = window.firebase.messaging();

      messaging
        .requestPermission() // displays "allow push" -dialog to user
        .then(() => {
          messaging
            .getToken() // fetches the token when user accepted
            .then((fcmToken) => {
              userService
                .patchProfile({
                  // sends the token to API
                  // push_enabled: true,
                  fcm_token: fcmToken,
                })
                .then(
                  () => {
                    userService.enablePushNotifications();
                    dispatch(success());
                  },
                  () => {
                    dispatch(failure());
                  }
                );
            })
            .catch(function (err) {
              dispatch(failure());
            });
        })
        .catch((err) => {
          dispatch(failure());
        });
    } else {
      dispatch(failure());
    }
  };

  function request() {
    return { type: ENABLE_PUSH_REQUEST };
  }
  function success() {
    return { type: ENABLE_PUSH_SUCCESS };
  }
  function failure() {
    return { type: ENABLE_PUSH_FAILURE };
  }
}

/**
 * Turns off push notifications from the API for the user. See userService for
 * further details.
 */
export function disablePushNotifications(fcmToken) {
  return function (dispatch) {
    dispatch(request());

    userService
      .patchProfile({
        fcm_token: null,
      })
      .then(() => {
        userService.disablePushNotifications();

        if ("firebase" in window && fcmToken) {
          const messaging = window.firebase.messaging();
          messaging.deleteToken(fcmToken);
        }

        dispatch(success());
      })
      .catch((err) => {
        dispatch(failure());
      });
  };

  function request() {
    return { type: DISABLE_PUSH_REQUEST };
  }

  function success() {
    return { type: DISABLE_PUSH_SUCCESS };
  }

  function failure() {
    return { type: DISABLE_PUSH_FAILURE };
  }
}

/**
 * BEING DEPRECATED
 * Removes the stored Firebase FCM token from the API. Related to
 * push notifications. See userService for further details.
 */
export function clearFcmToken(token) {
  return function (dispatch) {
    dispatch(request());

    if ("firebase" in window) {
      userService.deleteFcmToken();
      const messaging = window.firebase.messaging();

      messaging
        .deleteToken(token)
        .then(() => {
          dispatch(success());
        })
        .catch((err) => {
          //console.log(err);
          dispatch(failure());
        });
    }
  };

  function request() {
    return { type: CLEAR_FCM_TOKEN_REQUEST };
  }
  function success() {
    return { type: CLEAR_FCM_TOKEN_SUCCESS };
  }
  function failure() {
    return { type: CLEAR_FCM_TOKEN_FAILURE };
  }
}

/**
 * Clears user settings from Redux. Does not affect localStorage
 * or the API.
 */
export function clearUser() {
  return { type: CLEAR_USER };
}

/**
 * Toggles a barbershop from the users favorites.
 */

export function toggleFavoriteBarbershop(barbershop) {
  return function (dispatch) {
    userService.toggleFavoriteBarbershop(barbershop).then((favorites) => {
      dispatch(success(favorites));
    });
  };

  function success(favorites) {
    return {
      type: UPDATE_FAVORITE_BARBERSHOP,
      favoriteBarbershops: favorites,
    };
  }
}

/**
 * Sets the API locale
 */

export function setLocale(locale) {
  return function (dispatch) {
    userService.setLocale(locale);
    dispatch(filterMessagesWithLocale(locale));
    dispatch({ type: SET_LOCALE, locale: locale });
  };
}

/**
 * Sets the API locale based on user IP
 */

export function setLocaleFromIP(countryData) {
  return function (dispatch) {
    getIpInfo().then(
      (IPInfo) => {
        const selectedCountry = countryData.find((country) => {
          return country.code === IPInfo.country;
        });

        if (selectedCountry) {
          dispatch(success(selectedCountry.code));
        } else {
          dispatch(failure());
        }
      },
      (errors) => {
        dispatch(failure());
      }
    );
  };

  function success(locale) {
    userService.setLocale(locale);
    return { type: SET_LOCALE, locale: locale };
  }

  function failure() {
    userService.setLocale(ApiConstants.DEFAULT_LOCALE);
    return { type: SET_LOCALE, locale: ApiConstants.DEFAULT_LOCALE };
  }
}

/**
 * Handles the analytics event related to user profile. Be mindful of
 * performance as this function is called on every user state update.
 */

function handleUserDataAnalytics(state, action) {
  let language = "";

  // user profile is being sent through the action
  if (typeof action.profile !== "undefined") {
    const profileChanged = !isEqual(state.profile, action.profile);

    if (!profileChanged) return false;

    const coords = {
      latitude: state.latitude,
      longitude: state.longitude,
    };

    language = localStorage.getItem("language");

    // send updated profile data to analytics
    analytics.userData(action.profile, coords, language);
  }
  // user location data will change
  else if (action.type === LOCATION_SUCCESS) {
    const latitude =
      action.position && action.position.coords
        ? action.position.coords.latitude
        : undefined;
    const longitude =
      action.position && action.position.coords
        ? action.position.coords.longitude
        : undefined;

    if (state.latitude === latitude) return false;

    language = localStorage.getItem("language");

    // send updated coords data to analytics
    analytics.userData(
      state.profile,
      {
        latitude: latitude,
        longitude: longitude,
      },
      language
    );
  }
}

/**
 * Initial state of the store
 */
const user = JSON.parse(localStorage.getItem("user"));
const profile = JSON.parse(localStorage.getItem("profile")) || {};
const pushEnabled = localStorage.getItem("pushEnabled");
const favoriteBarbershops =
  JSON.parse(localStorage.getItem("favoriteBarbershops")) || [];
const locale = localStorage.getItem("locale");

// check if the recovery process is still on-going
const recovery = JSON.parse(localStorage.getItem("recovery"));
let isRecoveryTokenSent = false;

if (recovery) {
  isRecoveryTokenSent = helpers.isRecoveryTokenValid(recovery);
}

const initialLatitude = userService.getUserLatitude() || 0;
const initialLongitude = userService.getUserLongitude() || 0;

export const initialState = {
  // states
  loggedIn: user && user.id && user.token ? true : false,
  locale: locale,
  loading: false,
  loadingGeolocation: "idle",
  authenticating: false,
  profileIsSyncing: false,
  profileSynced: false,
  locationFailure: false,
  registrationPending: false,
  registrationSuccess: false,
  pushChangePending: false,
  recoveryTokenSent: isRecoveryTokenSent,
  passwordRecovered: false,
  profilePatched: false,

  // errors
  errors: {
    phone: false,
    password: false,
    token: false,
    registration: false,
    recovery: {
      phone: false,
      password: false,
      token: false,
    },
    patch: false,
  },
  registrationErrors: false, // maybe use the the common errors -object instead?

  // user information
  profile: profile,
  pushEnabled: pushEnabled === "true",
  latitude: initialLatitude,
  longitude: initialLongitude,
  recoveryToken: false,
  favoriteBarbershops: favoriteBarbershops,

  // DEPRECATED ?
  membership: { type: null },
};

/**
 * Reducer
 */
export default function reducer(state = initialState, action) {
  handleUserDataAnalytics(state, action);

  switch (action.type) {
    case LOGIN_REQUEST: {
      const y = Object.assign({}, state);

      y.errors.phone = false;
      y.errors.password = false;
      y.errors.token = false;
      y.authenticating = true;

      return y;
    }

    case LOGIN_SUCCESS: {
      const y = Object.assign({}, state);

      y.authenticating = false;
      y.loggedIn = true;

      return y;
    }

    case LOGIN_FAILURE: {
      const y = Object.assign({}, state);

      y.authenticating = false;
      y.errors.phone = action.errors.phone;
      y.errors.password = action.errors.password;
      y.errors.profile = action.errors.profile;

      return y;
    }

    case REGISTER_REQUEST: {
      const y = Object.assign({}, state);

      y.registrationPending = true;
      y.registrationSuccess = false;
      y.registrationErrors = false;

      return y;
    }

    case REGISTER_SUCCESS: {
      const y = Object.assign({}, state);

      y.registrationPending = false;
      y.registrationSuccess = true;

      return y;
    }

    case REGISTER_FAILURE: {
      const y = Object.assign({}, state);

      y.registrationPending = false;
      y.registrationSuccess = false;
      y.registrationErrors = action.errors;

      return y;
    }

    case RECOVERY_TOKEN_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.recoveryTokenSent = false;

      return y;
    }

    case RECOVERY_TOKEN_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.recoveryTokenSent = true;
      y.errors.recovery.phone = false;

      return y;
    }

    case RECOVERY_TOKEN_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.recoveryTokenSent = false;
      y.errors.recovery.phone = true;

      return y;
    }

    case RECOVER_PASSWORD_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.passwordRecovered = false;

      return y;
    }

    case RECOVER_PASSWORD_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.passwordRecovered = true;

      // reset errors
      Object.keys(y.errors.recovery).forEach(
        (v) => (y.errors.recovery[v] = false)
      );

      return y;
    }

    case RECOVER_PASSWORD_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.passwordRecovered = false;

      // reset errors
      Object.keys(y.errors.recovery).forEach(
        (v) => (y.errors.recovery[v] = false)
      );

      // check the error message
      if (action.error && action.error.data) {
        const message = action.error.data.error;

        // phone number is missing. reset back to phone input.
        if (message === ApiConstants.MROOM_API_ERROR_RECOVERY_MISSING_PHONE) {
          y.errors.recovery.phone = true;
          y.recoveryTokenSent = false;
        }
        // set a general error
        else {
          y.errors.recovery.password = true;
          y.errors.recovery.token = true;
        }
      }
      // set a general error if no error message was provided
      else {
        y.errors.recovery.password = true;
        y.errors.recovery.token = true;
      }

      return y;
    }

    case RESET_RECOVERY: {
      const y = Object.assign({}, state);

      y.recoveryTokenSent = false;
      y.passwordRecovered = false;

      // reset errors
      Object.keys(y.errors.recovery).forEach(
        (v) => (y.errors.recovery[v] = false)
      );

      return y;
    }

    case PROFILE_SYNC_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.profileSynced = false;
      y.profileIsSyncing = true;
      y.errors.token = false;

      return y;
    }

    case PROFILE_SYNC_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.loggedIn = true;
      y.profileSynced = true;
      y.profileIsSyncing = false;

      y.profile = action.profile;

      return y;
    }

    case PROFILE_SYNC_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.profileSynced = false;
      y.profileIsSyncing = false;
      y.loggedIn = false;

      y.profile = null;
      y.errors.token = action.error.token || y.errors.token;

      return y;
    }

    case PROFILE_SYNC_CLEAR: {
      const y = Object.assign({}, state);

      y.profileSynced = false;

      return y;
    }

    case PATCH_PROFILE_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.profilePatched = false;
      y.errors.patch = false;

      return y;
    }

    case PATCH_PROFILE_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.profilePatched = true;
      y.profile = action.profile;
      y.errors.patch = false;

      return y;
    }

    case PATCH_PROFILE_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.profilePatched = false;
      y.errors.patch = true;

      return y;
    }

    case LOCATION_REQUEST: {
      const y = Object.assign({}, state);

      y.loadingGeolocation = "pending";

      return y;
    }

    case LOCATION_SUCCESS: {
      const y = Object.assign({}, state);

      y.latitude = action.position.coords.latitude;
      y.longitude = action.position.coords.longitude;
      y.loadingGeolocation = "fulfilled";

      return y;
    }

    case LOCATION_FAILURE: {
      const y = Object.assign({}, state);

      y.loadingGeolocation = "rejected";
      y.locationFailure = true;

      return y;
    }

    case ENABLE_PUSH_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.pushChangePending = true;

      return y;
    }

    case ENABLE_PUSH_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.pushChangePending = false;
      y.pushEnabled = false;

      return y;
    }

    case ENABLE_PUSH_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.pushChangePending = false;
      y.pushEnabled = true;

      return y;
    }

    case DISABLE_PUSH_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.pushChangePending = true;

      return y;
    }

    case DISABLE_PUSH_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.pushChangePending = false;

      return y;
    }

    case DISABLE_PUSH_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.pushChangePending = false;
      y.pushEnabled = false;

      return y;
    }

    case USER_LOGOUT: {
      const y = Object.assign({}, state);

      y.loggedIn = false;
      y.profile = {};
      y.profileSynced = false;

      return y;
    }

    case USER_FORCED_LOGOUT: {
      const y = Object.assign({}, state);
      let x = Object.assign({}, y.errors);

      y.loggedIn = false;
      y.profile = {};
      y.profileSynced = false;

      x = {
        token: true,
      };

      y.errors = x;

      return y;
    }

    case CLEAR_USER: {
      let y = Object.assign({}, state);

      // bind to variable for mutation
      let x = Object.assign({}, y.errors);

      x = {
        phone: false,
        password: false,
      };

      // bring back to main object
      y.errors = x;

      y.loading = false;
      y.authenticating = false;
      y.latitude = 0;
      y.longitude = 0;
      y.profileSynced = false;
      y.profile = null;
      y.locationFailure = false;
      y.loggedIn = false;

      return y;
    }

    case UPDATE_FAVORITE_BARBERSHOP: {
      let y = Object.assign({}, state);

      y.favoriteBarbershops = action.favoriteBarbershops;

      return y;
    }

    case SET_LOCALE: {
      const y = Object.assign({}, state);

      y.locale = action.locale;

      return y;
    }

    default:
      return state;
  }
}

export const selectUserLatitude = (state) => state.user.latitude;
export const selectUserLongitude = (state) => state.user.longitude;

export const selectIsUserLocated = (state) =>
  !!(state.user.latitude && state.user.longitude);

export const selectLoadingGeolocation = (state) =>
  state.user.loadingGeolocation;

export const selectFavoriteBarbershops = (state) =>
  state.user.favoriteBarbershops;

export const selectUserProfile = (state) => state.user.profile;

export const selectUserLocale = (state) => state.user.locale;
