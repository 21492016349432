import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  locateUser,
  selectLoadingGeolocation,
  selectUserLatitude,
  selectUserLongitude,
} from "../../ducks/user";

import { showNotification } from "../../ducks/notification";
import { TranslateString } from "../../types";

interface ErrorMessage {
  title: TranslateString;
  text: TranslateString;
  timeout?: number;
}

export const useUserLocation = (error?: ErrorMessage) => {
  const dispatch = useDispatch();
  const userLatitude: number = useSelector(selectUserLatitude);
  const userLongitude: number = useSelector(selectUserLongitude);
  const loading = useSelector(selectLoadingGeolocation);
  const { title, text, timeout } = error || {};

  const locate = useCallback(() => {
    dispatch(locateUser());
  }, [dispatch]);

  useEffect(() => {
    if (loading === "rejected" && title && text) {
      dispatch(showNotification(title, text, timeout || 5000));
    }
  }, [dispatch, loading, title, text, timeout]);

  return {
    lat: userLatitude,
    lng: userLongitude,
    isLocated: !!(userLatitude && userLongitude),
    locate,
    loading,
  };
};
