import React from "react";
import classNames from "classnames";
import { withLocalize, Translate } from "react-localize-redux";
import * as helpers from "../utils/Helpers";
import homeScreenImage from "../assets/images/welcome-home-screen.png";

class HelpInstall extends React.Component {
  render() {
    const iOSContent = (
      <div className="c-help-screen__content">
        <h2>
          <Translate id="helpInstall.iosTitle" />
        </h2>
        <p>
          <Translate
            id="helpInstall.ios1stStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
        <p>
          <Translate
            id="helpInstall.ios2ndStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
      </div>
    );

    const androidContent = (
      <div className="c-help-screen__content">
        <h2>
          <Translate id="helpInstall.androidTitle" />
        </h2>
        <p>
          <Translate
            id="helpInstall.android1stStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
        <p>
          <Translate
            id="helpInstall.android2ndStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
      </div>
    );

    const genericContent = (
      <div className="c-help-screen__content">
        <h2>
          <Translate id="helpInstall.iosTitle" />
        </h2>
        <h3>iOS</h3>
        <p>
          <Translate
            id="helpInstall.ios1stStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
        <p>
          <Translate
            id="helpInstall.ios2ndStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
        <h3>Android</h3>
        <p>
          <Translate
            id="helpInstall.android1stStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
        <p>
          <Translate
            id="helpInstall.android2ndStep"
            options={{ renderInnerHtml: true }}
          />
        </p>
      </div>
    );

    const os = helpers.getMobileOperatingSystem();
    const content =
      os === "iOS"
        ? iOSContent
        : os === "Android"
        ? androidContent
        : genericContent;

    const image =
      os === "iOS" || os === "Android" ? (
        <div className="c-help-screen__image">
          <img
            src={homeScreenImage}
            alt="My M Room asennettuna kotivalikkoon"
          />
        </div>
      ) : null;

    const componentClass = classNames({
      "c-help-screen": true,
      "c-help-screen--with-image": image !== null,
    });

    return (
      <div className={componentClass}>
        {content}
        {image}
      </div>
    );
  }
}

export default withLocalize(HelpInstall);
