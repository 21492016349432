/**
 * External dependencies
 */
import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import { AnimatePresence } from "framer-motion";

/**
 * Internal dependencies
 */
import {
  fetchOrder,
  fetchBarbershop,
  cancelOrder,
  clearTracking,
  selectTrackingOrder,
  selectTrackingStatus,
  selectTrackingLoadingOrder,
  selectTrackingBarbershop,
  checkIn,
  selectTrackingCheckingIn,
  selectTrackingCheckedIn,
  selectTrackingCanceling,
} from "../features/tracking/trackingSlice";
import { isFalse } from "../utils/Helpers";
import { navigateTo } from "../utils/navigation";
import { PageProps, TrackingStatus } from "../types";
import { useActiveOrderId } from "../features/user/use-active-order-id";
import { useFocusInterval } from "../utils/use-focus-interval";
import { analytics } from "../utils/Analytics";
import { setPageTitle } from "../utils/seo";
import { isFinnish } from "../utils/translations";
import { showNotification } from "../ducks/notification";
import { useDimensions } from "../utils/use-dimensions";
import ApiConstants from "../constants/api";
import BarbershopMapButton from "../features/maps/BarbershopMapButton";
import BarbershopMap from "../features/maps/BarbershopMap";
import CancelOrderButton from "../features/tracking/CancelOrderButton";
import ConfirmButton from "../components/ConfirmButton";
import ConfirmDialog from "../components/ConfirmDialog";
import Modal from "../components/Modal";
import OrderReceipt from "../features/tracking/OrderReceipt";
import ReceiptToggle from "../features/tracking/ReceiptToggle";
import Timer from "../components/Timer";
import TrackingMessage from "../features/tracking/TrackingMessage";
import TrackingTimer from "../features/tracking/TrackingTimer";
import ZoomIn from "../features/animations/ZoomIn";
import MRoomShopLink from "../features/tracking/MRoomShopLink";
import ZoomOut from "../features/animations/ZoomOut";

/**
 * Constants
 */
const EXIT_ROUTE = "/";
const { ORDER_REFRESH_TIMEOUT } = ApiConstants;
const CHECKIN_UI_DELAY = 500;
const SHORT_SCREEN_HEIGHT_BREAKPOINT = 660;

interface TrackingProps extends PageProps {}

const Tracking: React.FC<TrackingProps> = ({ translate, activeLanguage }) => {
  /**
   * Utils, refs
   */
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>(null);
  const { windowHeight } = useDimensions();
  const isShortScreen = windowHeight < SHORT_SCREEN_HEIGHT_BREAKPOINT;

  /**
   * Display state
   */
  const [showReceipt, setShowReceipt] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [showBarbershopMap, setShowBarbershopMap] = useState(false);

  /**
   * Order id
   */
  const {
    orderId,
    loading: orderIdLoading,
    refresh: refreshOrderId,
  } = useActiveOrderId();

  /**
   * Fetch order data
   */
  const hasOrderId = orderId !== null;
  const order = useSelector(selectTrackingOrder);
  const loadingOrder = useSelector(selectTrackingLoadingOrder);
  const { barbershopId, queue } = order || {};
  const hasQueueValue = typeof queue === "number";

  const maybeFetchOrder = useCallback(() => {
    if (orderId !== null) {
      dispatch(fetchOrder(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    maybeFetchOrder();
  }, [maybeFetchOrder]);

  const { refresh } = useFocusInterval(maybeFetchOrder, ORDER_REFRESH_TIMEOUT);

  /**
   * Fetch barbershop data
   */
  const barbershop = useSelector(selectTrackingBarbershop);

  useEffect(() => {
    if (typeof barbershopId !== "undefined") {
      dispatch(fetchBarbershop(barbershopId));
    }
  }, [dispatch, barbershopId]);

  /**
   * Tracking status
   *
   * @note We are not relying on the tracking status to determine
   * if we should exit or not. Instead we are relying on the user
   * profile havind an active order id or not.
   */
  const status = useSelector(selectTrackingStatus);

  useEffect(() => {
    if (status !== "unknown" && status !== TrackingStatus.active) {
      refreshOrderId();
    }
  }, [status, refreshOrderId]);

  /**
   * @note When order is completed, the order is destroyed from the
   * backend, leading to a loading failure (400 Bad Request).
   *
   * This is quite unexpected and should be fixed so that it returns
   * a "completed" status instead.
   */
  useEffect(() => {
    if (loadingOrder === "rejected") {
      refreshOrderId();
    }
  }, [loadingOrder, refreshOrderId]);

  /**
   * Exit tracking if no active order id
   */
  useEffect(() => {
    if (orderId === null && orderIdLoading === "fulfilled") {
      setTimeout(() => navigateTo(EXIT_ROUTE, "slide-left"), 500);

      return () => {
        dispatch(clearTracking());
      };
    }
  }, [dispatch, orderId, orderIdLoading]);

  /**
   * Check-in
   */
  const checkingIn = useSelector(selectTrackingCheckingIn);
  const checkedIn = useSelector(selectTrackingCheckedIn);
  const [delayedCheckedIn, setDelayedCheckedIn] = useState(checkedIn);

  const maybeCheckIn = useCallback(() => {
    if (orderId !== null) {
      analytics.queueCheckin(barbershop || {}, queue || 0);
      dispatch(checkIn(orderId));
    }
  }, [dispatch, orderId, barbershop, queue]);

  /**
   * Delay UI change if checking in is happening through
   * a user action. Skip waiting if receiving info about checkin
   * simply through the API.
   */
  useEffect(() => {
    if (checkedIn) {
      setTimeout(
        () => {
          setDelayedCheckedIn(true);
        },
        checkingIn === "idle" ? 0 : CHECKIN_UI_DELAY
      );
    }
  }, [checkingIn, checkedIn]);

  /**
   * SEO & Analytics
   */
  useEffect(() => {
    if (activeLanguage) {
      setPageTitle([translate("title.base"), translate("title.tracking")]);
    }
  }, [activeLanguage, translate]);

  useEffect(() => {
    analytics.pageView("/tracking", "Queue");
  }, []);

  useEffect(() => {
    if (delayedCheckedIn) {
      analytics.pageView("/tracking/check-in", "Check-in");
    }
  }, [delayedCheckedIn]);

  /**
   * Order cancellation
   */
  const canceling = useSelector(selectTrackingCanceling);

  useEffect(() => {
    if (canceling === "fulfilled") {
      analytics.queueQuit(barbershop || {}, order || {});
    }
  }, [canceling, order, barbershop]);

  useEffect(() => {
    if (canceling === "fulfilled" && isFinnish(activeLanguage)) {
      dispatch(
        showNotification(
          "Harmi, että poistuit jonosta.",
          "Kertoisitko meille vielä miksi?",
          10000,
          "https://vbzts9bs511.typeform.com/to/tH8rtguP",
          "Täytä nopea kysely"
        )
      );
    }
  }, [dispatch, canceling, activeLanguage]);

  /**
   * There are some CSS issues with short screens and disabling
   * scrolling, so as a quick hack we scroll to top for those cases.
   */
  useEffect(() => {
    if (
      (showCancelOrderModal || showReceipt || showBarbershopMap) &&
      isShortScreen &&
      componentRef.current !== null
    ) {
      componentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showCancelOrderModal, showReceipt, showBarbershopMap, isShortScreen]);

  /**
   * Classnames
   */
  const componentClass = classNames("s-tracking", {
    "h-scrollable":
      [showCancelOrderModal, showReceipt, showBarbershopMap].every(isFalse) ||
      isShortScreen,
  });

  const footerClass = classNames("s-tracking__footer", {
    "s-tracking__footer--checked-in": delayedCheckedIn,
  });

  return (
    <div className={componentClass} ref={componentRef}>
      <AnimatePresence>
        {delayedCheckedIn && (
          <div className="s-tracking__checkin-background-wrapper">
            <ZoomOut
              duration={2}
              bounce={0}
              className="s-tracking__checkin-background"
            />
          </div>
        )}
      </AnimatePresence>

      <div className="s-tracking__wrapper">
        {status !== "unknown" && (
          <ReceiptToggle
            className="s-tracking__receipt-toggle"
            onClick={() => setShowReceipt(true)}
          />
        )}

        <div className="s-tracking__panel">
          <TrackingTimer className="s-tracking__time" onClick={() => refresh()}>
            <Timer
              minutes={queue}
              size="large"
              success={status === TrackingStatus.serviced}
              failure={
                status === TrackingStatus.cancelled ||
                status === TrackingStatus.noShow
              }
              isOnPanel={false}
              shouldPulse={true}
            />
          </TrackingTimer>

          <div className="s-tracking__message">
            <TrackingMessage
              queue={queue}
              loading={loadingOrder}
              checkedIn={delayedCheckedIn}
            />
          </div>

          <div key="tracking-footer" className={footerClass}>
            <AnimatePresence initial={false} exitBeforeEnter>
              {!delayedCheckedIn ? (
                <>
                  <ZoomIn
                    key="footer-location"
                    className="s-tracking__location"
                  >
                    <BarbershopMapButton
                      barbershop={barbershop}
                      onClick={() => setShowBarbershopMap(true)}
                    />
                  </ZoomIn>
                  <ZoomIn
                    key="footer-button"
                    className="s-tracking__check-in-button"
                    delay={0.15}
                  >
                    <ConfirmButton
                      text={
                        !hasQueueValue || (hasQueueValue && queue > 0) ? (
                          <Translate id="tracking.checkinEarly" />
                        ) : (
                          <Translate id="tracking.checkin" />
                        )
                      }
                      loading={checkingIn}
                      onClick={maybeCheckIn}
                    />
                  </ZoomIn>
                </>
              ) : (
                <ZoomIn
                  key="footer-checkin-message"
                  className="s-tracking__checkin-message"
                >
                  <MRoomShopLink />
                </ZoomIn>
              )}
            </AnimatePresence>
          </div>
        </div>

        <div className="s-tracking__cancel-order">
          <AnimatePresence>
            {status === TrackingStatus.active && (
              <CancelOrderButton
                onClick={() => setShowCancelOrderModal(true)}
              />
            )}
          </AnimatePresence>
        </div>
      </div>

      <AnimatePresence>
        {hasOrderId && showCancelOrderModal && (
          <Modal valign="middle">
            <ConfirmDialog
              title={<Translate id="tracking.leaveTitle" />}
              description={<Translate id="tracking.leaveDescription" />}
              cancelText={<Translate id="tracking.leaveCancel" />}
              confirmText={<Translate id="tracking.leaveConfirm" />}
              onCancel={() => setShowCancelOrderModal(false)}
              onConfirm={() => dispatch(cancelOrder(orderId))}
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showBarbershopMap && (
          <BarbershopMap
            className="s-tracking__map"
            barbershop={barbershop}
            onClick={() => setShowBarbershopMap(false)}
            analyticsPathName={"/tracking/barbershop-map"}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showReceipt && (
          <OrderReceipt
            className="s-tracking__receipt"
            title={<Translate id="tracking.recipeTitle" />}
            onClose={() => setShowReceipt(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default withLocalize(Tracking);
