import React from "react";
import classNames from "classnames";
import { withLocalize, Translate } from "react-localize-redux";
import mRoomIcon01 from "../assets/images/welcome-what-is-mroom-icon-01-white.png";
import mRoomIcon02 from "../assets/images/welcome-what-is-mroom-icon-02-white.png";
import mRoomIcon03 from "../assets/images/welcome-what-is-mroom-icon-03-white.png";

class HelpMRoom extends React.Component {
  render() {
    const componentClass = classNames({
      "c-help-screen": true,
    });

    return (
      <div className={componentClass}>
        <div className="c-help-screen__content c-help-screen__content--less-padding">
          <h2>
            <Translate id="helpMRoom.title" />
          </h2>
          <p>
            <Translate
              id="helpMRoom.description"
              options={{ renderInnerHtml: true }}
            />
          </p>
        </div>
        <div className="c-help-screen__icons">
          <div className="c-help-screen__icon">
            <img src={mRoomIcon01} alt="Ilman ajanvarausta" />
            <h3>
              <Translate id="helpMRoom.1stBullet" />
            </h3>
          </div>

          <div className="c-help-screen__icon">
            <img src={mRoomIcon02} alt="Jäsenenä saat enemmän" />
            <h3>
              <Translate id="helpMRoom.2ndBullet" />
            </h3>
          </div>

          <div className="c-help-screen__icon" style={{ marginLeft: "3px" }}>
            <img src={mRoomIcon03} alt="M CUT - oma hiustenleikkaustekniikka" />
            <h3>
              <Translate id="helpMRoom.3rdBullet" />
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(HelpMRoom);
