import apiConfig from "../config/api.json";
import {
  isDev,
  shouldRemoveCustomApiTrailingSlash,
  removeTrailingSlash,
} from "./Helpers";

/**
 * Gets the api access token.
 */
export const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  return user?.token;
};

/**
 * Gets the api refresh token.
 */
export const getRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  return user?.refreshToken;
};

/**
 * Gets the development api url.
 *
 * This needs to be in sync with what's setup in `setupProxy.js`
 * in the project root.
 */
export const getLocalhostApiUrl = () => {
  if (process.env.REACT_APP_CUSTOM_API_URL) {
    return "/api/custom";
  }

  const locale = localStorage.getItem("locale");
  return `/api/${locale}`;
};

/**
 * Gets the api url.
 */
export const getApiUrl = () => {
  if (isDev()) {
    return getLocalhostApiUrl();
  }

  if (process.env.REACT_APP_CUSTOM_API_URL) {
    return process.env.REACT_APP_CUSTOM_API_URL;
  }

  const { urls, defaultLocale } = apiConfig || {};
  const locale = localStorage.getItem("locale") || defaultLocale;

  return urls[locale];
};

/**
 * Check if access token is expired from the response status.
 *
 * For whatever reason the API returns 422 code when
 * authentication fails, so we need to include that as well.
 *
 * @note The above only applies to the legacy api's. The new
 * 2022 api returns a proper 401 on auth fail. When all have been
 * migrated, it is safe to remove 422 from this.
 */
export const isAccessTokenExpired = (status) => {
  return status === 401 || status === 422;
};

/**
 * Gets the user id from localStorage.
 */
export const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { id } = user || {};
  return id || false;
};

/**
 * Checks if we should remove the trailing slash from the end of urls.
 */
export const shouldRemoveTrailingSlash = () => {
  if (process.env.REACT_APP_CUSTOM_API_URL) {
    return shouldRemoveCustomApiTrailingSlash();
  }

  const { defaultLocale, removeTrailingSlash } = apiConfig || {};
  const locale = localStorage.getItem("locale") || defaultLocale;

  return removeTrailingSlash.includes(locale);
};

/**
 * Maybe removes trailing slash from axios requests.
 */
export const maybeRemoveTrailingSlash = (config) => {
  if (shouldRemoveTrailingSlash()) {
    config.url = removeTrailingSlash(config.url);
  }

  return config;
};
