import React from "react";
import classNames from "classnames";

import { TranslateString } from "../types";

export interface ButtonProps {
  className?: string;
  text?: string | TranslateString | false;
  icon?: React.ReactElement | false;
  size?: string;
  variant?: string;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  iconAfter?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  text,
  icon,
  size,
  variant,
  disabled = false,
  isActive,
  iconAfter = false,
  onClick,
  children,
}) => {
  const hasIcon = !!icon;
  const hasText = !!text;

  const componentClass = classNames("c-btn", className, {
    [`c-btn--${size}`]: !!size,
    [`c-btn--${variant}`]: !!variant,
    "is-active": isActive,
    "is-disabled": disabled,
    "has-icon": hasIcon,
    "has-icon-after": iconAfter,
  });

  const content = (
    <>
      {hasIcon && <div className="c-btn__icon">{icon}</div>}
      <span className="c-btn__content">{hasText ? text : children}</span>
    </>
  );

  if (!onClick) {
    return <div className={componentClass}>{content}</div>;
  }

  return (
    <button className={componentClass} onClick={onClick} disabled={disabled}>
      {content}
    </button>
  );
};

export default Button;
