import { useDispatch, useSelector } from "react-redux";

import { useIsLoggedIn } from "./use-is-logged-in";
import { RootState } from "../../store";
import { login } from "../../ducks/user";

const selectErrors = (state: RootState) => state.user.errors;

type LoginPayload = {
  phone: string;
  password: string;
  rememberLogin: boolean;
};

export function useLogin() {
  const dispatch = useDispatch();
  const loggedIn = useIsLoggedIn();
  const errors = useSelector(selectErrors);

  const submit = (input: LoginPayload) => {
    dispatch(login(input.phone, input.password, input.rememberLogin));
  };

  return {
    loggedIn,
    errors,
    submit,
  };
}
