import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Loading, Barbershop } from "../../types";
import * as barbershopService from "../../services/barbershop";
import { RootState, AppThunkApi } from "../../store";

export const fetchBarbershops = createAsyncThunk<
  Barbershop[],
  void,
  AppThunkApi
>("barbershop/fetchBarbershops", async (_, { rejectWithValue }) => {
  const response = barbershopService.getBarbershops().then(
    (barbershops) => barbershops as Barbershop[],
    (error) => rejectWithValue(error?.data?.error)
  );

  return response;
});

export interface BarbershopState {
  loading: Loading;
  barbershops: Barbershop[];
}

const initialState: BarbershopState = {
  loading: "idle",
  barbershops: [] as Barbershop[],
};

export const barbershopSlice = createSlice({
  name: "barbershop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // barbershop/fetchBarbershops
    builder.addCase(fetchBarbershops.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(fetchBarbershops.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.barbershops = action.payload;
    });

    builder.addCase(fetchBarbershops.rejected, (state) => {
      state.loading = "rejected";
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = barbershopSlice.actions;

export default barbershopSlice.reducer;

export const selectBarbershops = (state: RootState) =>
  state.barbershop.barbershops;
