import React, { useCallback, useMemo } from "react";
import { Translate } from "react-localize-redux";
import ContentLoader from "react-content-loader";

import { Barbershop, StoreSelectedSource, UnitSystem } from "../../types";
import { ReactComponent as IconArrowRight } from "../../assets/images/icon-arrow-right.svg";
import { analytics } from "../../utils/Analytics";
import { loaderTheme } from "../../utils/loaders";
import { navigateTo } from "../../utils/navigation";
import { ReactComponent as FavoriteIcon } from "../../assets/images/icon-favorite-active.svg";
import { ReactComponent as ScissorIcon } from "../../assets/images/icon-scissor.svg";
import { useBarbershopQueueStatus } from "./use-barbershop-queue-status";
import Timer from "../../components/Timer";
import OpeningHours from "../../components/OpeningHours";
import classNames from "classnames";
import { getFormattedDistance } from "../../utils/units";

interface BarbershopCardProps {
  barbershop: Barbershop;
  distance?: number | null;
  unitSystem?: UnitSystem;
  source: StoreSelectedSource;
  onAfterClick?: () => void;
  isFavorite?: boolean;
}

const BarbershopCard: React.FC<BarbershopCardProps> = ({
  barbershop,
  distance,
  unitSystem = "metric",
  source,
  onAfterClick,
  isFavorite,
}) => {
  const { id, name, city, queue, address, openingHours, chairCount } =
    barbershop;

  /**
   * Distance
   */
  const formattedDistance = useMemo(
    () => getFormattedDistance(distance, unitSystem),
    [distance, unitSystem]
  );

  /**
   * Barbershop queue status
   */
  const status = useBarbershopQueueStatus(barbershop);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      if (status !== "taking-orders") {
        return false;
      }

      analytics.storeSelected({ ...barbershop, distance }, source);
      navigateTo(`/barbershop/${id}`, "switch-open");

      if (onAfterClick) {
        onAfterClick();
      }
    },
    [onAfterClick, barbershop, distance, source, id, status]
  );

  const componentClass = classNames("c-barbershop-card", {
    "is-disabled": status !== "taking-orders",
  });

  /**
   * @note There was a weird visual glitch in chrome where the cards would
   * sometimes disappear when scrolling, and re-appear when changing some css value.
   *
   * It _might_ have been caused by using a `<button>` element as the component
   * root, but not sure. Take care if using that.
   */
  return (
    <a
      href={`/barbershop/${id}`}
      className={componentClass}
      onClick={handleClick}
    >
      <div className="c-barbershop-card__indicator">
        {status === "taking-orders" ? (
          <Timer minutes={queue} size="small" />
        ) : (
          <div className="c-barbershop-card__message">
            {status === "full" ? (
              <Translate id="list.noOrders" />
            ) : (
              <Translate id="list.closed" />
            )}
          </div>
        )}
        {!!formattedDistance && (
          <div className="c-barbershop-card__distance">{formattedDistance}</div>
        )}
      </div>

      <div className="c-barbershop-card__main">
        <h3 className="c-barbershop-card__title">
          {`${name}, ${city}`}{" "}
          {isFavorite && (
            <span className="c-barbershop-card__favorite">
              <FavoriteIcon width={12} height={12} />
            </span>
          )}
        </h3>
        <p className="c-barbershop-card__subtitle">{address}</p>
        <p className="c-barbershop-card__opening-hours">
          {/**
           * This is a hacky way to get around a weird
           * TypeScript bug. Should be fixed by typing the
           * OpeningHours component instead.
           */}
          <OpeningHours {...{ times: openingHours }} />
          {chairCount && chairCount !== "0" && (
            <>
              <span>&bull; </span>
              <span className="c-barbershop-card__barbers">
                <ScissorIcon width={15} height={15} />
                {chairCount}
              </span>
            </>
          )}
        </p>
      </div>

      <div className="c-barbershop-card__icon">
        <IconArrowRight />
      </div>
    </a>
  );
};

export default BarbershopCard;

interface MultiCardLoaderProps {
  amount?: number;
}

export const MultiBarbershopCardLoader: React.FC<MultiCardLoaderProps> = ({
  amount = 3,
}) => {
  return (
    <>
      {[...Array(amount)].map((_, index) => (
        <BarbershopCardLoader key={index} />
      ))}
    </>
  );
};

export const BarbershopCardLoader = () => (
  <ContentLoader
    viewBox="0 0 315 92"
    width={"100%"}
    height={92}
    {...loaderTheme}
    opacity={0.5}
    style={{ width: "100%", display: "block", marginBottom: 3 }}
    preserveAspectRatio="none"
  >
    <rect x="0" y="0" rx="5" ry="5" width="315" height="92" />
  </ContentLoader>
);
