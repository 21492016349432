import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import "inobounce";
import "keen-slider/keen-slider.min.css";

import { isDev, shouldDisableServiceWorker } from "./utils/Helpers";
import App from "./App";
import { store } from "./store";
import initFirebase from "./firebase";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import sentryConfig from "./config/sentry.json";
import { initA11y } from "./utils/a11y";

import "./styles/webfonts.css";
import "./styles/index.scss";

// initialize Firebase
initFirebase();

// Error handling with Sentry.io
if (!isDev()) {
  Sentry.init({
    dsn: `https://${sentryConfig.key}@sentry.io/${sentryConfig.project}`,
  });
}

// Initialize a11y helpers
initA11y();

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalizeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (shouldDisableServiceWorker()) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      // Refresh service worker automatically
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }

      window.location.reload();
    },
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
