import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  Loading,
  TrackingOrder,
  TrackingStatus,
  Barbershop,
} from "../../types";
import * as orderService from "../../services/order";
import * as barbershopService from "../../services/barbershop";
import { RootState, AppThunkApi } from "../../store";

export const fetchOrder = createAsyncThunk<TrackingOrder, number, AppThunkApi>(
  "tracking/fetchOrder",
  async (id, { rejectWithValue }) => {
    const response = orderService.getOrder(id).then(
      (order) => order as TrackingOrder,
      (error) => rejectWithValue(error?.data?.error)
    );

    return response;
  }
);

export const fetchBarbershop = createAsyncThunk<
  Barbershop,
  number,
  AppThunkApi
>("tracking/fetchBarbershop", async (id, { rejectWithValue }) => {
  const response = barbershopService.getBarbershop(id).then(
    (barbershop) => barbershop as Barbershop,
    (error) => rejectWithValue(error?.data?.error)
  );

  return response;
});

export const checkIn = createAsyncThunk<boolean, number, AppThunkApi>(
  "tracking/checkIn",
  async (id, { rejectWithValue }) => {
    const response = orderService.checkIn(id).then(
      () => true,
      (error) => rejectWithValue(error?.data?.error)
    );

    return response;
  }
);

export const cancelOrder = createAsyncThunk<boolean, number, AppThunkApi>(
  "tracking/cancelOrder",
  async (id, { rejectWithValue }) => {
    const response = orderService.cancelOrder(id).then(
      () => true,
      (error) => rejectWithValue(error?.data?.error)
    );

    return response;
  }
);

export interface TrackingState {
  loadingOrder: Loading;
  loadingBarbershop: Loading;
  checkingIn: Loading;
  canceling: Loading;
  order: false | TrackingOrder;
  barbershop: false | Barbershop;
  status: "unknown" | TrackingStatus;
  checkedIn: boolean;
}

const initialState: TrackingState = {
  loadingOrder: "idle",
  loadingBarbershop: "idle",
  checkingIn: "idle",
  canceling: "idle",
  order: false,
  barbershop: false,
  status: "unknown",
  checkedIn: false,
};

export const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    clearTracking: () => {
      orderService.clearOrder();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // tracking/fetchOrder
    builder.addCase(fetchOrder.pending, (state) => {
      state.loadingOrder = "pending";
    });

    builder.addCase(fetchOrder.fulfilled, (state, action) => {
      state.loadingOrder = "fulfilled";

      const { status, checkedIn } = action.payload;

      state.order = action.payload;
      state.status = status;
      state.checkedIn = checkedIn;
    });

    builder.addCase(fetchOrder.rejected, (state) => {
      state.loadingOrder = "rejected";
    });

    // tracking/fetchBarbershop
    builder.addCase(fetchBarbershop.pending, (state) => {
      state.loadingBarbershop = "pending";
    });

    builder.addCase(fetchBarbershop.fulfilled, (state, action) => {
      state.loadingBarbershop = "fulfilled";
      state.barbershop = action.payload;
    });

    builder.addCase(fetchBarbershop.rejected, (state) => {
      state.loadingBarbershop = "rejected";
    });

    // tracking/checkIn
    builder.addCase(checkIn.pending, (state) => {
      state.checkingIn = "pending";
    });

    builder.addCase(checkIn.fulfilled, (state) => {
      state.checkingIn = "fulfilled";
      state.checkedIn = true;
    });

    builder.addCase(checkIn.rejected, (state) => {
      state.checkingIn = "rejected";
    });

    // tracking/cancelOrder
    builder.addCase(cancelOrder.pending, (state) => {
      state.canceling = "pending";
    });

    builder.addCase(cancelOrder.fulfilled, (state) => {
      state.canceling = "fulfilled";
      state.status = TrackingStatus.cancelled;
    });

    builder.addCase(cancelOrder.rejected, (state) => {
      state.canceling = "rejected";
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearTracking } = trackingSlice.actions;

export default trackingSlice.reducer;

export const selectTrackingLoadingOrder = (state: RootState) =>
  state.tracking.loadingOrder;
export const selectTrackingLoadingBarbershop = (state: RootState) =>
  state.tracking.loadingBarbershop;
export const selectTrackingCheckingIn = (state: RootState) =>
  state.tracking.checkingIn;
export const selectTrackingCanceling = (state: RootState) =>
  state.tracking.canceling;

export const selectTrackingOrder = (state: RootState) => state.tracking.order;
export const selectTrackingBarbershop = (state: RootState) =>
  state.tracking.barbershop;
export const selectTrackingStatus = (state: RootState) => state.tracking.status;
export const selectTrackingCheckedIn = (state: RootState) =>
  state.tracking.checkedIn;
