import React from "react";

interface TrackingTimerProps {
  className?: string;
  onClick?: () => void;
}

const TrackingTimer: React.FC<TrackingTimerProps> = ({
  className,
  onClick,
  children,
}) => {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

export default TrackingTimer;
