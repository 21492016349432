import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class LocalizedContent extends React.Component {
  render() {
    return this.props.locales.includes(this.props.currentLocale)
      ? this.props.children
      : null;
  }
}

LocalizedContent.propTypes = {
  locales: PropTypes.array,
  currentLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
};

function mapStateToProps(state, ownProps) {
  const y = {
    currentLocale: state.user.locale,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalizedContent);
