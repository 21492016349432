import React from "react";
import ContentLoader from "react-content-loader";
import { loaderTheme } from "../../utils/loaders";

const ServicesLoader = () => {
  return (
    <div
      style={{
        padding: "22px 30px",
        borderTop: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <ServiceGroupLoader />
      <ServiceLoader />
      <ServiceLoader />
      <ServiceLoader />
    </div>
  );
};

export default ServicesLoader;

const ServiceGroupLoader = () => (
  <ContentLoader
    viewBox="0 0 315 110"
    width={"100%"}
    height={110}
    {...loaderTheme}
    style={{ width: "100%", display: "block" }}
    preserveAspectRatio="none"
  >
    <rect x="0" y="0" rx="5" ry="5" width="80" height="22" />
    <rect x="0" y="30" rx="3" ry="3" width="315" height="10" />
    <rect x="0" y="44" rx="3" ry="3" width="275" height="10" />
    <rect x="0" y="58" rx="3" ry="3" width="295" height="10" />
    <rect x="0" y="72" rx="3" ry="3" width="225" height="10" />
  </ContentLoader>
);

const ServiceLoader = () => (
  <ContentLoader
    viewBox="0 0 315 75"
    width={"100%"}
    height={75}
    {...loaderTheme}
    style={{ width: "100%", display: "block" }}
    preserveAspectRatio="none"
  >
    <rect x="0" y="0" rx="5" ry="5" width="75" height="12" />
    <rect x="0" y="18" rx="3" ry="3" width="145" height="10" />
    <rect x="0" y="34" rx="3" ry="3" width="50" height="12" />
  </ContentLoader>
);
