export const initA11y = () => {
  window.addEventListener("keydown", detectFirstTab);
};

export const detectFirstTab = (e: any) => {
  if (e.keyCode === 9) {
    document.documentElement.classList.add("has-user-tabbing");
    window.removeEventListener("keydown", detectFirstTab);
  }
};
