import React from "react";
import { TranslateString } from "../types";

interface IconTitleTextProps {
  icon: React.ReactNode;
  title: TranslateString;
  text: TranslateString;
}

const IconTitleText: React.FC<IconTitleTextProps> = ({ icon, title, text }) => {
  return (
    <div className="c-icon-title-text">
      <div className="c-icon-title-text__icon">{icon}</div>
      <div className="c-icon-title-text__content">
        <h3 className="c-icon-title-text__title">{title}</h3>
        <p className="c-icon-title-text__text">{text}</p>
      </div>
    </div>
  );
};

export default IconTitleText;
