import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectUserLatitude,
  selectUserLongitude,
  selectUserProfile,
} from "../../ducks/user";

import { analytics } from "../../utils/Analytics";

export const useGeolocationDataReadyEvent = (slug: string, title: string) => {
  const prevLatitude = useRef(null);
  const prevLongitude = useRef(null);

  const latitude = useSelector(selectUserLatitude);
  const longitude = useSelector(selectUserLongitude);
  const profile = useSelector(selectUserProfile);

  useEffect(() => {
    // Only trigger if we have geolocation data
    if (latitude && longitude) {
      // Only trigger if the geolocation has changed
      if (
        prevLatitude.current !== latitude ||
        prevLongitude.current !== longitude
      ) {
        analytics.glDataReady(slug, title, latitude, longitude, profile);

        // Store current geolocation data
        prevLatitude.current = latitude;
        prevLongitude.current = longitude;
      }
    }
  }, [slug, title, latitude, longitude, profile]);

  return null;
};
