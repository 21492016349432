import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";

import {
  Service,
  ExtraServices,
  ExtraService,
  RenderComponent,
} from "../../types";
import { priceToUnits } from "../../utils/Helpers";
import { slideOpen } from "../animations/utils";
import { addService, removeService, selectOrderServiceIds } from "./orderSlice";
import { useServiceExtraServices } from "./use-service-extra-services";
import CheckMark from "../../components/CheckMark";

import { ReactComponent as InfoIcon } from "../../assets/images/icon-info.svg";

interface ServiceToggleProps extends Service {
  data?: ExtraServices;
  render?: (
    serviceExtraServices: ExtraService[],
    index?: number
  ) => RenderComponent;
  onInfoClick?: (service: Service) => void;
  size?: "regular" | "large";
}

const ServiceToggle: React.FC<ServiceToggleProps> = ({
  data,
  render,
  onInfoClick,
  size = "regular",
  ...service
}) => {
  const { id, title, description, longDescription, price } = service;
  const dispatch = useDispatch();
  const selected = useSelector(selectOrderServiceIds);
  const isSelected = useMemo(() => selected.includes(id), [selected, id]);
  const serviceExtraServices = useServiceExtraServices(id, data);
  const hasInfo = !!longDescription;
  const hasRender = !!render;

  const handleClick = useCallback(() => {
    if (!isSelected) {
      dispatch(addService(id));
    } else {
      dispatch(removeService(id));
    }
  }, [dispatch, id, isSelected]);

  const componentClass = classNames("c-service-toggle", {
    "is-selected": isSelected,
    "has-extra-services": !!(
      serviceExtraServices && serviceExtraServices.length
    ),
    "has-info": hasInfo,
    [`is-size-${size}`]: true,
  });

  return (
    <div className={componentClass}>
      <button className="c-service-toggle__button" onClick={handleClick}>
        <h3 className="c-service-toggle__title">{title}</h3>
        <p className="c-service-toggle__description">{description}</p>
        <p className="c-service-toggle__price">{priceToUnits(price)}</p>
        <div className="c-service-toggle__icon">
          <CheckMark isSelected={isSelected} />
        </div>
      </button>

      {hasInfo && onInfoClick && (
        <button
          className="c-service-toggle__info h-button-wrapper"
          onClick={() => onInfoClick(service)}
        >
          <InfoIcon />
        </button>
      )}

      <AnimatePresence>
        {isSelected && hasRender && (
          <motion.div {...slideOpen}>{render(serviceExtraServices)}</motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ServiceToggle;
