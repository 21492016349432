import React from "react";
import classNames from "classnames";
import { Translate } from "react-localize-redux";

import { BarbershopQueueStatus, Loading } from "../../types";

interface BarbershopTimerProps {
  className?: string;
  status: BarbershopQueueStatus;
  loading: Loading;
  size?: "regular" | "tiny";
  onClick?: () => void;
}

const BarbershopTimer: React.FC<BarbershopTimerProps> = ({
  status,
  loading,
  onClick,
  size = "regular",
  children,
}) => {
  const componentClass = classNames("c-barbershop-timer", {
    "is-message": status !== "taking-orders",
    [`c-barbershop-timer--${size}`]: true,
  });

  const messages = {
    full: <Translate id="list.noOrders" />,
    closed: <Translate id="list.closed" />,
    unknown: "–",
  };

  if (status === null) {
    return null;
  }

  return (
    <button className={componentClass} onClick={onClick}>
      {status === "taking-orders" || loading !== "fulfilled" ? (
        <>{children}</>
      ) : (
        <span>{messages[status]}</span>
      )}
    </button>
  );
};

export default BarbershopTimer;
