import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const MembershipBadge = ({ type, text, clickHandler, shape }) => {
    const handleClick = () => {
        if (typeof clickHandler === "function") {
            clickHandler();
        }
    };

    const componentClasses = classNames({
        "c-membership-badge": true,
        [`c-membership-badge--${type.toLowerCase()}`]: true,
        [`c-membership-badge--${shape}`]: true
    });

    return (
        <div className={componentClasses} onClick={handleClick}>
            <p>
                <strong>{type}</strong> {text}
            </p>
        </div>
    );
};

MembershipBadge.propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    clickHandler: PropTypes.func,
    shape: PropTypes.string
};

export default MembershipBadge;
