import { configureStore } from "@reduxjs/toolkit";

// using our ducks approach lets import our reducer from that feature
import deprecated__list from "./ducks/deprecated__list";
import notification from "./ducks/notification";
import deprecated__order from "./ducks/deprecated__order";
import user, { LOCATION_SUCCESS } from "./ducks/user";
import {
  ADD_EXTRA_SERVICE,
  REMOVE_EXTRA_SERVICE,
} from "./ducks/deprecated__order";
import messages from "./ducks/messages";

import order from "./features/order/orderSlice";
import tracking from "./features/tracking/trackingSlice";
import barbershop from "./features/barbershop/barbershopSlice";

export const store = configureStore({
  reducer: {
    order,
    tracking,
    barbershop,

    // Legacy
    deprecated__list,
    notification,
    deprecated__order,
    user,
    messages,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Support for legacy mutable ducks
      immutableCheck: {
        ignoredPaths: [
          "deprecated__list",
          "notification",
          "deprecated__order",
          "user",
          "messages",
        ],
      },
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          // Legacy
          LOCATION_SUCCESS,
          ADD_EXTRA_SERVICE,
          REMOVE_EXTRA_SERVICE,
        ],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type
export type AppDispatch = typeof store.dispatch;
// Basic thunk api config that rejects with an error message
export interface AppThunkApi {
  rejectValue: string;
}
