import React from "react";

import { TranslateString } from "../types";

interface PageHeaderProps {
  title: TranslateString;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <div className="c-page-header">
      <h1 className="h3">{title}</h1>
    </div>
  );
};

export default PageHeader;
