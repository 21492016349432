import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import LoginForm from "./LoginForm";
import { TranslateString } from "../types";
import { syncUserProfile } from "../ducks/user";
import { useLogin } from "../features/user/use-login";
import { ReactComponent as IconClose } from "../assets/images/icon-login-dialog-close.svg";
import { analytics } from "../utils/Analytics";

interface LoginDialogProps {
  title: TranslateString;
  onClose?: () => void;
  onComplete?: () => void;
  onLinkClick?: () => void;
}

function LoginDialog({
  title,
  onClose,
  onComplete,
  onLinkClick,
}: LoginDialogProps) {
  const dispatch = useDispatch();
  const { loggedIn, errors, submit } = useLogin();

  useEffect(() => {
    if (loggedIn) {
      dispatch(syncUserProfile());
      if (onComplete) {
        onComplete();
        analytics.genericEvent("loginSuccessful");
      }
    }
  }, [dispatch, loggedIn, onComplete]);

  return (
    <div className="c-login-dialog">
      {!!onClose && (
        <button onClick={onClose} className="c-login-dialog__close">
          <IconClose />
        </button>
      )}
      <h2 className="c-login-dialog__title">{title}</h2>
      <LoginForm
        submitHandler={submit}
        errors={errors}
        onLinkClick={onLinkClick}
      />
    </div>
  );
}

export default LoginDialog;
