import React from "react";
import classNames from "classnames";

interface FixedHeaderContentProps {
  className: string;
  title: string;
  isVisible: boolean;
}

const FixedHeaderContent: React.FC<FixedHeaderContentProps> = ({
  className,
  title,
  children,
  isVisible,
}) => {
  const componentClass = classNames(className, {
    "is-visible": isVisible,
  });

  const titleClass = `${className}__title`;
  const timerClass = `${className}__timer`;

  return (
    <div className={componentClass}>
      <h4 className={titleClass}>{title}</h4>
      <div className={timerClass}>{children}</div>
    </div>
  );
};

export default FixedHeaderContent;
