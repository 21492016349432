import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RouteLink from "../components/RouteLink";
import Spinner from "../components/Spinner";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import { showNotification } from "../ducks/notification";
import { syncUserProfile, logout } from "../ducks/user";

class Credits extends React.Component {
  constructor(props) {
    super(props);

    this.titleSet = false;
    this.blockTransition = false;
  }

  componentDidMount() {
    // sync profile from API to make sure data is up to date
    this.props.actions.syncUserProfile();

    analytics.pageView("/profile/credits", "Credits");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // if the login has expired, log the user out
    if (!newProps.loggedIn && !this.blockTransition) {
      this.props.actions.logout();
      this.props.actions.showNotification(
        newProps.translate("error.tokenTitle"),
        newProps.translate("error.tokenText")
      );

      this.blockTransition = true;
    }

    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.credits");
      this.titleSet = true;
    }
  }

  render() {
    const isLoaded = this.props.profile ? true : false;
    const isMember = this.props.profile && this.props.profile.membership;

    const componentClass = classNames({
      "s-settings": true,
      "h-scrollable": true,
      "s-settings--loading": this.props.loading,
    });

    return (
      <div className={componentClass}>
        <div className="s-settings__back">
          <RouteLink to="/profile" transition="switch-close">
            <div className="c-back-button">Back</div>
          </RouteLink>
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="credits.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="credits.subtitle" />
          </p>
        </div>

        {isLoaded && isMember && (
          <div className="s-settings__content">
            <div className="s-settings__item">
              <h3 className="s-settings__item-label">
                <Translate id="credits.servicesTitle" />
              </h3>
              <p className="s-settings__item-value">
                {this.props.profile.membership.credits}
              </p>
            </div>
            <div className="s-settings__item">
              <h3 className="s-settings__item-label">
                <Translate id="credits.extraTitle" />
              </h3>
              <p className="s-settings__item-value">
                {this.props.profile.membership.wp_prem_credits}
              </p>
            </div>
            <div className="s-settings__item">
              <h3 className="s-settings__item-label">
                <Translate id="credits.productsTitle" />
              </h3>
              <p className="s-settings__item-value">
                {this.props.profile.membership.wp_prod_credits}
              </p>
            </div>
          </div>
        )}

        {this.props.loading && (
          <div className="s-settings__spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

Credits.propTypes = {
  actions: PropTypes.object,
  profile: PropTypes.object,
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loading: state.user.loading,
    loggedIn: state.user.loggedIn,
    profile: state.user.profile,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        showNotification,
        syncUserProfile,
        logout,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Credits));
