import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

import RouteRedirect from "./RouteRedirect";

const selectLoggedIn = (state) => state.user.loggedIn;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(selectLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <RouteRedirect to="/" transition="instant" timeout={0} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default PrivateRoute;
