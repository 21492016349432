import { useState, useMemo } from "react";

import { usePreviousState } from "./use-previous-state";

/* eslint-disable no-useless-escape */
export const validateEmail = (maybeEmail: string): boolean => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    maybeEmail
  );
};
/* eslint-enable */

export function useEmailInput(initialState = "", initialFocus = false) {
  const [input, previousInput, setInput] = usePreviousState(initialState);
  const [focus, setFocus] = useState(initialFocus);
  const hasInput = !!input;
  const isValid = useMemo(() => validateEmail(input), [input]);
  const hasError = !focus && hasInput && !isValid;
  const hasNewInput = input !== previousInput;

  return {
    input,
    setInput,
    hasInput,
    hasNewInput,
    isValid,
    hasError,
    focus: () => setFocus(true),
    blur: () => setFocus(false),
  };
}
