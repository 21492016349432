import React from "react";
import { Translate } from "react-localize-redux";

import InlineNotice from "../../components/InlineNotice";
import { BarbershopQueueStatus, Barbershop } from "../../types";

interface StatusNoticeProps {
  className?: string;
  status: BarbershopQueueStatus;
  queue: Barbershop["queue"];
}

interface TitleAndText {
  title: JSX.Element;
  text: JSX.Element;
}

interface Texts {
  timeZero: TitleAndText;
  unavailable: TitleAndText;
}

const StatusNotice: React.FC<StatusNoticeProps> = ({
  className,
  status,
  queue,
}) => {
  if (status === "unknown") {
    return null;
  }

  const texts: Texts = {
    timeZero: {
      title: <Translate id="barbershop.timeZeroNoticeTitle" />,
      text: <Translate id="barbershop.timeZeroNoticeText" />,
    },
    unavailable: {
      title: <Translate id="barbershop.notTakingOrdersNoticeTitle" />,
      text: <Translate id="barbershop.notTakingOrdersNoticeText" />,
    },
  };

  const noticeType = getNoticeType(status, queue);

  if (!noticeType) {
    return null;
  }

  return (
    <div className={className}>
      <InlineNotice {...texts[noticeType]} />
    </div>
  );
};

export default StatusNotice;

const getNoticeType = (
  status: BarbershopQueueStatus,
  queue: Barbershop["queue"]
): keyof Texts | null => {
  if (status === "closed" || status === "full") {
    return "unavailable";
  }

  if (queue === 0) {
    return "timeZero";
  }

  return null;
};
