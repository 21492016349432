import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import gsap from "gsap";
import { withLocalize } from "react-localize-redux";

import { isEmptyObject } from "../utils/Helpers";
import { toggleFavoriteBarbershop } from "../ducks/user";

class FavoriteToggle extends React.Component {
  constructor(props) {
    super(props);

    this.pulse = null;

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();

    this.animatePulse();

    this.props.actions.toggleFavoriteBarbershop(this.props.barbershop);
  }

  animatePulse() {
    if (this.pulse === null) return false;

    gsap.set(this.pulse, { scale: 0, opacity: 1 });
    gsap.to(this.pulse, {
      duration: 1,
      scale: 1,
      opacity: 0,
      ease: "expo.out",
    });
  }

  render() {
    if (isEmptyObject(this.props.barbershop)) {
      return null;
    }

    const componentClass = classNames("c-favorite-toggle", "h-button-wrapper", {
      "is-active": this.props.isFavorite,
    });

    return (
      <button className={componentClass} onClick={this.handleClick}>
        <span
          ref={(c) => {
            this.pulse = c;
          }}
        />
      </button>
    );
  }
}

FavoriteToggle.propTypes = {
  barbershop: PropTypes.object,
  isFavorite: PropTypes.bool,
  actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        toggleFavoriteBarbershop,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(FavoriteToggle));
