import React from "react";
import classNames from "classnames";

interface AnchorLinkProps {
  className?: string;
  target: string;
  offset?: number | (() => number);
  scrollRef: React.RefObject<HTMLDivElement>;
}

const AnchorLink: React.FC<AnchorLinkProps> = ({
  className,
  target,
  offset = 0,
  scrollRef,
  children,
}) => {
  const handleClick = () => {
    /**
     * @note This is a bit dangerous. If doing something
     * business critical you should probably use refs instead.
     */
    const el = document.getElementById(target);

    if (el === null) {
      return false;
    }

    if (scrollRef.current === null) {
      return false;
    }

    const top = typeof offset === "function" ? el.offsetTop + offset() : offset;

    scrollRef.current.scrollTo({
      top,
      left: 0,
    });
  };

  const componentClass = classNames(className, "h-button-wrapper");

  return (
    <button className={componentClass} onClick={handleClick}>
      {children}
    </button>
  );
};

export default AnchorLink;

// import React from "react";
// import classNames from "classnames";

// interface AnchorLinkProps {
//   className?: string;
//   target: string;
//   offset?: number;
//   scrollRef: React.RefObject<HTMLDivElement>;
//   offsetRefs?: React.RefObject<HTMLDivElement>[];
// }

// const AnchorLink: React.FC<AnchorLinkProps> = ({
//   className,
//   target,
//   offset = 0,
//   scrollRef,
//   offsetRefs = [],
//   children,
// }) => {
//   const handleClick = () => {
//     /**
//      * @note This is a bit dangerous. If doing something
//      * business critical you should probably use refs instead.
//      */
//     const el = document.getElementById(target);

//     if (el === null) {
//       return false;
//     }

//     if (scrollRef.current === null) {
//       return false;
//     }

//     const refOffset = offsetRefs.reduce((result, ref) => {
//       if (ref.current) {
//         result += ref.current.offsetTop;
//       }

//       return result;
//     }, 0);

//     scrollRef.current.scrollTo({
//       top: el.offsetTop + refOffset + offset,
//       left: 0,
//     });
//   };

//   const componentClass = classNames(className, "h-button-wrapper");

//   return (
//     <button className={componentClass} onClick={handleClick}>
//       {children}
//     </button>
//   );
// };

// export default AnchorLink;
