import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import RouteLink from "../components/RouteLink";
import * as userService from "../services/user";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      showError: false,
      success: false,
      error: "", // error message from API comes here
      oldPW: "",
      newPW: "",
      confirmPW: "",
      errors: {
        oldPW: false,
        newPW: false,
        confirmPW: false,
      },
    };

    this.titleSet = false;

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    analytics.pageView("/profile/password", "Update Password");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.updatePassword");
      this.titleSet = true;
    }
  }

  changeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });

    setTimeout(() => {
      this.validate();
    }, 100);
  }

  validate() {
    let errors = {
      oldPW: false,
      newPW: false,
      confirmPW: false,
    };
    let canSubmit = true;

    if (!this.state.oldPW) {
      errors.oldPW = this.props.translate("updatePassword.oldPasswordError");
      canSubmit = false;
    }
    if (!this.state.newPW || this.state.newPW.length < 8) {
      errors.newPW = this.props.translate("updatePassword.newPasswordError");
      canSubmit = false;
    }
    if (this.state.newPW !== this.state.confirmPW) {
      errors.confirmPW = this.props.translate("updatePassword.matchError");
      canSubmit = false;
    }

    if (canSubmit) {
      this.setState({
        errors: errors,
        canSubmit: canSubmit,
        showError: false,
      });
    } else {
      this.setState({
        errors: errors,
        canSubmit: canSubmit,
      });
    }
  }

  submitHandler(event) {
    event.preventDefault();

    this.setState({
      showError: true,
    });

    this.validate();

    if (this.state.canSubmit) {
      userService.changePassword(this.state.oldPW, this.state.newPW).then(
        () => {
          this.setState({ success: true });
        },
        (response) => {
          if (typeof response == "object" && response.error) {
            this.setState({
              showError: true,
              error: response.error,
            });
          } else {
            this.setState({
              showError: true,
              error: this.props.translate("updatePassword.genericErrorInfo"),
            });
          }
        }
      );
    }
  }

  render() {
    const submitClass = classNames({
      "c-btn": true,
      "c-btn--small": true,
      "is-disabled": !this.state.canSubmit,
    });

    return (
      <div className="s-settings h-scrollable">
        <div className="s-settings__back">
          <RouteLink to="/profile" transition="switch-close">
            <div className="c-back-button">
              <Translate id="navigation.back" />
            </div>
          </RouteLink>
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="updatePassword.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="updatePassword.intro" />
          </p>
        </div>

        <div className="s-settings__content">
          <div className="c-password-form">
            {this.state.success && (
              <div className="c-password-form__success">
                <h2>
                  <Translate id="updatePassword.successTitle" />
                </h2>
                <p>
                  <Translate id="updatePassword.successInfo" />
                </p>
              </div>
            )}

            {!this.state.success && (
              <form onSubmit={this.submitHandler} method="post">
                {this.state.showError && this.state.error && (
                  <div className="c-password-form__errors">
                    <h3>
                      <Translate id="updatePassword.genericErrorTitle" />
                    </h3>
                    <p>{this.state.error}</p>
                  </div>
                )}

                <div
                  className={
                    "c-password-form__input" +
                    (this.state.showError && this.state.errors.oldPW
                      ? " has-error"
                      : "")
                  }
                >
                  <label htmlFor="password-old">
                    <Translate id="updatePassword.current" />
                  </label>
                  <input
                    id="password-old"
                    type="password"
                    name="oldPW"
                    onChange={this.changeHandler}
                  />
                  {this.state.showError && this.state.errors.oldPW && (
                    <p className="c-password-form__input__error">
                      {this.state.errors.oldPW}
                    </p>
                  )}
                </div>

                <div
                  className={
                    "c-password-form__input" +
                    (this.state.showError && this.state.errors.newPW
                      ? " has-error"
                      : "")
                  }
                >
                  <label htmlFor="password-new">
                    <Translate id="updatePassword.new" />
                  </label>
                  <input
                    id="password-new"
                    type="password"
                    name="newPW"
                    onChange={this.changeHandler}
                  />
                  {this.state.showError && this.state.errors.newPW && (
                    <p className="c-password-form__input__error">
                      {this.state.errors.newPW}
                    </p>
                  )}
                </div>

                <div
                  className={
                    "c-password-form__input" +
                    (this.state.showError && this.state.errors.confirmPW
                      ? " has-error"
                      : "")
                  }
                >
                  <label htmlFor="password-confirm">
                    <Translate id="updatePassword.confirm" />
                  </label>
                  <input
                    id="password-confirm"
                    type="password"
                    name="confirmPW"
                    onChange={this.changeHandler}
                  />
                  {this.state.showError && this.state.errors.confirmPW && (
                    <p className="c-password-form__input__error">
                      {this.state.errors.confirmPW}
                    </p>
                  )}
                </div>

                <div className="c-password-form__submit">
                  <button type="submit" className={submitClass}>
                    <Translate id="updatePassword.submit" />
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UpdatePassword.propTypes = {
  translate: PropTypes.func,
};

export default withLocalize(UpdatePassword);
