import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BackButton from "../components/LegacyBackButton";
import Option from "../components/Option";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

class UpdateLanguage extends React.Component {
  constructor(props) {
    super(props);

    this.titleSet = false;
    this.changeActiveLanguage = this.changeActiveLanguage.bind(this);
  }

  componentDidMount() {
    analytics.pageView("/profile/language", "Update Language");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.updateLanguage");
      this.titleSet = true;
    }
  }

  buildOptions(languages) {
    if (!languages && !languages.length) return false;

    return languages.map((language, index) => {
      return (
        <Option
          key={index}
          id={index}
          title={language.name}
          selected={language.active}
          clickHandler={this.changeActiveLanguage}
        />
      );
    });
  }

  changeActiveLanguage(option) {
    const newLanguage = this.props.languages.find((language) => {
      return language.name === option.title;
    });

    if (!newLanguage) return false;

    this.props.setActiveLanguage(newLanguage.code);

    localStorage.setItem("language", newLanguage.code);

    // TO-DO: If user is logged in, save language setting to API. Currently not available in API.
  }

  render() {
    const options = this.buildOptions(this.props.languages);

    return (
      <div className="s-settings h-scrollable">
        <div className="s-settings__back">
          <BackButton transition="switch-close" />
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="updateLanguage.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="updateLanguage.intro" />
          </p>
        </div>

        <div className="s-settings__content s-settings__content--no-xpadding">
          <div className="s-settings__options">{options}</div>
        </div>
      </div>
    );
  }
}

UpdateLanguage.propTypes = {
  languages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setActiveLanguage: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(UpdateLanguage));
