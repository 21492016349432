import React from "react";

import { ExtraService, TranslateString, RenderComponent } from "../../types";

interface ExtraServiceGroupContainerProps {
  data: ExtraService[];
  title?: TranslateString;
  render: (service: ExtraService, index?: number) => RenderComponent;
}

const ExtraServiceGroupContainer: React.FC<ExtraServiceGroupContainerProps> = ({
  data,
  title,
  render,
}) => {
  if (data?.length === 0) {
    return null;
  }

  return (
    <div className="c-extra-service-group-container">
      {!!title && (
        <h4 className="c-extra-service-group-container__title">{title}</h4>
      )}
      <ul className="c-extra-service-group-container__services">
        {data.map((service, index) => (
          <li
            key={service.id}
            className="c-extra-service-group-container__service"
          >
            {render(service, index)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExtraServiceGroupContainer;
