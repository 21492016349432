import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import * as helpers from "../utils/Helpers";
import Button from "./Button";
import Dropdown from "./Dropdown";
import Spinner from "./Spinner";
import countryData from "../data/limitedCountries.json";
import { withLocalize, Translate } from "react-localize-redux";

import { setLocale, setLocaleFromIP } from "../ducks/user";

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.countryData = countryData;
    this.countryOptions = this.getCountryOptions(countryData);

    this.state = {
      countryCode: helpers.getInitialDialcode(
        this.props.locale,
        this.countryData
      ),
      phone: "",
      password: "",
      firstname: "",
      lastname: "",
      terms_accepted: false,
      allow_email: false,
      allow_sms: true,

      isUntouched: true,
      isValid: false,
      hasChanged: false,
      submitAttempted: false,

      errors: {},
    };

    this.validateForm = this.validateForm.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.fieldBlurHandler = this.fieldBlurHandler.bind(this);
  }

  componentDidMount() {
    if (!this.props.locale) {
      this.props.actions.setLocaleFromIP(this.countryData);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.props.locale && newProps.locale) {
      this.setState({
        countryCode: helpers.getDialcodeFromLocale(
          newProps.locale,
          this.countryData
        ),
      });
    }
  }

  validateField(field, value) {
    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );

    if (!value) {
      value = this.state[field];
    }
    switch (field) {
      case "phone":
        if (!value || value.length < 6) {
          return false;
        }
        return true; // validates
      case "password":
        if (!value || !passwordRegex.test(value)) {
          return false;
        }
        return true; // validates
      case "email":
        if (!value || !/[^@]+@[^@]+\.\w{2,}/.test(value)) {
          return false;
        }
        return true; // validates
      default:
        if (!value) {
          return false;
        }
        return true; // validates
    }
  }

  validateForm(options) {
    if (!options) {
      options = {};
    }

    let errors = {};

    // validateField returns true when field is valid
    errors.firstname = !this.validateField("firstname");
    errors.lastname = !this.validateField("lastname");
    errors.phone = !this.validateField("phone");
    errors.password = !this.validateField("password");
    errors.email = !this.validateField("email");
    errors.terms = this.state.terms_accepted ? false : true;

    let isValid = true;

    Object.keys(errors).forEach((key) => {
      if (errors[key]) {
        isValid = false;
      }
    });

    this.setState({
      isValid,
    });

    if (options.skipErrors) {
      if (isValid) {
        this.setState({
          errors: { ...this.state.errors, ...errors },
        });
      }
    } else {
      this.setState({
        errors: { ...this.state.errors, ...errors },
      });
    }

    return isValid;
  }

  submitHandler(event) {
    event.preventDefault();

    this.setState({
      submitAttempted: true,
    });

    if (this.validateForm()) {
      // no errors

      const data = {
        phone: this.state.countryCode + this.state.phone,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        password: this.state.password,
        terms_accepted: this.state.terms_accepted ? true : false,
        allow_email: this.state.allow_email ? true : false,
        allow_sms: this.state.allow_sms ? true : false,
      };

      this.props.submitHandler(data);
    } else {
      return false;
    }
  }

  checkboxChange(event) {
    this.setState({
      [event.target.name]: event.target.checked ? event.target.value : false,
      isUntouched: false,
    });
    if (event.target.required) {
      setTimeout(() => {
        this.validateForm();
      }, 100);
    }
  }

  changeHandler(event) {
    const valid = this.validateField(event.target.name, event.target.value);

    this.setState({
      [event.target.name]: event.target.value,
      isUntouched: false,
    });

    if (valid) {
      this.setState({
        errors: { ...this.state.errors, [event.target.name]: 0 },
      });
    } else {
      this.setState({
        isValid: false,
      });

      if (this.state.submitAttempted) {
        this.setState({
          errors: { ...this.state.errors, [event.target.name]: 1 },
        });
      }
    }
  }

  fieldBlurHandler(event) {
    const valid = this.validateField(event.target.name, event.target.value);

    this.setState({
      [event.target.name]: event.target.value,
    });

    if (valid) {
      this.setState({
        errors: { ...this.state.errors, [event.target.name]: 0 },
      });

      if (this.state.submitAttempted) {
        setTimeout(() => {
          this.validateForm();
        }, 100);
      } else if (!this.state.isUntouched) {
        setTimeout(() => {
          this.validateForm({ skipErrors: true });
        }, 100);
      }
    } else {
      this.setState({
        isValid: false,
      });

      if (this.state.submitAttempted) {
        this.setState({
          errors: { ...this.state.errors, [event.target.name]: 1 },
        });
      }
    }
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryCode: selectedOption,
    });

    this.props.actions.setLocale(
      helpers.getLocaleFromDialcode(selectedOption, this.countryData)
    );
  }

  getCountryDataFromCode(code) {
    return this.countryData.find((country) => {
      return country.code === code;
    });
  }

  getCountryOptions(data) {
    return data.map((country) => {
      return {
        value: country.dial_code,
        label: country.name,
      };
    });
  }

  render() {
    const formClass = classNames({
      "c-register-form": true,
      "is-submittable": this.state.isValid,
      "is-submitting": this.props.hasSubmitted,
    });
    const phoneClass = classNames({
      "c-register-form__phone": true,
      "has-error": this.state.errors.phone,
    });
    const passwordClass = classNames({
      "c-register-form__password": true,
      "has-error": this.state.errors.password,
    });
    const firstNameClass = classNames({
      "c-register-form__firstname": true,
      "has-error": this.state.errors.firstname,
    });
    const lastNameClass = classNames({
      "c-register-form__lastname": true,
      "has-error": this.state.errors.lastname,
    });
    const emailClass = classNames({
      "c-register-form__email": true,
      "has-error": this.state.errors.email,
    });
    const termsClass = classNames({
      "c-register-form__terms": true,
      "has-error": this.state.errors.terms,
    });
    const allowEmailClass = classNames({
      "c-register-form__allow-email": true,
      "has-error": this.state.errors.allow_email,
    });

    const phoneError = this.state.errors.phone ? (
      <div className="c-register-form__error-message">
        <Translate id="register.phoneError" />
      </div>
    ) : (
      ""
    );
    const passwordError = this.state.errors.password ? (
      <div className="c-register-form__error-message">
        <Translate id="register.passwordError" />
      </div>
    ) : (
      ""
    );
    const firstnameError = this.state.errors.firstname ? (
      <div className="c-register-form__error-message">
        <Translate id="register.firstnameError" />
      </div>
    ) : (
      ""
    );
    const lastnameError = this.state.errors.lastname ? (
      <div className="c-register-form__error-message">
        <Translate id="register.lastnameError" />
      </div>
    ) : (
      ""
    );
    const emailError = this.state.errors.email ? (
      <div className="c-register-form__error-message">
        <Translate id="register.emailError" />
      </div>
    ) : (
      ""
    );
    const termsError = this.state.errors.terms ? (
      <div
        className="c-register-form__error-message"
        style={{ marginLeft: "10px" }}
      >
        <Translate id="register.termsError" />
      </div>
    ) : (
      ""
    );

    return (
      <div className={formClass}>
        {this.props.hasSubmitted && <Spinner />}

        <form onSubmit={this.onSubmit} method="post" autoComplete="off">
          <input type="hidden" value="chrome-autocomplete-fix" />

          <div className="c-register-form__field">
            <div className="c-register-form__country-code">
              <Dropdown
                changeHandler={this.handleCountryChange}
                options={this.countryOptions}
                selectedOption={this.state.countryCode}
              />
            </div>
            <input
              type="tel"
              name="phone"
              placeholder="401234567"
              required
              className={phoneClass}
              onChange={this.changeHandler}
              onBlur={this.fieldBlurHandler}
            />
            <label htmlFor="phone">
              <Translate id="register.phoneLabel" />
            </label>
            {phoneError}
          </div>

          <div className="c-register-form__field">
            <input
              type="text"
              name="firstname"
              required
              className={firstNameClass}
              onChange={this.changeHandler}
              onBlur={this.fieldBlurHandler}
            />
            <label htmlFor="firstname">
              <Translate id="register.firstnameLabel" />
            </label>
            {firstnameError}
          </div>

          <div className="c-register-form__field">
            <input
              type="text"
              name="lastname"
              required
              className={lastNameClass}
              onChange={this.changeHandler}
              onBlur={this.fieldBlurHandler}
            />
            <label htmlFor="lastname">
              <Translate id="register.lastnameLabel" />
            </label>
            {lastnameError}
          </div>

          <div className="c-register-form__field">
            <input
              type="email"
              name="email"
              required
              className={emailClass}
              onChange={this.changeHandler}
              onBlur={this.fieldBlurHandler}
            />
            <label htmlFor="email">
              <Translate id="register.emailLabel" />
            </label>
            {emailError}
          </div>

          <div className="c-register-form__field">
            <input
              type="password"
              name="password"
              required
              className={passwordClass}
              onChange={this.changeHandler}
              onBlur={this.fieldBlurHandler}
              autoComplete="new-password"
            />
            <label htmlFor="password">
              <Translate id="register.passwordLabel" />
            </label>
            <div className="c-register-form__instructions">
              <Translate id="register.passwordInstructions" />
            </div>
            {passwordError}
          </div>

          <div className="c-register-form__checkbox">
            <input
              type="checkbox"
              name="terms_accepted"
              id="terms_accepted"
              required
              className={termsClass}
              checked={this.state.terms_accepted ? true : false}
              onChange={this.checkboxChange}
            />
            <label htmlFor="terms_accepted">
              {this.props.translate("register.termsLabel") + " "}
              <a
                href={this.props.translate("links.terms")}
                target="_blank"
                rel="noreferrer"
              >
                <Translate id="register.termsLinkText" />
              </a>
            </label>
            {termsError}
          </div>

          <div className="c-register-form__checkbox">
            <input
              type="checkbox"
              name="allow_email"
              id="allow_email"
              className={allowEmailClass}
              checked={this.state.allow_email ? true : false}
              onChange={this.checkboxChange}
            />
            <label htmlFor="allow_email">
              <Translate id="register.allowEmailLabel" />
            </label>
          </div>

          <div className="c-register-form__submit">
            <Button
              size="regular"
              onClick={this.submitHandler}
              text={this.props.translate("register.submitText")}
            />
          </div>
        </form>
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  submitHandler: PropTypes.func,
  hasSubmitted: PropTypes.bool,
  translate: PropTypes.func,
  actions: PropTypes.object,
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

function mapStateToProps(state, ownProps) {
  const y = {
    locale: state.user.locale,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        setLocale,
        setLocaleFromIP,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(RegistrationForm));
