import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Locator extends React.Component {
  render() {
    const componentClass = classNames({
      "c-locator": true,
      "c-locator--loading": this.props.loading,
      "h-button-wrapper": true,
      "c-locator--error": this.props.error,
    });

    return (
      <button className={componentClass} onClick={this.props.clickHandler} />
    );
  }
}

Locator.propTypes = {
  clickHandler: PropTypes.func,
  loading: PropTypes.bool,
};

export default Locator;
