import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import RouteLink from "../components/RouteLink";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import Message from "../components/Message";

class Messages extends React.Component {
  constructor(props) {
    super(props);

    this.blockTransition = false;
    this.titleSet = false;
  }

  componentDidMount() {
    analytics.pageView("/profile/messages", "Messages");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.messages");
      this.titleSet = true;
    }
  }

  render() {
    const componentClass = classNames({
      "s-messages": true,
      "h-scrollable": true,
    });

    return (
      <div className={componentClass}>
        <div className="s-messages__back">
          <RouteLink to="/profile" transition="switch-close">
            <div className="c-back-button">Back</div>
          </RouteLink>
        </div>

        <div className="s-messages__header">
          <h1 className="s-messages__title">
            <span>
              <Translate id="messages.title" />
            </span>

            {!!this.props.unreadCount && (
              <span className="s-messages__badge">
                {this.props.unreadCount}
              </span>
            )}
          </h1>
        </div>

        <div className="s-messages__content">
          {this.props.messages.map((message) => (
            <Message
              key={message.id}
              {...message}
              isRead={this.props.read.includes(message.id)}
            />
          ))}
        </div>
      </div>
    );
  }
}

Messages.propTypes = {
  actions: PropTypes.object,
  messages: PropTypes.array,
  read: PropTypes.array,
  unreadCount: PropTypes.number,
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    messages: state.messages.messages,
    read: state.messages.read,
    unreadCount: state.messages.unreadCount,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Messages));
