import React, { useMemo } from "react";
import { withLocalize, LocalizeContextProps } from "react-localize-redux";

import { Barbershop, RenderComponent, UnitSystem } from "../../types";
import { getUnitSystem } from "../../services/user";
import { sortBarbershopsByDistances } from "./utils";
import { useDistancesToUser } from "../user/use-distances-to-user";
import {
  metricToUnitSystem,
  metricToUnitSystemSuffix,
} from "../../utils/units";
import ListLabel from "./ListLabel";

interface BarbershopListByDistanceProps extends LocalizeContextProps {
  className?: string;
  itemClassName?: string;
  barbershops: Barbershop[];
  render: (
    barbershop: Barbershop,
    distance: number | null,
    unitSystem: UnitSystem,
    index?: number
  ) => RenderComponent;
  loader: React.ReactElement;
}

const BarbershopListByDistance: React.FC<BarbershopListByDistanceProps> = ({
  className,
  itemClassName,
  barbershops,
  translate,
  render,
  loader,
}) => {
  const distances = useDistancesToUser(barbershops);
  const unitSystem = useMemo(() => getUnitSystem(), []);

  const sortedBarbershops = useMemo(() => {
    if (distances === null) {
      return barbershops;
    }

    return sortBarbershopsByDistances(barbershops, distances);
  }, [barbershops, distances]);

  /**
   * We need to keep track of distance labels ("under 1 km"),
   * in order to add them only once above the first item
   * that matches the rules.
   */
  const usedLabels = [] as string[];

  return (
    <div className={className}>
      {distances === null
        ? loader
        : sortedBarbershops.map((barbershop) => {
            const distance = metricToUnitSystem(
              distances[barbershop.id],
              unitSystem
            );

            const labelId = getLabelTranslationId(distance);

            let label = null;

            if (labelId && !usedLabels.includes(labelId)) {
              label = metricToUnitSystemSuffix(
                translate(labelId).toString(),
                unitSystem
              );

              usedLabels.push(labelId);
            }

            return (
              <div key={barbershop.id} className={itemClassName}>
                {!!label && <ListLabel>{label}</ListLabel>}
                {render(barbershop, distance, unitSystem)}
              </div>
            );
          })}
    </div>
  );
};

export default withLocalize(BarbershopListByDistance);

const getLabelTranslationId = (distance: number | null): string | null => {
  if (distance === null) {
    return null;
  }

  if (distance < 1) {
    return "list.label1";
  }

  if (distance < 2) {
    return "list.label2";
  }

  if (distance < 10) {
    return "list.label3";
  }

  return "list.label4";
};
