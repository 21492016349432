import React from "react";
import { motion } from "framer-motion";

import { bounce as bounceAnimation } from "./utils";
import { SpringAnimationComponentProps } from "./types";

const Bounce: React.FC<SpringAnimationComponentProps> = ({
  children,
  className,
  bounce = 0.5,
  duration = 1,
  delay = 0,
  ...props
}) => {
  return (
    <motion.div
      {...bounceAnimation}
      {...props}
      className={className}
      transition={{ type: "spring", bounce, duration, delay }}
    >
      {children}
    </motion.div>
  );
};

export default Bounce;
