import { useSelector } from "react-redux";

import { RootState } from "../../store";

const selectLoggedIn = (state: RootState) => state.user.loggedIn;

export const useIsLoggedIn = () => {
  const loggedIn = useSelector(selectLoggedIn);
  return loggedIn;
};
