import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import history from "../utils/navigation";

class MenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.clickHandler) {
      this.props.clickHandler();
    } else if (this.props.link) {
      history.push({
        pathname: this.props.link,
        state: {
          transition: "switch-open",
        },
      });
    }
  }

  render() {
    const componentClass = classNames({
      "c-menu-item": true,
      "c-menu-item--priority": this.props.priority,
      "c-menu-item--link": this.props.link,
    });

    const hasBadge = !!this.props.badge;

    const title = this.props.title ? (
      <h3 className="c-menu-item__title">
        <span>{this.props.title}</span>

        {hasBadge && (
          <span className="c-menu-item__badge">{this.props.badge}</span>
        )}
      </h3>
    ) : (
      ""
    );
    const subtitle = this.props.subtitle ? (
      <p className="c-menu-item__subtitle">{this.props.subtitle}</p>
    ) : (
      ""
    );

    return (
      <div className={componentClass} onClick={this.handleClick}>
        {title}
        {subtitle}
      </div>
    );
  }
}

MenuItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  priority: PropTypes.bool,
  badge: PropTypes.number,
  clickHandler: PropTypes.func,
};

export default MenuItem;
