import axios from "axios";

import * as helpers from "../utils/Helpers";
import * as APINormalizer from "../utils/APINormalizer";
import {
  getApiUrl,
  getRefreshToken,
  maybeRemoveTrailingSlash,
} from "../utils/api";

/**
 * Create the authentication api instance.
 */
const authApi = axios.create({
  baseURL: getApiUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Update baseURL before each request.
 */
authApi.interceptors.request.use(
  (config) => {
    config.baseURL = getApiUrl();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Maybe remove trailing slash before each request.
 */
authApi.interceptors.request.use(maybeRemoveTrailingSlash);

/**
 * Send the user login credentials to the API endpoint and receive access token.
 *
 * @return {Promise.<function, Error>} Resolves with updating user id and access token, rejects with errors
 */
export function login(
  phone: string,
  password: string,
  rememberLogin: boolean
): any {
  return new Promise((resolve, reject) => {
    const data = {
      phone: phone,
      password: password,
    };

    authApi
      .post("/login/", data)
      .then((response) => {
        const data = APINormalizer.loginResponse(response.data);
        setRememberLogin(rememberLogin);
        resolve(helpers.updateLocalStorage(data, "user"));
      })
      .catch((error) => {
        reject({ phone: true, password: true });
      });
  });
}

/**
 * Logs the user out by removing the localStorage item.
 */
export function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("profile");
}

/**
 * Pass the registration form data to API
 */
export function register(data: any): any {
  return new Promise((resolve, reject) => {
    authApi
      .post("/register/", data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

/**
 * Get password recovery token from the API.
 *
 * @param {object} data - Contains user inputted phone number
 * @param {string} countryCode - Country code for local storage
 * @param {string} phone - Phone number for local storage
 * @return {Promise.<object, Error>} Resolves with an empty object or rejects with errors
 */
export function getRecoveryToken(
  data: any,
  countryCode: string,
  phone: string
) {
  return new Promise((resolve, reject) => {
    authApi
      .post("/auth/reset-token/", data)
      .then(() => {
        const date = new Date();

        resolve(
          helpers.updateLocalStorage(
            {
              countryCode: countryCode,
              phone: phone,
              date: date.getTime(),
            },
            "recovery"
          )
        );
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

/**
 * Tell the API to reset the user's password.
 *
 * @param {object} data - Contains user inputted phone, token and password
 * @return {Promise.<object, Error>} Resolves with an empty object or rejects with errors
 */
export function recoverPassword(data: any): any {
  return new Promise((resolve, reject) => {
    authApi
      .post("/auth/reset/", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

/**
 * Reset the password recovery process
 */
export function resetRecovery() {
  localStorage.removeItem("recovery");
}

/**
 * Refreshes the token from the API.
 */
export const refreshLogin = async () => {
  return new Promise((resolve, reject) => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      reject();
    }

    authApi
      .post(
        "/refresh/",
        {
          refresh: refreshToken,
        },
        /**
         * @note The following header is used by the legacy backend systems.
         *
         * Once all are migrated, it can be removed.
         */
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      )
      .then((response) => {
        resolve(APINormalizer.refreshResponse(response.data));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Sets if the user wants to remember login.
 */
export const setRememberLogin = (value: boolean) => {
  return localStorage.setItem("shouldRememberLogin", value.toString());
};

/**
 * Checks if user wants their login to be remembered
 */
export const shouldRememberLogin = () => {
  const shouldRememberLogin = localStorage.getItem("shouldRememberLogin");
  return shouldRememberLogin === "true" ? true : false;
};
