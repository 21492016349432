import { useEffect, useCallback } from "react";

// import * as userService from "../services/userService";

const TokenUpdater = () => {
  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === "visible") {
      // userService.refreshToken();
    }
  }, []);

  useEffect(() => {
    // userService.refreshToken();

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);

  return null;
};

export default TokenUpdater;
