import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DOMPurify from "dompurify";

import { markMessageRead } from "../ducks/messages";

class Message extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.state.isExpanded) {
      this.props.actions.markMessageRead(this.props.id);
    }

    this.setState((state) => {
      return { isExpanded: !state.isExpanded };
    });
  }

  render() {
    const content = DOMPurify.sanitize(this.props.content, {
      ADD_ATTR: ["target"],
    });
    const d = new Date(this.props.date);
    const date = d.toLocaleDateString("fi");

    const componentClass = classNames({
      "c-message": true,
      "is-expanded": this.state.isExpanded,
      "is-read": this.props.isRead,
    });

    return (
      <div className={componentClass} aria-expanded={this.state.isExpanded}>
        <button className="c-message__header" onClick={this.handleClick}>
          <h2>{this.props.title}</h2>
          <p className="c-message__date">{date}</p>
        </button>
        <div
          className="c-message__content h-std"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    );
  }
}

Message.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.string,
  actions: PropTypes.object,
  isRead: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        markMessageRead,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);
