import React, { useEffect, useState } from "react";

import { Barbershop, RenderComponent } from "../../types";
import { sortByCity } from "./utils";

interface BarbershopListProps {
  className?: string;
  itemClassName?: string;
  barbershops: Barbershop[];
  loader?: React.ReactElement;
  render: (barbershop: Barbershop, index?: number) => RenderComponent;
}

const BarbershopList: React.FC<BarbershopListProps> = ({
  className,
  itemClassName,
  barbershops,
  loader,
  render,
}) => {
  const hasData = !!(barbershops && barbershops.length);
  const [sortedBarbershops, setSortedBarbershops] = useState(barbershops);

  useEffect(() => {
    if (barbershops && barbershops.length) {
      setSortedBarbershops([...barbershops].sort(sortByCity));
    }
  }, [barbershops]);

  return (
    <div className={className}>
      {!hasData
        ? loader
        : sortedBarbershops.map((barbershop) => {
            return (
              <div key={barbershop.id} className={itemClassName}>
                {render(barbershop)}
              </div>
            );
          })}
    </div>
  );
};

export default BarbershopList;
