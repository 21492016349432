import React, { useCallback, useState, useMemo } from "react";
import classNames from "classnames";
import { Translate, withLocalize } from "react-localize-redux";
import { AnimatePresence } from "framer-motion";

import { Benefit, PageProps } from "../types";
import { sortByIsRedeemable } from "../features/bonus/utils";
import { useProfile } from "../features/user/use-profile";
import BenefitButton, {
  BenefitButtonLoader,
} from "../features/bonus/BenefitButton";
import SliderGroup from "../components/SliderGroup";
import Slider from "../components/Slider";
import BonusCounter from "../features/bonus/BonusCounter";
import BenefitNotice from "../features/bonus/BenefitNotice";
import Modal from "../components/Modal";
import PageHeader from "../components/PageHeader";

interface BonusSystemProps extends PageProps {}

const BonusSystem: React.FC<BonusSystemProps> = () => {
  /**
   * Bonus data
   */
  const { profile, loading } = useProfile();
  const { bonus } = profile || {};
  const { amount, expirationDate, benefits } = bonus || {};
  const hasBenefits = !!(benefits && benefits.length);

  const sortedBenefits: Benefit[] = useMemo(() => {
    if (!hasBenefits) {
      return [];
    }

    return benefits.sort((a, b) => sortByIsRedeemable(a, b));
  }, [hasBenefits, benefits]);

  /**
   * Modal
   */
  const [redeemBenefit, setRedeemBenefit] = useState<Benefit | null>(null);
  const [showRedeemModal, setShowRedeemModal] = useState(false);

  const handleBenefitClick = useCallback((benefit) => {
    setRedeemBenefit(benefit);
    setShowRedeemModal(true);
  }, []);

  /**
   * Classnames
   */
  const componentClass = classNames("s-bonus-system", {
    "h-scrollable": !showRedeemModal,
  });

  return (
    <div className={componentClass}>
      <div className="s-bonus-system__wrapper">
        <PageHeader title={<Translate id="title.bonus" />} />

        <div className="s-bonus-system__counter">
          <BonusCounter amount={amount} expirationDate={expirationDate} />
        </div>

        <div className="s-bonus-system__benefits">
          <SliderGroup
            title={<Translate id="benefits.title" />}
            description={<Translate id="benefits.description" />}
          >
            <Slider
              slidesPerView={2.1}
              loading={loading}
              data={sortedBenefits}
              renderItem={(benefit) => (
                <BenefitButton
                  {...benefit}
                  onClick={() => handleBenefitClick(benefit)}
                />
              )}
              loaderItem={<BenefitButtonLoader />}
              noDataAvailable={
                <BenefitNotice text={<Translate id="benefits.noBenefits" />} />
              }
            />
          </SliderGroup>
        </div>
      </div>

      <AnimatePresence>
        {showRedeemModal && (
          <Modal>
            <div onClick={() => setShowRedeemModal(false)}>
              {redeemBenefit?.title}
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default withLocalize(BonusSystem);
