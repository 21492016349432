import classNames from "classnames";
import React from "react";

import { TranslateString } from "../types";

interface CheckboxProps {
  label: TranslateString;
  value: boolean;
  onChange: () => void;
  align?: "left" | "center";
  size?: "regular" | "large";
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  value,
  onChange,
  align = "center",
  size = "regular",
}) => {
  const componentClass = classNames(
    "c-checkbox",
    `is-align-${align}`,
    `is-size-${size}`
  );

  return (
    <label className={componentClass}>
      <input
        type="checkbox"
        className="c-checkbox__input"
        checked={value}
        onChange={onChange}
      />
      <span className="c-checkbox__label">{label}</span>
    </label>
  );
};

export default Checkbox;
