import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

interface ShowAndHideProps {
  children: React.ReactNode;
  delay?: number;
  length?: number;
  onShow?: () => void;
  onHide?: () => void;
}

const ShowAndHide: React.FC<ShowAndHideProps> = ({
  delay,
  length,
  onShow,
  onHide,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(delay ? false : true);
  const [hasBeenVisible, setHasBeenVisible] = useState(isVisible);

  /**
   * Make notice visible after delay if it's been set
   */
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (delay) {
      timeout = setTimeout(() => {
        setIsVisible(true);
        setHasBeenVisible(true);
      }, delay);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delay]);

  /**
   * Hide notice if a length has been set
   */
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (isVisible && length) {
      timeout = setTimeout(() => {
        setIsVisible(false);
      }, length);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isVisible, length]);

  /**
   * Trigger callbacks if they've been set
   */
  useEffect(() => {
    if (isVisible && onShow) {
      onShow();
    }

    if (hasBeenVisible && !isVisible && onHide) {
      onHide();
    }
  }, [isVisible, hasBeenVisible, onShow, onHide]);

  return <AnimatePresence>{isVisible && children}</AnimatePresence>;
};

export default ShowAndHide;
