import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { syncUserProfile } from "../../ducks/user";
import { getActiveOrderIdFromProfile } from "../../services/user";
import { RootState } from "../../store";
import { Loading } from "../../types";
import usePageVisibility from "../../utils/use-page-visibility";

interface ActiveOrderId {
  orderId: null | number;
  loading: Loading;
  refresh: () => void;
}

// @todo Update User logic this so that this is not needed
const selectIsProfileSyncing = (state: RootState) =>
  state.user.profileIsSyncing;

export const useActiveOrderId = (): ActiveOrderId => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<Loading>("idle");
  const [orderId, setOrderId] = useState(null);
  const isProfileSyncing = useSelector(selectIsProfileSyncing);

  const updateProfile = useCallback(
    (visible: boolean) => {
      if (visible) {
        setLoading("pending");
        dispatch(syncUserProfile());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (loading === "pending" && !isProfileSyncing) {
      const id = getActiveOrderIdFromProfile();
      setOrderId(id);
      setLoading("fulfilled");
    }
  }, [loading, isProfileSyncing]);

  usePageVisibility(updateProfile);

  const refresh = useCallback(() => updateProfile(true), [updateProfile]);

  return { orderId, loading, refresh };
};
