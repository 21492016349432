import React from "react";
import PropTypes from "prop-types";
import * as helpers from "../utils/Helpers";
import { withLocalize, Translate } from "react-localize-redux";

class Receipt extends React.Component {
  generateServices(services) {
    return services.map((service, index) => {
      const units = helpers.priceToUnits(service.price);

      return (
        <li className="c-receipt__item" key={index}>
          <p className="c-receipt__item__amount">{service.amount + "x"}</p>
          <h4 className="c-receipt__item__title">{service.name}</h4>
          <p className="c-receipt__item__price">{units}</p>
        </li>
      );
    });
  }

  generateTotals(services) {
    let sums = {
      price: 0,
      vat_amount: 0,
      vat: 0,
    };

    services.forEach((service) => {
      sums.price += service.price;
      sums.vat_amount += service.vat_amount;

      // handle VAT percentage (hopefully it's the same for all services on the bill)

      if (sums.vat === 0 && service.vat) {
        sums.vat = service.vat; // set VAT % of the entire order
      }
      if (sums.vat && service.vat && sums.vat !== service.vat) {
        // we have mixed VATs, can't use the percent for the whole order
        sums.vat = -1;
      }
    });

    return sums;
  }

  render() {
    const services = this.generateServices(this.props.receipt.rows);
    const totals = this.generateTotals(this.props.receipt.rows);

    const dt = new Date(this.props.receipt.date);
    const dateString = `${dt.getDate()}.${
      dt.getMonth() + 1
    }.${dt.getFullYear()}, ${this.props.translate(
      "general.timePrefix"
    )} ${dt.getHours()}:${(dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes()}`;

    const totals_vat =
      totals.vat && totals.vat !== -1
        ? totals.vat + "%"
        : helpers.priceToUnits(totals.vat_amount);

    return (
      <div className="c-receipt">
        <h5 className="c-receipt__orderid">
          {`${this.props.translate("orderHistory.orderId")} ${
            this.props.receipt.orderId
          }`}
        </h5>

        <div className="c-receipt__time">
          <p className="h-text-small">{dateString}</p>
        </div>

        {this.props.receipt.barbershop && (
          <div className="c-receipt__pob">
            <h3>{this.props.receipt.barbershop.name}</h3>
            <p className="h-text-small">
              {this.props.receipt.barbershop.address},{" "}
              {this.props.receipt.barbershop.city}
            </p>
          </div>
        )}

        <h3>
          <Translate id="orderHistory.orderServices" />
        </h3>
        <ul className="c-receipt__items">{services}</ul>

        <div className="c-receipt__total">
          <h3>
            <Translate id="orderHistory.orderTotal" />
          </h3>
          <p>
            {helpers.priceToUnits(totals.price)}

            {Boolean(totals.price) && (
              <span className="c-receipt__total__vat">
                {`${this.props.translate(
                  "orderHistory.orderVAT"
                )} ${totals_vat}`}
              </span>
            )}
          </p>
        </div>
      </div>
    );
  }
}

Receipt.propTypes = {
  translate: PropTypes.func,
  receipt: PropTypes.object,
};

export default withLocalize(Receipt);
