import ApiConstants from "../constants/api.js";
import * as helpers from "../utils/Helpers";
import { analytics } from "../utils/Analytics";
import { getUserId } from "../utils/api.js";
import { api } from "./api";

const {
  shouldMockBonusData,
  generateMockBonusData,
  shouldMockProfileData,
  generateMockProfileData,
} = helpers;

/**
 * Fetches the user profile from the API
 *
 * @return {Promise.<object, Error>} Resolves with user data from the API, rejects with errors
 */
export function getProfile() {
  return new Promise((resolve, reject) => {
    const id = getUserId();

    if (!id) {
      reject("User not found");
      return;
    }

    api
      .get(`/customers/${id}/`)
      .then((response) => {
        const data = shouldMockProfileData()
          ? generateMockProfileData()
          : shouldMockBonusData()
          ? generateMockBonusData(response.data)
          : response.data;

        resolve(helpers.updateLocalStorage(data, "profile"));
      })
      .catch((error) => {
        reject({ token: true });
      });
  });
}

/**
 * Send updated user profile settings to API
 *
 * @param {object} data - Object containing the changed fields
 * @return {Promise.<object, Error>} Resolves by updating local storage, rejects with errors
 */

export function patchProfile(data) {
  return new Promise((resolve, reject) => {
    const id = getUserId();

    if (!id) {
      reject("User not found");
      return;
    }

    api
      .patch(`/customers/${id}/`, data)
      .then(() => {
        resolve(helpers.updateLocalStorage(data, "profile"));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Disable the tutorial for the user
 *
 * @return {Promise.<function, Error>} Resolves by updating the local user, rejects with errors
 */
export function disableTutorial() {
  return patchProfile({ tutorial_seen: true });
}

/**
 * Change authenticated user's password
 *
 * @return {Promise.<function, Error>} Resolves by updating the local user, rejects with errors
 */
export function changePassword(oldPassword, newPassword) {
  return new Promise((resolve, reject) => {
    const data = {
      new_password: newPassword,
      old_password: oldPassword,
    };

    api
      .post("/password/", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

/**
 * Update push notifications status in localStorage
 */
export function enablePushNotifications() {
  localStorage.setItem("pushEnabled", true);
}

/**
 * Disable push notifications for user (localStorage)
 */
export function disablePushNotifications() {
  localStorage.setItem("pushEnabled", false);
}

/**
 * Delete the FCM token
 */
export function deleteFcmToken() {
  localStorage.setItem("pushEnabled", false);
}

/**
 * Set a flag that the user has seen the welcome screens
 */

export function setWelcomeSeen() {
  return new Promise((resolve, reject) => {
    resolve(localStorage.setItem("welcomeSeen", true));
  });
}

/**
 * Toggles a barbershop from favorites.
 */
export function toggleFavoriteBarbershop(barbershop) {
  return new Promise((resolve, reject) => {
    const data = JSON.parse(localStorage.getItem("favoriteBarbershops")) || [];

    let favorites = data;

    // find if items is already in favorites
    const index = favorites.findIndex((item) => item === barbershop.id);

    // add or remove from favorites array
    if (index < 0) {
      favorites.push(barbershop.id);
      analytics.addToFavorites(barbershop);
    } else {
      favorites = favorites.filter((item) => item !== barbershop.id);
      analytics.removeFromFavorites(barbershop);
    }

    localStorage.setItem("favoriteBarbershops", JSON.stringify(favorites));

    resolve(favorites);
  });
}

/**
 * Sets the API locale.
 */
export function setLocale(locale) {
  localStorage.setItem("locale", locale);
}

/**
 * Gets the API locale.
 */
export function getLocale() {
  return localStorage.getItem("locale") || ApiConstants.DEFAULT_LOCALE;
}

/**
 * Gets the API locale name
 */
export function getCurrentLocaleName(fallback) {
  const locale = getLocale();

  let name;

  switch (locale) {
    case "FI":
      name = "Finland";
      break;
    case "NO":
      name = "Norway";
      break;
    case "DK":
      name = "Denmark";
      break;
    case "DE":
      name = "Germany";
      break;
    case "SE":
      name = "Sweden";
      break;
    case "US":
      name = "United States";
      break;
    case "EE":
      name = "Estonia";
      break;
    default:
      name = fallback;
  }

  return name;
}

/**
 * Get unit system
 */
export function getUnitSystem() {
  const locale = getLocale();
  return locale === "US" ? "imperial" : "metric";
}

/**
 * Get user profile from localStorage
 */
export function getLocalProfile() {
  return JSON.parse(localStorage.getItem("profile"));
}

/**
 * Get active order id from profile
 */
export function getActiveOrderIdFromProfile() {
  const profile = getLocalProfile();
  return profile?.active_order_id;
}

/**
 * Handle getting and setting user geolocation data
 */
export const getUserLatitude = () => {
  return Number(localStorage.getItem("userLatitude"));
};

export const getUserLongitude = () => {
  return Number(localStorage.getItem("userLongitude"));
};

export const setUserLatitude = (lat) => {
  localStorage.setItem("userLatitude", lat);
};

export const setUserLongitude = (lng) => {
  localStorage.setItem("userLongitude", lng);
};

export const clearUserLocation = () => {
  localStorage.removeItem("userLatitude");
  localStorage.removeItem("userLongitude");
};
