import { useEffect } from "react";

export const useScrollHandler = (
  handleScroll: (e: Event) => void,
  scrollRef?: React.RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    const wrapperEl = scrollRef?.current || null;

    if (wrapperEl) {
      wrapperEl.addEventListener("scroll", handleScroll, false);
    }

    return () => {
      if (wrapperEl) {
        wrapperEl.removeEventListener("scroll", handleScroll, false);
      }
    };
  }, [handleScroll, scrollRef]);
};
