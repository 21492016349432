import * as helpers from "../utils/Helpers";
import * as barbershopService from "../services/barbershop";
import ApiConstants from "../constants/api";
import barbershopConstants from "../constants/barbershop";
import { searchBarbershops as utilSearchBarbershops } from "../features/barbershop/utils";

/**
 * Actions
 */
export const FETCH_BARBERSHOPS_REQUEST = "FETCH_BARBERSHOPS_REQUEST";
export const FETCH_BARBERSHOPS_SUCCESS = "FETCH_BARBERSHOPS_SUCCESS";
export const FETCH_BARBERSHOPS_FAILURE = "FETCH_BARBERSHOPS_FAILURE";
export const FETCH_BARBERSHOPS_CACHED = "FETCH_BARBERSHOPS_CACHED";

export const GET_BARBERSHOP_DISTANCES = "GET_BARBERSHOP_DISTANCES";

export const SORT_BARBERSHOPS = "SORT_BARBERSHOPS";

export const SEARCH_BARBERSHOPS = "SEARCH_BARBERSHOPS";

export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const CLEAR_BARBERSHOPS = "CLEAR_BARBERSHOPS";

export const TOGGLE_MAP_EXPANDED = "TOGGLE_MAP_EXPANDED";

/**
 * Action creators
 */
export function searchBarbershops(query) {
  return { type: SEARCH_BARBERSHOPS, query };
}

export function clearSearch(query) {
  return { type: CLEAR_SEARCH };
}

// setup a cache for refreshing the barbershops
let fetched = false;

export function fetchBarbershops(refreshing = false) {
  // check if enough time has passed since the last barbershops fetch
  const currentTime = new Date();
  const cacheExpired =
    typeof fetched.getTime === "function"
      ? Math.abs(fetched.getTime() - currentTime.getTime()) >
        ApiConstants.BARBERSHOPS_FETCH_CACHE
      : true;

  if (cacheExpired) {
    return function (dispatch) {
      dispatch(request());

      barbershopService.getBarbershops().then(
        (barbershops) => {
          dispatch(success(barbershops));

          // set cache timestamp
          fetched = new Date();
        },
        (errors) => {
          dispatch(failure(errors));
        }
      );
    };
  } else {
    return function (dispatch) {
      dispatch(request());

      setTimeout(() => {
        dispatch(cached());
      }, 500);
    };
  }
  function cached() {
    return { type: FETCH_BARBERSHOPS_CACHED };
  }
  function request() {
    return {
      type: FETCH_BARBERSHOPS_REQUEST,
      payload: {
        refreshing,
      },
    };
  }
  function success(json) {
    return {
      type: FETCH_BARBERSHOPS_SUCCESS,
      barbershops: json,
    };
  }
  function failure(error) {
    return { type: FETCH_BARBERSHOPS_FAILURE, error };
  }
}

export function sortBarbershops(sortBy) {
  return { type: SORT_BARBERSHOPS, sortBy };
}

export function clearBarbershops() {
  return { type: CLEAR_BARBERSHOPS };
}

export function getBarbershopDistances(origin) {
  return { type: GET_BARBERSHOP_DISTANCES, origin };
}

export function toggleMapExpanded() {
  return { type: TOGGLE_MAP_EXPANDED };
}

/**
 * Initial state of the store
 */
export const initialState = {
  barbershops: [],

  query: "",
  sortBy: "queue",

  loading: true,
  distancesCalculated: false,
  mapExpanded: false,
};

/**
 * Reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BARBERSHOPS_REQUEST: {
      const y = Object.assign({}, state);

      if (!action.payload.refreshing) {
        y.loading = true;
      }

      return y;
    }

    case FETCH_BARBERSHOPS_SUCCESS: {
      const y = Object.assign({}, state);

      y.barbershops = action.barbershops;
      y.distancesCalculated = false;
      y.loading = false;

      return y;
    }

    case FETCH_BARBERSHOPS_CACHED: {
      const y = Object.assign({}, state);

      y.loading = false;

      return y;
    }

    case FETCH_BARBERSHOPS_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;

      return y;
    }

    case SORT_BARBERSHOPS: {
      const y = Object.assign({}, state);

      y.sortBy = action.sortBy;

      return y;
    }

    case SEARCH_BARBERSHOPS: {
      const y = Object.assign({}, state);

      y.query = action.query;

      return y;
    }

    case GET_BARBERSHOP_DISTANCES: {
      const y = Object.assign({}, state);

      y.barbershops = y.barbershops.map((barbershop, index) => {
        const distance = helpers.calculateDistance(
          action.origin,
          barbershop.coords
        );

        if (!isNaN(distance)) barbershop.distance = distance;

        return barbershop;
      });

      y.distancesCalculated = true;

      return y;
    }

    case CLEAR_SEARCH: {
      const y = Object.assign({}, state);

      y.query = initialState.query;

      return y;
    }

    case CLEAR_BARBERSHOPS: {
      let y = Object.assign({}, state);

      y = initialState;

      return y;
    }

    case TOGGLE_MAP_EXPANDED: {
      let y = Object.assign({}, state);

      y.mapExpanded = !state.mapExpanded;

      return y;
    }

    default:
      return state;
  }
}

/**
 * Selector to return state only
 */
export const filterBarbershops = (state) => {
  // create a new copy of barbershops to mutate
  let barbershops = [...state.barbershops];

  // filter out tests unless devMode is on
  if (!helpers.isDevMode()) {
    barbershops = barbershops.filter((item) => {
      return !ApiConstants.MOCK_BARBERSHOP_NAMES.includes(item.name);
    });
  }

  // search query
  if (state.query !== "") {
    barbershops = utilSearchBarbershops(state.query, barbershops);
  }

  // sort the barbershops
  if (state.sortBy === "distance") {
    barbershops = barbershops.sort((a, b) => {
      return a.distance - b.distance;
    });
  } else if (state.sortBy === "queue") {
    barbershops = barbershops.sort((a, b) => {
      // first prioritize if the queue is open or not
      const queueAllowed = barbershopConstants.queueStates.ALLOW;

      if (
        a.queueState !== b.queueState &&
        (a.queueState === queueAllowed || b.queueState === queueAllowed)
      ) {
        return a.queueState === queueAllowed ? -1 : 1;
      }

      // DEPRECATED
      // if (a.open !== b.open) {
      //     return a.open === b.open ? 0 : a.open ? -1 : 1;
      // }

      // then prioritize shops that have wait times declared
      if (a.queue === null) {
        return 1;
      } else if (b.queue === null) {
        return -1;
      }

      // finally sort by queue times
      return a.queue - b.queue;
    });
  }

  return barbershops;
};
