import * as APINormalizer from "../utils/APINormalizer";
import ApiConstants from "../constants/api";
import { api } from "./api";
import { isDevMode } from "../utils/Helpers";

const { MOCK_BARBERSHOP_NAMES } = ApiConstants;

/**
 * Fetch the compact barbershop data from the list endpoint
 *
 * @return {Promise.<object, Error>} Resolves with barbershop data, rejects with errors
 */
export const getBarbershops = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/pobs/")
      .then((response) => {
        const results = APINormalizer.barbershopsResponse(response.data);

        /**
         * The API response contains one or several test stores
         * meant only for development. We need to filter them out
         * from the public facing lists.
         */
        const publicResults = isDevMode()
          ? results
          : results.filter(({ name }) => !MOCK_BARBERSHOP_NAMES.includes(name));

        resolve(publicResults);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetch the extended barbershop data from the shop endpoint
 *
 * @param {integer} id - Id of the barbershop
 * @return {Promise.<object, Error>} Resolves with barbershop data, rejects with errors
 */

export function getBarbershop(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/pobs/${id}/`)
      .then((response) => {
        resolve(APINormalizer.barbershopResponse(response.data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
