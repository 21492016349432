import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

import { Barbershop } from "../../types";
import { searchBarbershops } from "./utils";

interface BarbershopSearchResults {
  results: Barbershop[];
  hasResults: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  hasQuery: boolean;
}

export const useBarbershopSearch = (
  barbershops: Barbershop[],
  debounceDelay: number = 500
): BarbershopSearchResults => {
  const [results, setResults] = useState(barbershops);
  const hasResults = !!(results && results.length);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, debounceDelay);
  const hasQuery = debouncedQuery.length > 0;

  useEffect(() => {
    const queryResult = searchBarbershops(debouncedQuery, barbershops);
    setResults(queryResult);
  }, [barbershops, debouncedQuery]);

  return { results, hasResults, query, setQuery, hasQuery };
};
