import React from "react";
import PropTypes from "prop-types";
import RouteLink from "./RouteLink";

const BackButton = (props) => {
  const backPath = window.previousLocation
    ? window.previousLocation.pathname
    : "/login";

  return (
    <RouteLink to={backPath} transition="switch-close">
      <div className="c-back-button">Back</div>
    </RouteLink>
  );
};

BackButton.propTypes = {
  transition: PropTypes.string,
};

export default BackButton;
