import React, { useEffect, useCallback } from "react";
import { TranslateFunction } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";

import {
  editOrder,
  selectOrderError,
  selectOrderErrorMessage,
} from "./orderSlice";
import { analytics } from "../../utils/Analytics";
import { showNotification } from "../../ducks/notification";
import { getAnalyticsOptions } from "./utils";

interface OrderErrorHandlerProps {
  id: number;
  name: string;
  translate: TranslateFunction;
}

const OrderErrorHandler: React.FC<OrderErrorHandlerProps> = ({
  id,
  name,
  translate,
}) => {
  const dispatch = useDispatch();

  const error = useSelector(selectOrderError);
  const errorMessage = useSelector(selectOrderErrorMessage);

  const throwTimeSlotError = useCallback(
    () =>
      dispatch(
        showNotification(
          translate("error.timeslotTitle"),
          translate("error.timeslotText"),
          5000
        )
      ),
    [translate, dispatch]
  );

  const throwUnknownError = useCallback(
    () =>
      dispatch(
        showNotification(
          translate("error.sendOrderTitle"),
          `${translate("error.sendOrderText")} ${translate(
            "error.messagePrefix"
          )} ${errorMessage}`
        )
      ),
    [translate, dispatch, errorMessage]
  );

  useEffect(() => {
    if (error === "timeslot") {
      dispatch(editOrder());
      throwTimeSlotError();
      analytics.error("timeSlot", getAnalyticsOptions(id, name));
    }
  }, [dispatch, error, id, name, throwTimeSlotError]);

  useEffect(() => {
    if (error === "unknown") {
      dispatch(editOrder());
      throwUnknownError();
      analytics.error("sendOrder", getAnalyticsOptions(id, name));
    }
  }, [dispatch, error, errorMessage, id, name, throwUnknownError]);

  return null;
};

export default OrderErrorHandler;
