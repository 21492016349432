import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withLocalize } from "react-localize-redux";

import { selectUserLocale } from "../ducks/user";
import { PageProps } from "../types";
import { analytics } from "../utils/Analytics";
import { setPageTitle } from "../utils/seo";
import BonusSystem from "./BonusSystem";
import BonusTeaser from "./BonusTeaser";
import bonusConfig from "../config/bonus.json";
import { BonusConfig } from "../config/types";
import RouteRedirect from "../components/RouteRedirect";

interface BonusProps extends PageProps {}

const Bonus: React.FC<BonusProps> = (props) => {
  const { activeLanguage, translate } = props;

  const { localesWithBonus, localesWithBonusTeaser } =
    bonusConfig as BonusConfig;
  const locale = useSelector(selectUserLocale);

  /**
   * SEO & Analytics
   */
  useEffect(() => {
    if (activeLanguage) {
      setPageTitle([translate("title.base"), translate("title.bonus")]);
    }
  }, [activeLanguage, translate]);

  useEffect(() => {
    analytics.pageView("/bonus", "Bonus");
  }, []);

  return localesWithBonus.includes(locale) ? (
    <BonusSystem />
  ) : localesWithBonusTeaser.includes(locale) ? (
    <BonusTeaser />
  ) : (
    <RouteRedirect to={"/home"} transition="slide-left" />
  );
};

export default withLocalize(Bonus);
