import md5 from "md5";
import ApiConstants from "../constants/api";
import * as helpers from "./Helpers";
import * as userService from "../services/user";

const defaults = {
  marketCountry: "Finland",
  currencyCode: "EUR",
  language: "fi",
};

const SERVICE_BRAND = "M ROOM";
const EXTRA_SERVICE_SUFFIX = "Extra";
const { FIXED_SERVICE_AMOUNT } = ApiConstants;

/**
 * Get analytics price from apps price
 *
 * @param {number} price
 * @returns {number}
 */
function formatPrice(price) {
  return price / 100;
}

/**
 * Convert minutes to hh:mm format
 *
 * @param {number} mins - Number of minutes
 * @return {string} Formatted hours / minutes string
 */

function formatMinutes(mins) {
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;

  hours = hours < 0 ? "00" : hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 0 ? "00" : minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
}

/**
 * Format date to YYYY-MM-DD
 *
 * @param {object} date - JavaScript Date object
 * @return {string} Formatted date string
 */

function formatDate(date) {
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

/**
 * Format user language
 *
 * @param {string} code - Language code
 * @return {string} Name of the language
 */

function formatLanguage(code) {
  switch (code) {
    case "fi":
      return "Finnish";
    case "en":
      return "English";
    default:
      return code;
  }
}

/**
 * Format the GTM actionField id
 *
 * @param {object} barbershop - Barbershop data
 * @param {string} date - Order date in string format
 * @return {string} actionfield id string
 */

function formatActionFieldId(barbershop = {}) {
  const dt = new Date();
  const timestamp = dt.getTime();
  const dateFormatted = formatDate(dt);

  return `${barbershop.country}|${barbershop.city}|${barbershop.name}|${dateFormatted}|${timestamp}`;
}

/**
 * Format the order products
 *
 * @param {array} serviceGroups - array of service group data
 * @param {array} services - The user order in the app's syntax
 * @param {object} extraServices - Extra services as servideId indexed arrays
 * @return {array} GA ecommerce compatible product format
 */
function formatProducts(serviceGroups, services, extraServices) {
  const serviceArray = services.map((service) => {
    const group = serviceGroups.find((group) => group.id === service.groupId);
    return formatService(service, group);
  });

  const extraServiceArray = Object.keys(extraServices).reduce(
    (result, serviceId) => {
      const service = services.find(
        (service) => service.id.toString() === serviceId.toString()
      );

      const extraServicesOfService = extraServices[serviceId].map(
        (extraService) => formatExtraService(extraService, service)
      );

      return [...result, ...extraServicesOfService];
    },
    []
  );

  return [...serviceArray, ...extraServiceArray];
}

/**
 * Format a service
 *
 * @param {object} service
 * @param {object} serviceGroup
 * @returns {object}
 */
function formatService(service, group) {
  const { id, title, price } = service;
  const category = group ? group.title : "";

  return {
    name: title,
    id,
    category,
    brand: SERVICE_BRAND,
    price: formatPrice(price),
    quantity: FIXED_SERVICE_AMOUNT,
  };
}

/**
 * Format an extra service
 *
 * @param {object} extraService
 * @param {object} service
 * @returns {object}
 */
function formatExtraService(extraService, service) {
  const { id, title, price } = extraService;
  const category = service ? `${service.title}/${EXTRA_SERVICE_SUFFIX}` : "";

  return {
    name: title,
    id,
    category,
    brand: SERVICE_BRAND,
    price: formatPrice(price),
    quantity: FIXED_SERVICE_AMOUNT,
  };
}

/**
 * Calculate the order sum
 *
 * @param {array} services
 * @param {object} extraServices
 * @returns {number}
 */
function calculateOrderSum(services, extraServices) {
  const serviceSum = helpers.getServicePriceSum(services);

  const extraServiceSum = Object.keys(extraServices).reduce(
    (sum, serviceId) => {
      sum += helpers.getServicePriceSum(extraServices[serviceId]);
      return sum;
    },
    0
  );

  return formatPrice(serviceSum + extraServiceSum);
}

/**
 * Very generic event helper
 *
 * @param {string} label - Event label
 */

function genericEvent(label) {
  push({
    event: label,
  });
}

/**
 * Helper function for the pageview event
 *
 * @param {string} path - String of the page path
 * @param {string} title - String of the page Title
 */

function pageView(path, title) {
  push({
    event: "pageView",
    marketCountry: userService.getCurrentLocaleName(defaults.marketCountry),
    page: {
      path: path,
      title: title,
    },
  });
}

/**
 * Handling of userData event
 *
 * @param {object} profile - Object containing the user profile
 * @param {object} coords - User coordinates
 * @param {string} language - String with language code
 */

function userData(profile, coords, language) {
  if (typeof profile === "undefined" || profile === null) return false;

  const isMember = profile && profile.membership && profile.membership.type;
  const type = isMember ? profile.membership.type : "Not a member";
  const beginDate = isMember ? profile.membership.begin_date : null;
  const endDate = isMember ? profile.membership.end_date : null;
  const credits = isMember ? profile.membership.credits.toString() : null;
  const userId = typeof profile.phone !== "undefined" ? md5(profile.phone) : "";
  const userLanguage = language !== null ? language : defaults.language;
  const pushNotificationsEnabled = localStorage.getItem("pushEnabled");
  const geolocationEnabled =
    coords && coords.latitude && coords.longitude ? true : false;

  push({
    event: "userData",
    userData: {
      userId: userId,
      userLanguage: formatLanguage(userLanguage),
      userCity: profile.city,
      membershipLevel: type,
      membershipBeginDate: beginDate,
      membershipEndDate: endDate,
      creditsLeft: credits,
      userEmailPermit: profile.allow_email ? "yes" : "no",
      smsNotificationsEnabled: profile.allow_sms,
      pushNotificationsEnabled:
        pushNotificationsEnabled === "true" ? true : false,
      geolocationEnabled: geolocationEnabled,
      // locationData: {} // currently not supported
      // purchaseHistory: [] // currently not supported
    },
  });
}

/**
 * Handling the store selection from various sources
 *
 * @param {object} barbershop - Object containing the barbershop information
 * @param {string} source - Source where the event is called from
 */

function storeSelected(barbershop = {}, source) {
  const distance =
    typeof barbershop.distance !== "undefined" ? barbershop.distance : null;

  push({
    event: "storeSelected",
    storeData: {
      selectedFrom: source,
      storeName: barbershop.name,
      storeCity: barbershop.city,
      storeCountry: barbershop.country,
      storeAddress: barbershop.address,
      distanceToStore: distance,
      storeOpeningHours: barbershop.openingHours,
      queueLengthApproximation: formatMinutes(barbershop.queue),
    },
  });
}

/**
 * Handle the order received event, when the user confirms the order
 *
 * @param {object} barbershop
 * @param {array} services
 * @param {object} extraServices
 */
function queueConfirmation(barbershop = {}, services, extraServices) {
  const { name, city, country, address, openingHours, queue, serviceGroups } =
    barbershop || {};

  push({
    event: "queueConfirmation",
    storeData: {
      storeName: name,
      storyCity: city,
      storeCountry: country,
      storeAddress: address,
      storeOpeningHours: openingHours,
      queueLengthEstimation: formatMinutes(queue),
    },
    ecommerce: {
      currencyCode: helpers.getCurrencyCode(userService.getLocale()),
      purchase: {
        actionField: {
          id: formatActionFieldId(barbershop),
          affiliation: `${country}|${city}|${name}`,
          revenue: calculateOrderSum(services, extraServices),
          tax: 0,
        },
        products: formatProducts(serviceGroups, services, extraServices),
      },
    },
  });
}

/**
 * Helper function for barbershop check-in button click
 *
 * @param {object} barbershop - Object containing the barbershop data
 * @param {number} queue - Queue minutes
 */

function queueCheckin(barbershop = {}, queue) {
  push({
    event: "queueCheckin",
    storeData: {
      storeName: barbershop.name,
      storeCity: barbershop.city,
      storeCountry: barbershop.country,
      storeAddress: barbershop.address,
      queueLengthEstimation: formatMinutes(queue),
    },
  });
}

/**
 * Helper function for the queueQuit event
 *
 * @param {object} barbershop - Object containing the barbershop information
 * @param {object} order - Object containing the order details
 */

function queueQuit(barbershop = {}, order) {
  push({
    event: "queueQuit",
    storeData: {
      storeName: barbershop.name,
      storeCity: barbershop.city,
      storeCountry: barbershop.country,
      storeAddress: barbershop.address,
      queueLengthEstimation: formatMinutes(order.queue),
    },
    ecommerce: {
      currencyCode: helpers.getCurrencyCode(userService.getLocale()),
      refund: {
        actionField: {
          id: formatActionFieldId(barbershop, order.date),
        },
      },
    },
  });
}

/**
 * Helper function for the search event
 *
 * @param {string} term - The search term inputted by the user
 * @param {number} results - The number of search results
 */

function searchStore(term, results = null) {
  push({
    event: "searchStore",
    searchTerm: term,
    searchResults: results,
  });
}

/**
 * Helper function for handling specific error events
 *
 * @param {string} type - Error type
 * @param {object} page - Object containing the page path and title
 */

function error(type, page = {}) {
  push({
    event: "errorMessage",
    errorType: type,
    page: page,
  });
}

/**
 * Helper function for handling generic error messages
 *
 * @param {string} message - Error message
 */

function errorMessage(message) {
  push({
    event: "errorMessage",
    errorMessage: message,
  });
}

/**
 * Helper function for handling adding a barbershop to favorites
 *
 * @param {object} barbershop - Object containing the barbershop information
 */

function addToFavorites(barbershop) {
  push({
    event: "addToFavorites",
    storeData: {
      storeName: barbershop.name,
      storeCity: barbershop.city,
      storeCountry: barbershop.country,
      storeAddress: barbershop.address,
      storeOpeningHours: barbershop.openingHours,
    },
  });
}

/**
 * Helper function for handling removing a barbershop from favorites
 *
 * @param {object} barbershop - Object containing the barbershop information
 */

function removeFromFavorites(barbershop) {
  push({
    event: "removeFromFavorites",
    storeData: {
      storeName: barbershop.name,
      storeCity: barbershop.city,
      storeCountry: barbershop.country,
      storeAddress: barbershop.address,
      storeOpeningHours: barbershop.openingHours,
    },
  });
}

/**
 * Send geolocation data to data layer
 */

function glDataReady(path, title, latitude, longitude, profile) {
  const userId =
    profile && typeof profile.phone !== "undefined" ? md5(profile.phone) : "";

  push({
    event: "glDataReady",
    userId: userId,
    marketCountry: userService.getCurrentLocaleName(defaults.marketCountry),
    page: {
      path: path,
      title: title,
    },
    userCoords: {
      latitude: latitude,
      longitude: longitude,
    },
  });
}

/**
 * Generic helper function for pushing to data layer
 *
 * @param {object} data - Object containing the analytics data
 */
function push(data) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(data);
}

export const analytics = {
  genericEvent,
  pageView,
  userData,
  storeSelected,
  queueConfirmation,
  queueCheckin,
  queueQuit,
  searchStore,
  error,
  errorMessage,
  addToFavorites,
  removeFromFavorites,
  glDataReady,
  push,
  deprecated__queueConfirmation,
};

/**--------------------------------------------------------------------
 * Deprecated
 * ----------------------------------------------------------------- */

/**
 * Handle the order received event, when the user confirms the order
 * @deprecated use queueConfirmation
 */
function deprecated__queueConfirmation(barbershop = {}, order) {
  push({
    event: "queueConfirmation",
    storeData: {
      storeName: barbershop.name,
      storyCity: barbershop.city,
      storeCountry: barbershop.country,
      storeAddress: barbershop.address,
      storeOpeningHours: barbershop.openingHours,
      queueLengthEstimation: formatMinutes(order.queue),
    },
    ecommerce: {
      currencyCode: helpers.getCurrencyCode(userService.getLocale()),
      purchase: {
        actionField: {
          id: deprecated__formatActionFieldId(barbershop, order.date),
          affiliation: `${barbershop.country}|${barbershop.city}|${barbershop.name}`,
          revenue: deprecated__calculateOrderSum(order.services),
          tax: 0,
        },
        products: deprecated__formatProducts(order.services),
      },
    },
  });
}

/**
 * Format the order products
 *
 * @param {array} services - The user order in the app's syntax
 * @return {array} GA ecommerce compatible product format
 * @deprecated use formatProducts
 */
function deprecated__formatProducts(services) {
  const products = services.map((service) => {
    const options = service.options.map((option) => {
      // get all the extra services first
      const extras = option.extras.map((extra) => {
        return {
          name: extra.name,
          id: extra.id,
          category: `${option.name}/Extra`,
          brand: "M ROOM",
          price: extra.price / 100,
          quantity: 1,
        };
      });

      // push the main product option to the same array
      extras.push({
        name: option.name,
        id: option.id,
        category: option.category,
        brand: "M ROOM",
        price: option.price / 100,
        quantity: 1,
      });

      // combine the main product and the extra services
      return [].concat(...extras);
    });

    return [].concat(...options);
  });

  return [].concat(...products);
}

/**
 * Format the GTM actionField id
 *
 * @param {object} barbershop - Barbershop data
 * @param {string} date - Order date in string format
 * @return {string} actionfield id string
 * @deprecated use formatActionFieldId
 */

function deprecated__formatActionFieldId(barbershop = {}, orderDate = "") {
  const dt = new Date(orderDate);
  const timestamp = dt.getTime();
  const dateFormatted = formatDate(dt);

  return `${barbershop.country}|${barbershop.city}|${barbershop.name}|${dateFormatted}|${timestamp}`;
}

/**
 * @deprecated
 */
export function deprecated__calculateOrderSum(selected) {
  // loop through services...
  const sum = selected.reduce((total, service) => {
    // ...which have options...
    const optionPrices = service.options.reduce((optionsTotal, option) => {
      // ...which have extra options...
      const extraPrices = option.extras.reduce((extrasTotal, extra) => {
        return extrasTotal + extra.price;
      }, 0);

      return optionsTotal + option.price + extraPrices;
    }, 0);

    return total + optionPrices;
  }, 0);

  return sum / 100;
}
