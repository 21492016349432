import React from "react";
import ContentLoader from "react-content-loader";

import { loaderTheme } from "../../utils/loaders";

interface ListLabelProps {
  icon?: React.ReactElement;
}

const ListLabel: React.FC<ListLabelProps> = ({ children, icon }) => {
  const hasIcon = !!icon;

  return (
    <div className="c-list-label">
      {hasIcon && <span className="c-list-label__icon">{icon}</span>}
      <span className="c-list-label__text">{children}</span>
    </div>
  );
};

export default ListLabel;

export const ListLabelLoader = () => (
  <div className="c-list-label">
    <ContentLoader
      viewBox="0 0 50 10"
      height={10}
      {...loaderTheme}
      opacity={0.5}
    >
      <rect x="0" y="0" rx="3" ry="3" width="50" height="10" />
    </ContentLoader>
  </div>
);
