import React, { useState, useEffect } from "react";

import { Coords } from "../types";
import { buildStaticMapURL } from "../utils/google-maps";

interface StaticGoogleMapsImageProps {
  className?: string;
  coords: Coords;
  onClick?: () => void;
}

const StaticGoogleMapsImage: React.FC<StaticGoogleMapsImageProps> = ({
  className,
  coords,
  onClick,
  children,
}) => {
  const [staticMapUrl, setStaticMapUrl] = useState("");

  useEffect(() => {
    const url = buildStaticMapURL(coords);
    setStaticMapUrl(url);
  }, [coords]);

  if (!staticMapUrl) {
    return null;
  }

  if (onClick) {
    return (
      <button
        className={className}
        onClick={onClick}
        style={{
          backgroundImage: `url(${staticMapUrl})`,
          display: "block",
          width: "100%",
          border: "none",
          backgroundColor: "#000",
        }}
      >
        {children}
      </button>
    );
  }

  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${staticMapUrl})` }}
    >
      {children}
    </div>
  );
};

export default StaticGoogleMapsImage;
