import firebaseConfig from "./config/firebase.json";

/**
 * @todo See if this whole thing actually works or not.
 */

export default function initFirebase() {
  if (!("PushManager" in window && "firebase" in window)) {
    return false;
  }

  if (typeof navigator.serviceWorker === "undefined") return false;

  window.firebase.initializeApp(firebaseConfig.app);

  navigator.serviceWorker.ready
    .then(() => {
      const messaging = window.firebase.messaging();

      messaging.usePublicVapidKey(firebaseConfig.vapidPublicKey);
    })
    .catch(() => {});
}
