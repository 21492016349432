import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RouteLink from "../components/RouteLink";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import { withLocalize, Translate } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

import { showNotification } from "../ducks/notification";
import {
  syncUserProfile,
  patchProfile,
  logout,
  enablePushNotifications,
  disablePushNotifications,
} from "../ducks/user";

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);

    const firebaseDevMode = localStorage.getItem("firebaseDevMode");

    this.devMode =
      firebaseDevMode && firebaseDevMode !== "0" && firebaseDevMode !== "false"
        ? 1
        : 0;

    this.state = {
      pushSupported: true,
      //pushEnabled: false
    };

    this.titleSet = false;
    this.blockTransition = false;

    this.togglePushNotifications = this.togglePushNotifications.bind(this);
    this.devUnsubscribe = this.devUnsubscribe.bind(this);
    this.devArea = this.devArea.bind(this);
    this.toggleSMS = this.toggleSMS.bind(this);
  }

  componentDidMount() {
    // sync profile from API to make sure data is up to date
    this.props.actions.syncUserProfile();

    // check if push notifications are supported
    if (!("firebase" in window && window.firebase.apps.length)) {
      this.setState({
        pushSupported: false,
      });
    }

    analytics.pageView("/profile/notifications", "Notification Settings");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // if the login has expired, log the user out
    if (!newProps.loggedIn && !this.blockTransition) {
      this.props.actions.logout();
      this.props.actions.showNotification(
        newProps.translate("error.tokenTitle"),
        newProps.translate("error.tokenText")
      );

      this.blockTransition = true;
    }

    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.notificationSettings");
      this.titleSet = true;
    }
  }

  toggleSMS() {
    if (this.props.profile) {
      this.props.actions.patchProfile({
        allow_sms: !this.props.profile.allow_sms,
      });
    }
  }

  togglePushNotifications() {
    if (this.props.pushEnabled) {
      // PUSH IS ENABLED, DISABLE NOTIFICATIONS
      const messaging = window.firebase.messaging();

      messaging
        .getToken()
        .then((fcmToken) => {
          this.props.actions.disablePushNotifications(fcmToken);
        })
        .catch((err) => {
          this.props.actions.disablePushNotifications(false); // do it anyway, just can't delete the token in FCM
        });
    } else {
      // PUSH IS NOT ENABLED, LET'S ASK FOR PERMISSION
      this.props.actions.enablePushNotifications();
    }
  }

  devTokenCheck() {
    const messaging = window.firebase.messaging();

    messaging
      .getToken()
      .then((fcmToken) => {
        //console.log(fcmToken);
      })
      .catch((err) => {
        //console.log("tried to get fcm token, failed.", err);
      });
  }

  devSubscribe() {
    const messaging = window.firebase.messaging();

    messaging
      .requestPermission()
      .then(() => {
        //console.log("subscribe success");
      })
      .catch((err) => {
        //console.log("subscribe failed", err);
      });
  }

  // this is broken now, token not stored in localStorage
  devUnsubscribe() {
    const messaging = window.firebase.messaging();

    const token = this.props.user.fcm_token;

    if (token) {
      messaging
        .deleteToken(token)
        .then(() => {
          //console.log("delete token success");
        })
        .catch((err) => {
          //console.log("delete token failed", err);
        });
    }
  }

  devArea() {
    if (!this.devMode) {
      return "";
    }

    return (
      <div className="s-settings__dev-area">
        <br />
        <h1>DEV</h1>
        <br />
        <p>
          <Button
            onClick={this.devTokenCheck}
            text="check fcm token"
            size="small"
          />
        </p>
        <br />
        <p>
          <Button
            onClick={this.devSubscribe}
            text="subscribe to push notifications"
            size="small"
          />
        </p>
        <br />
        <p>
          <Button
            onClick={this.devUnsubscribe}
            text="unsubscribe push notifications"
            size="small"
          />
        </p>
      </div>
    );
  }

  render() {
    const devArea = this.devArea();
    const profileLoaded = this.props.profile ? true : false;
    const smsAllowed = profileLoaded ? this.props.profile.allow_sms : false;

    const componentClass = classNames({
      "s-settings": true,
      "h-scrollable": true,
      "s-settings--loading": this.props.loading,
    });

    return (
      <div className={componentClass}>
        <div className="s-settings__back">
          <RouteLink to="/profile" transition="switch-close">
            <div className="c-back-button">Back</div>
          </RouteLink>
        </div>

        <div className="s-settings__header">
          <h1 className="s-settings__title">
            <Translate id="notificationSettings.title" />
          </h1>
          <p className="s-settings__subtitle">
            <Translate id="notificationSettings.text" />
          </p>
        </div>

        {profileLoaded && (
          <div className="s-settings__content">
            <h2 className="s-settings__content-title">
              <Translate id="notificationSettings.smsTitle" />
            </h2>
            <p className="s-settings__description">
              {/**
               * Translation disabled per client's request.
               */}
              {/* <Translate id="notificationSettings.smsDescription" />
              <br />
              <br /> */}
              {smsAllowed ? (
                <Translate id="notificationSettings.smsAllowed" />
              ) : (
                <Translate id="notificationSettings.smsDisallowed" />
              )}
            </p>
            <Button
              size="small"
              onClick={this.toggleSMS}
              text={
                smsAllowed
                  ? this.props.translate(
                      "notificationSettings.smsUnsubscribeText"
                    )
                  : this.props.translate(
                      "notificationSettings.smsSubscribeText"
                    )
              }
            />
          </div>
        )}

        {profileLoaded && (
          <div className="s-settings__content">
            <h2 className="s-settings__content-title">
              <Translate id="notificationSettings.pushTitle" />
            </h2>
            <p className="s-settings__description">
              <Translate id="notificationSettings.pushDescription" />
            </p>
            {this.state.pushSupported ? (
              <React.Fragment>
                <p className="s-settings__description">
                  {this.props.pushEnabled ? (
                    <Translate id="notificationSettings.pushAllowed" />
                  ) : (
                    <Translate id="notificationSettings.pushDisallowed" />
                  )}
                </p>
                <Button
                  size="small"
                  onClick={this.togglePushNotifications}
                  text={
                    this.props.pushEnabled
                      ? this.props.translate(
                          "notificationSettings.pushUnsubscribeText"
                        )
                      : this.props.translate(
                          "notificationSettings.pushSubscribeText"
                        )
                  }
                  disabled={this.props.pushChangePending}
                />
              </React.Fragment>
            ) : (
              <p className="s-settings__description">
                <Translate id="notificationSettings.pushUnsupported" />
              </p>
            )}

            {devArea}
          </div>
        )}

        {this.props.loading && (
          <div className="s-settings__spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  pushChangePending: PropTypes.bool,
  pushEnabled: PropTypes.bool,
  translate: PropTypes.func,
  profile: PropTypes.object,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loggedIn: state.user.loggedIn,
    loading: state.user.loading,
    profile: state.user.profile,
    pushChangePending: state.user.pushChangePending,
    pushEnabled: state.user.pushEnabled,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        patchProfile,
        syncUserProfile,
        logout,
        enablePushNotifications,
        disablePushNotifications,
        showNotification,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(NotificationSettings));
