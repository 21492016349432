import React from "react";
import RouteLink from "../components/RouteLink";
import { withLocalize } from "react-localize-redux";

import { analytics } from "../utils/Analytics";

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);

    this.titleSet = false;
  }

  componentDidMount() {
    analytics.pageView(window.location.pathname, "Page not found");
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.pageNotFound");
      this.titleSet = true;
    }
  }

  render() {
    return (
      <div className="s-error">
        <h1>Oh dear, a 404.</h1>
        <p>
          Sorry, we couldn't find that page for you.
          <br />
          <br />
        </p>
        <RouteLink to="/" transition="slide-left">
          <div className="c-btn c-btn--small">Back to home</div>
        </RouteLink>
      </div>
    );
  }
}

export default withLocalize(PageNotFound);
