import { useState, useCallback } from "react";

export const useSessionStorage = (key: string) => {
  const [value, setValue] = useState<string | null>(
    sessionStorage.getItem(key)
  );

  const setAndStoreValue = useCallback(
    (newValue: string) => {
      sessionStorage.setItem(key, newValue);
      setValue(newValue);
    },
    [key, setValue]
  );

  return [value, setAndStoreValue] as const;
};
