import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withLocalize, Translate } from "react-localize-redux";
import * as userService from "../services/user";
import history from "../utils/navigation";

class HelpAccount extends React.Component {
  constructor(props) {
    super(props);

    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleRecoveryClick = this.handleRecoveryClick.bind(this);
  }

  handleRegisterClick() {
    userService.setWelcomeSeen().then(() => {
      this.goToNextScene("/register");
    });
  }

  handleRecoveryClick() {
    userService.setWelcomeSeen().then(() => {
      this.goToNextScene("/recovery");
    });
  }

  goToNextScene(path) {
    history.push({
      pathname: path,
      state: {
        transition: "slide-right",
      },
    });
  }

  render() {
    const componentClass = classNames({
      "c-help-screen": true,
      "c-help-screen--with-links": true,
    });

    return (
      <div className={componentClass}>
        <div className="c-help-screen__links">
          <div
            className="c-help-screen__link"
            onClick={this.handleRegisterClick}
          >
            <h2>
              <Translate
                id="helpAccount.registerTitle"
                options={{ renderInnerHtml: true }}
              />
            </h2>
            <p>
              <Translate
                id="helpAccount.registerDescription"
                options={{ renderInnerHtml: true }}
              />
            </p>
          </div>
          <div
            className="c-help-screen__link"
            onClick={this.handleRecoveryClick}
          >
            <h2>
              <Translate
                id="helpAccount.recoveryTitle"
                options={{ renderInnerHtml: true }}
              />
            </h2>
            <p>
              <Translate
                id="helpAccount.recoveryDescription"
                options={{ renderInnerHtml: true }}
              />
            </p>
          </div>
          {/* <div className="c-help-screen__divider">
            <span>
              <Translate id="general.or" />
            </span>
          </div> */}
        </div>
      </div>
    );
  }
}

HelpAccount.propTypes = {
  translate: PropTypes.func,
};

export default withLocalize(HelpAccount);
