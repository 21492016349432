import PropTypes from "prop-types";

const MinimapLink = ({ name, address, clickHandler }) => {
  const handleClick = () => {
    clickHandler();
  };

  return (
    <div className="c-minimap-link" onClick={handleClick}>
      <h3 className="h2 c-minimap-link__name">{name}</h3>
      <p className="c-minimap-link__address">{address}</p>
      <div className="c-minimap-link__icon" />
    </div>
  );
};

MinimapLink.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  clickHandler: PropTypes.func,
};

export default MinimapLink;
