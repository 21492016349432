import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import { withLocalize } from "react-localize-redux";
import RouteLink from "../components/RouteLink";
import Logo from "../components/Logo";
import LoginForm from "../components/LoginForm";
import history from "../utils/navigation";

import { analytics } from "../utils/Analytics";

import { showNotification } from "../ducks/notification";
import { syncUserProfile, login, logout } from "../ducks/user";
import { clearOrder as deprecated__clearOrder } from "../ducks/deprecated__order";
import { clearTracking } from "../features/tracking/trackingSlice";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";

/**
 * @todo This page should be dedicated to just the Login feature.
 * Create a separate root page for initial route handling.
 */
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: true,
      nextScenePath: false,
    };

    /**
     * @deprecated
     * This functionality has been moved to the InitialRouteSelector.tsx page.
     */
    // this.welcomeSeen =
    //   localStorage.getItem("welcomeSeen") === "true" ? true : false;

    this.blockTransition = false;
    this.forcedLogout = false;
    this.titleSet = false;
    this.pageViewEventSent = false;
    this.statusChecked = false;
    this.locale = localStorage.getItem("locale");

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // Store current location for back buttons
    window.previousLocation = this.props.location;

    /**
     * @deprecated
     * This functionality has been moved to the InitialRouteSelector.tsx page.
     */
    // // information about the password recovery process
    // const recovery = JSON.parse(localStorage.getItem("recovery"));

    // check if user is logged in and that the access token is still valid
    if (this.props.loggedIn) {
      this.props.actions.syncUserProfile();
    }

    /**
     * @deprecated
     * This functionality has been moved to the InitialRouteSelector.tsx page.
     */
    // // check if the user is in the middle of a password recovery process
    // else if (recovery && helpers.isRecoveryTokenValid(recovery)) {
    //   this.goToRecovery();
    // }
    // // take user to welcome screen if they haven't seen it yet
    // else if (!this.welcomeSeen) {
    //   this.goToWelcome();
    // }
    // // finally show the login form
    // else {
    //   this.setState(() => {
    //     return {
    //       showForm: true,
    //     };
    //   });
    // }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.loading) {
      if (newProps.errors.token && !this.forcedLogout) {
        this.throwError("token");
      } else if (newProps.loggedIn && !newProps.profileSynced) {
        this.props.actions.syncUserProfile();
      } else if (
        newProps.loggedIn &&
        newProps.profileSynced &&
        newProps.profile &&
        !this.statusChecked
      ) {
        this.checkUserStatus(newProps);
      } else if (
        !newProps.loggedIn &&
        this.welcomeSeen &&
        !this.state.showForm
      ) {
        this.setState(() => {
          return {
            showForm: true,
          };
        });
      }
    }

    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title = newProps.translate("title.base");
      this.titleSet = true;
    }

    if (!this.props.loggedIn && newProps.loggedIn) {
      analytics.genericEvent("loginSuccessful");
    }
  }

  componentDidUpdate() {
    if (this.props.errors.token && !this.forcedLogout) {
      this.throwError("token");
    }

    if (
      this.props.loggedIn &&
      this.state.nextScenePath &&
      !this.blockTransition
    ) {
      this.changeScene();
    }

    if (this.state.showForm && !this.pageViewEventSent) {
      analytics.pageView("/login", "Login");

      this.pageViewEventSent = true;
    }
  }

  checkUserStatus(props) {
    // clear old orders <- @todo is this needed?
    if (props.profile.active_order_id === null) {
      this.props.actions.deprecated__clearOrder();
      this.props.actions.clearTracking();
    }

    let path;

    if (props.profile.active_order_id) {
      path = "/tracking";
    } else {
      path = "/home";
    }

    this.setState(() => {
      return {
        nextScenePath: path,
      };
    });

    this.statusChecked = true;
  }

  // transition to the next scene
  changeScene() {
    if (this.blockTransition) return false;

    history.push({
      pathname: this.state.nextScenePath,
      search: window.location.search,
      state: {
        transition: "slide-right",
      },
    });

    this.blockTransition = true;
  }

  // go to recovery page
  goToRecovery() {
    if (this.blockTransition) return false;

    history.push({
      pathname: "/recovery",
      state: {
        transition: "slide-right",
      },
    });

    this.blockTransition = true;
  }

  // go to welcome page
  goToWelcome() {
    if (this.blockTransition) return false;

    history.push({
      pathname: "/welcome",
      search: window.location.search,
      state: {
        transition: "slide-right",
      },
    });

    this.blockTransition = true;
  }

  // handle login form submit
  handleSubmit(input) {
    this.props.actions.login(input.phone, input.password, input.rememberLogin);
  }

  // handle errors and notifications
  throwError(type) {
    if (type === "status") {
      this.forcedLogout = true;
      this.props.actions.logout();
      this.props.actions.showNotification(
        this.props.translate("error.statusTitle"),
        this.props.translate("error.statusText"),
        5000
      );

      analytics.error("loginStatus", {
        path: "/login",
        title: "Login",
      });
    } else if (type === "token") {
      this.forcedLogout = true;
      this.props.actions.logout();
      this.props.actions.showNotification(
        this.props.translate("error.tokenTitle"),
        this.props.translate("error.tokenText"),
        5000
      );

      analytics.error("accessToken", {
        path: "/login",
        title: "Login",
      });
    }
  }

  render() {
    const componentClass = classNames("s-login", "h-scrollable", {
      "s-login--loading": this.props.loading,
      "s-login--form": this.state.showForm,
      "s-login--authenticating": this.props.authenticating,
      "s-login--transitioning": this.state.nextScenePath,
    });

    return (
      <div className={componentClass}>
        <div className="s-login__wrapper">
          <div className="s-login__language">
            <RouteLink
              to="/profile/language"
              className="c-language-link"
              transition="switch-open"
            >
              <span>Language</span>
            </RouteLink>
          </div>
          <div className="s-login__panel">
            <div className="s-login__top">
              <div className="s-login__logo">
                <Logo />
              </div>
            </div>

            <div className="s-login__middle">
              <div className="s-login__form">
                <LoginForm
                  submitHandler={this.handleSubmit}
                  errors={{
                    phone: this.props.errors.phone,
                    password: this.props.errors.password,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="s-login__bar">
            <a
              href={this.props.translate("links.information")}
              className="c-text-link"
              target="_blank"
              rel="noreferrer"
            >
              {/* <Translate id="login.infoLinkText" /> */}
            </a>

            <button className="ot-sdk-show-settings">Cookie Settings</button>
          </div>
        </div>

        <div className="s-login__spinner">
          <Spinner />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object,
  loading: PropTypes.bool,
  authenticating: PropTypes.bool,
  status: PropTypes.number,
  actions: PropTypes.object,
  errors: PropTypes.object,
  loggedIn: PropTypes.bool,
  profileSynced: PropTypes.bool,
  order: PropTypes.object,
  translate: PropTypes.func,
  activeLanguage: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const y = {
    loading: state.user.loading,
    authenticating: state.user.authenticating,
    profile: state.user.profile,
    errors: state.user.errors,
    loggedIn: state.user.loggedIn,
    profileSynced: state.user.profileSynced,
    locale: state.user.locale,
    order: {
      active: state.deprecated__order.active,
      statusChecked: state.deprecated__order.statusChecked,
      statusError: state.deprecated__order.statusError,
      loading: state.deprecated__order.loading,
    },
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        showNotification,
        syncUserProfile,
        login,
        logout,
        deprecated__clearOrder,
        clearTracking,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Login));
