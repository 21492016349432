import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";

const OpeningHours = ({ times, translate }) => {
  const content = times
    ? `${translate("general.openingTimesPrefix")} ${times}`
    : translate("general.timeUnavailable");

  return <React.Fragment>{content}</React.Fragment>;
};

OpeningHours.propTypes = {
  translate: PropTypes.func,
  times: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default withLocalize(OpeningHours);
