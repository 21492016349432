/**
 * External dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import { Translate } from "react-localize-redux";
import ContentLoader from "react-content-loader";
import { AnimatePresence } from "framer-motion";

/**
 * Internal dependencies
 */
import { randomArrayItem } from "../../utils/Helpers";
import { Loading, TranslateString } from "../../types";
import { loaderTheme } from "../../utils/loaders";
import FadeIn from "../animations/FadeIn";
import classNames from "classnames";

/**
 * Constants
 */
const TIME_CHANGE_THRESHOLD_MINUTES = 10;

const TIME_INCREASE_MESSAGE_IDS = [
  "tracking.timeIncrease.v1",
  "tracking.timeIncrease.v2",
  "tracking.timeIncrease.v3",
  "tracking.timeIncrease.v4",
  "tracking.timeIncrease.v5",
];

const TIME_DECREASE_MESSAGE_IDS = [
  "tracking.timeDecrease.v1",
  "tracking.timeDecrease.v2",
  "tracking.timeDecrease.v3",
  "tracking.timeDecrease.v4",
  "tracking.timeDecrease.v5",
];

type Size = "regular" | "small";

interface TrackingMessageProps {
  loading: Loading;
  queue?: number;
  checkedIn: boolean;
  size?: Size;
}

const TrackingMessage: React.FC<TrackingMessageProps> = ({
  loading,
  queue,
  checkedIn,
  size = "regular",
}) => {
  const previousQueue = useRef<number | null>(null);
  const [message, setMessage] = useState<TranslateString | React.ReactElement>(
    <Loader size={size} />
  );

  useEffect(() => {
    if (typeof queue !== "undefined" && loading === "fulfilled") {
      const prev = previousQueue.current;

      // Default message id
      let messageId = "tracking.timeNeutral";

      // Show a warning if timer hits 0 several times in a row
      if (queue === 0 && prev === 0) {
        messageId = "tracking.timeWarning";
      }

      if (prev !== null) {
        // If timer increased over the threshold
        if (queue >= prev + TIME_CHANGE_THRESHOLD_MINUTES) {
          messageId = randomArrayItem(TIME_INCREASE_MESSAGE_IDS);
        }

        // If timer decreased over the threshold
        if (queue <= prev - TIME_CHANGE_THRESHOLD_MINUTES) {
          messageId = randomArrayItem(TIME_DECREASE_MESSAGE_IDS);
        }

        // Time hits zero
        if (queue === 0 && prev > 0) {
          messageId = "tracking.timeZero";
        }
      }

      previousQueue.current = queue;
      setMessage(<Translate id={messageId} />);
    }
  }, [loading, queue]);

  const componentClass = classNames("c-tracking-message", {
    "c-tracking-message--checked-in": checkedIn,
    [`is-size-${size}`]: true,
  });

  const titleClass = classNames("c-tracking-message__title");

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {!checkedIn ? (
        <FadeIn
          key={"tracking"}
          duration={size === "regular" ? 0.5 : 0}
          className={componentClass}
        >
          {size === "regular" ? (
            <h2 className={titleClass}>{message}</h2>
          ) : (
            <h5 className={titleClass}>{message}</h5>
          )}
        </FadeIn>
      ) : (
        <FadeIn
          key={"checkin"}
          className={componentClass}
          duration={size === "regular" ? 0.5 : 0}
          delay={size === "regular" ? 0.25 : 0}
        >
          {size === "regular" ? (
            <h1 className={titleClass}>
              <Translate id="checkin.title" />
            </h1>
          ) : (
            <h5 className={titleClass}>
              <Translate id="checkin.title" />
            </h5>
          )}
          <p className="c-tracking-message__description">
            <Translate id="checkin.text" />
          </p>
        </FadeIn>
      )}
    </AnimatePresence>
  );
};

export default TrackingMessage;

const Loader = ({ size }: { size: Size }) =>
  size === "regular" ? (
    <ContentLoader
      viewBox="0 0 250 40"
      width={250}
      height={40}
      {...loaderTheme}
      style={{ width: "100%" }}
      preserveAspectRatio="none"
    >
      <rect x="15" y="0" rx="6" ry="12" width="220" height="15" />
      <rect x="35" y="25" rx="6" ry="12" width="180" height="15" />
    </ContentLoader>
  ) : (
    <ContentLoader
      viewBox="0 0 200 30"
      width={200}
      height={30}
      {...loaderTheme}
      style={{ width: "100%" }}
      preserveAspectRatio="none"
    >
      <rect x="0" y="3" rx="3" ry="5" width="190" height="9" />
      <rect x="0" y="20" rx="3" ry="5" width="120" height="9" />
    </ContentLoader>
  );
