import * as helpers from "../utils/Helpers";
import * as APINormalizer from "../utils/APINormalizer";
import { getUserId } from "../utils/api.js";
import { api } from "./api";

/**
 * Get the latest order of a user from the API
 *
 * @return {Promise.<array, Error>} Resolves with array of orders or rejects with Errors
 */

export function getLatestOrder() {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    let params = {
      latest_only: true,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    api
      .get(`/customers/${userId}/orders/?${queryString}`)
      .then((response) => {
        resolve(APINormalizer.ordersResponse(response.data, userId)[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get all receipts of a user from the API
 *
 * @return {Promise.<array, Error>} Resolves with array of orders or rejects with Errors
 */

export function getReceipts() {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    api
      .get(`/customers/${userId}/receipts/`)
      .then((response) => {
        resolve(APINormalizer.receiptsResponse(response.data, userId));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get single order of a user from the API
 *
 * @param {int} orderId - Order ID
 * @return {Promise.<object, Error>} Resolves with order details or rejects with Errors
 */

export function getOrder(orderId) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    api
      .get(`/customers/${userId}/orders/${orderId}/`)
      .then((response) => {
        resolve(APINormalizer.orderResponse(response.data, userId));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Sends the order to the API
 *
 * @param {object} barbershop - Single shop data
 * @param {array} services - The selected services
 * @param {object} extraServices - The selected extra services
 * @return {Promise.<number, Error>} Resolves with the order details, rejects with Errors
 */
export const sendOrder = (barbershop, services, extraServices) =>
  new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    const normalizedPayload = APINormalizer.sendOrderPayload(
      barbershop,
      services,
      extraServices
    );

    api
      .post(`/customers/${userId}/orders/`, normalizedPayload)
      .then((response) => {
        const normalizedResponse = APINormalizer.sendOrderResponse(
          response.data
        );

        saveOrder(normalizedResponse);
        resolve(normalizedResponse?.orderId);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

/**
 * Checks the user in to the pob
 *
 * @param {int} orderId - ID of the order
 * @return {Promise.<object, Error>} Resolves by updating the order in local storage
 */

export function checkIn(orderId) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    const data = {
      checkedIn: true,
    };

    api
      .patch(
        `/customers/${userId}/orders/${orderId}/`,
        APINormalizer.checkInPayload(data)
      )
      .then((response) => {
        resolve(helpers.updateLocalStorage(data, "order"));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Cancels the order from the API
 *
 * @param {int} orderId - ID of the order
 * @return {Promise.<object, Error>} Resolves with void or rejects with Errors
 */

export function cancelOrder(orderId) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    const data = {
      cancel: true,
    };

    api
      .patch(`/customers/${userId}/orders/${orderId}/`, data)
      .then((response) => {
        clearOrder();
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Stores the order details to localStorage
 *
 * @todo Is this needed? Remove if not
 * @param {object} order - Selected orders
 */

export function saveOrder(order) {
  localStorage.setItem("order", JSON.stringify(order));
}

/**
 * Clears the order details from localStorage
 *
 * @todo Is this needed? Remove if not
 */
export function clearOrder() {
  localStorage.removeItem("order");
}

/**
 * -------------------------------------------------------------------
 * Deprecated:
 * -------------------------------------------------------------------
 */

/**
 * Fetch the extended barbershop data from the shop endpoint
 *
 * @deprecated use getBarbershop instead
 * @param {integer} id - Id of the barbershop
 * @return {Promise.<object, Error>} Resolves with barbershop data, rejects with errors
 */

export function deprecated__getBarbershop(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/pobs/${id}/`)
      .then((response) => {
        resolve(APINormalizer.deprecated__barbershopResponse(response.data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get all orders of a user from the API
 *
 * @deprecated use getOrders instead
 * @param {string} status - Get only orders with a specific status
 * @param {boolean} latestOnly - Get the latest order only
 * @return {Promise.<array, Error>} Resolves with array of orders or rejects with Errors
 */

export function deprecated__getOrders(status = "", latestOnly = false) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    let params = {
      status: status,
      latest_only: latestOnly,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    api
      .get(`/customers/${userId}/orders/?${queryString}`)
      .then((response) => {
        resolve(
          APINormalizer.deprecated__ordersResponse(response.data, userId)
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get single order of a user from the API
 *
 * @deprecated use getOrder instead
 * @param {int} orderId - Order ID
 * @return {Promise.<object, Error>} Resolves with order details or rejects with Errors
 */

export function deprecated__getOrder(orderId) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    api
      .get(`/customers/${userId}/orders/${orderId}/`)
      .then((response) => {
        resolve(APINormalizer.deprecated__orderResponse(response.data, userId));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Sends the order to the API
 *
 * @deprecated Use sendOrder instead
 * @param {object} barbershop - Single shop data
 * @param {array} services - The order's selected services
 * @return {Promise.<object, Error>} Resolves with the order details, rejects with Errors
 */

export function deprecated__sendOrder(barbershop, services) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    const data = APINormalizer.deprecated__orderPayload(services, barbershop);

    api
      .post(`/customers/${userId}/orders/`, data)
      .then((response) => {
        resolve(APINormalizer.deprecated__orderResponse(response.data, userId));
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

/**
 * Cancels the order from the API
 *
 * @param {int} orderId - ID of the order
 * @return {Promise.<object, Error>} Resolves with void or rejects with Errors
 */

export function deprecated__cancelOrder(orderId) {
  return new Promise((resolve, reject) => {
    const userId = getUserId();

    if (!userId) {
      reject("User not found");
      return;
    }

    const data = {
      cancel: true,
    };

    api
      .patch(`/customers/${userId}/orders/${orderId}/`, data)
      .then((response) => {
        deprecated__clearOrder();
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Stores the order details to localStorage
 *
 * @param {object} order - Selected orders
 * @param {object} barbershop - Barbershop details
 */

export function deprecated__saveOrder(order, barbershop = false) {
  localStorage.setItem("order", JSON.stringify(order));

  if (barbershop) {
    localStorage.setItem("barbershop", JSON.stringify(barbershop));
  }
}

/**
 * Clears the order details from localStorage
 */

export function deprecated__clearOrder() {
  localStorage.removeItem("order");
  localStorage.removeItem("barbershop");
}
