import React from "react";

import { Barbershop } from "../types";
import { navigateTo } from "../utils/navigation";
import { analytics } from "../utils/Analytics";

interface BarbershopMarkerButtonProps {
  barbershop: Barbershop;
  lat?: number;
  lng?: number;
}

const BarbershopMarkerButton: React.FC<BarbershopMarkerButtonProps> = ({
  barbershop,
  children,
}) => {
  const { id } = barbershop || {};

  const handleClick = () => {
    if (typeof barbershop !== "undefined") {
      analytics.storeSelected(barbershop, "map");
      navigateTo(`/barbershop/${id}`, "switch-open");
    }
  };

  return (
    <button className="h-button-wrapper" onClick={handleClick}>
      {children}
    </button>
  );
};

export default BarbershopMarkerButton;
