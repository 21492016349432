import React, { useState, useCallback } from "react";
import classNames from "classnames";

import { Loading, TranslateString } from "../../types";
import ConfirmButton from "../../components/ConfirmButton";
import { useEmailInput } from "../../utils/forms";
import { patchProfile } from "../../services/user";
import Checkbox from "../../components/Checkbox";

interface EmailFormProps {
  initialEmail?: string;
  label: TranslateString;
  cta: TranslateString;
  marketingPermissionLabel: TranslateString;
  emailErrorText: TranslateString;
  requireMarketingPermission?: boolean;
  initialMarketingPermission?: boolean;
  onComplete?: () => void;
}

const EmailForm: React.FC<EmailFormProps> = ({
  initialEmail = "",
  label,
  cta,
  marketingPermissionLabel,
  emailErrorText,
  requireMarketingPermission = false,
  initialMarketingPermission = true,
  onComplete,
}) => {
  const [loading, setLoading] = useState<Loading>("idle");

  /**
   * Email state
   */
  const { input, setInput, focus, blur, isValid, hasError } =
    useEmailInput(initialEmail);

  /**
   * Marketing permission state
   */
  const [marketingPermission, setMarketingPermission] = useState(
    initialMarketingPermission
  );

  /**
   * Handle submission
   */
  const handleClick = useCallback(() => {
    setLoading("pending");

    patchProfile({
      email: input,
      allow_email: marketingPermission,
    })
      .then(() => {
        setLoading("fulfilled");

        if (onComplete) {
          onComplete();
        }
      })
      .catch(() => {
        setLoading("rejected");
      });
  }, [input, marketingPermission, onComplete]);

  /**
   * Class names
   */
  const inputClass = classNames("c-email-form__input", {
    "has-error": hasError,
    "is-valid": isValid,
  });

  return (
    <form className="c-email-form">
      <label className="c-email-form__label">{label}</label>

      <input
        className={inputClass}
        type="email"
        value={input}
        onChange={(e) => setInput(e?.currentTarget?.value)}
        onFocus={focus}
        onBlur={blur}
        required
      />

      {hasError && (
        <div className="c-email-form__error-message">{emailErrorText}</div>
      )}

      <div className="c-email-form__marketing-permission">
        <Checkbox
          label={marketingPermissionLabel}
          value={marketingPermission}
          onChange={() => setMarketingPermission(!marketingPermission)}
          align="left"
        />
      </div>

      <div className="c-email-form__button">
        <ConfirmButton
          loading={loading}
          text={cta}
          onClick={handleClick}
          disabled={
            !isValid || (requireMarketingPermission && !marketingPermission)
          }
        />
      </div>
    </form>
  );
};

export default EmailForm;
