/**
 * External dependencies
 */
import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

/**
 * Internal dependencies
 */
import { ExtraService, PageProps, Service } from "../types";
import { analytics } from "../utils/Analytics";
import { useDimensions } from "../utils/use-dimensions";
import {
  clearOrder,
  fetchBarbershop,
  selectOrderBarbershop,
  selectOrderLoading,
  selectOrderStatus,
  acceptOrder,
} from "../features/order/orderSlice";
import { clearProfileSync } from "../ducks/user";
import { navigateTo } from "../utils/navigation";
import { setPageTitle } from "../utils/seo";
import { getAnalyticsOptions } from "../features/order/utils";
import { useBarbershopQueueStatus } from "../features/barbershop/use-barbershop-queue-status";
import { slugify, getTotalOffsetTop } from "../utils/Helpers";
import { useFavoriteBarbershopIds } from "../features/user/use-favorite-barbershop-ids";
import { useFocusInterval } from "../utils/use-focus-interval";
import { useRandomBrandImageUrl } from "../features/images/use-random-brand-image-url";
import { useTrueOncePerSession } from "../features/storage/use-true-once-per-session";

import ApiConstants from "../constants/api";
import BackButton from "../components/BackButton";
import BarbershopDetails from "../features/barbershop/BarbershopDetails";
import BarbershopTimer from "../features/barbershop/BarbershopTimer";
import BarbershopMap from "../features/maps/BarbershopMap";
import FavoriteToggle from "../components/FavoriteToggle";
import FixedHeader from "../components/FixedHeader";
import FixedHeaderContent from "../features/order/FixedHeaderContent";
import ShowAndHide from "../components/ShowAndHide";
import StatusNotice from "../features/order/StatusNotice";
import Timer from "../components/Timer";
import ServiceGroupContainer from "../features/order/ServiceGroupContainer";
import ServiceToggle from "../features/order/ServiceToggle";
import ExtraServiceGroupContainer from "../features/order/ExtraServiceGroupContainer";
import ExtraServiceToggle from "../features/order/ExtraServiceToggle";
import Modal from "../components/Modal";
import OrderReview from "../features/order/OrderReview";
import OrderErrorHandler from "../features/order/OrderErrorHandler";
import ServicesLoader from "../features/order/ServicesLoader";
import NoticeBubble from "../components/NoticeBubble";
import AnchorLink from "../components/AnchorLink";
import Button from "../components/Button";
import VariableWidthSlider from "../components/VariableWidthSlider";
import SlideUp from "../features/animations/SlideUp";
import CriticalCTAButton from "../components/CriticalCTAButton";
import ConfirmDialog from "../components/ConfirmDialog";
import Drawer, { useDrawerState } from "../components/Drawer";
import ServiceInfo from "../features/order/ServiceInfo";
import ExtraServiceInfo from "../features/order/ExtraServiceInfo";
import { useIsLoggedIn } from "../features/user/use-is-logged-in";
import LoginDialog from "../components/LoginDialog";

/**
 * Constants
 */
const EXIT_ROUTE = "/home";
const SUCCESS_ROUTE = "/tracking";

const FIXED_HEADER_HEIGHT = 75;
const ANCHOR_FINE_OFFSET = 2;

const { BARBERSHOP_REFRESH_TIMEOUT } = ApiConstants;

const IMAGE_RESPONSIVE_HEIGHT = {
  sm: 230,
  md: 250,
  lg: 325,
};

/**
 * Interface
 */
interface BarbershopProps extends PageProps {}

const Barbershop: React.FC<BarbershopProps> = ({
  translate,
  activeLanguage,
}) => {
  /**
   * Utils, refs
   */
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);

  /**
   * Display state
   */
  const [showBarbershopMap, setShowBarbershopMap] = useState(false);

  /**
   * User
   */
  const loggedIn = useIsLoggedIn();
  const [showLoginForm, setShowLoginForm] = useState(false);

  /**
   * Component mount actions
   */
  useEffect(() => {
    return () => {
      dispatch(clearOrder());
    };
  }, [dispatch]);

  /**
   * Fetch barbershop data
   */
  const { id: idString } = useParams<any>();
  const id = parseInt(idString);

  const memoizedFetchBarbershop = useCallback(() => {
    dispatch(fetchBarbershop(id));
  }, [dispatch, id]);

  useEffect(() => {
    memoizedFetchBarbershop();
  }, [memoizedFetchBarbershop]);

  useFocusInterval(memoizedFetchBarbershop, BARBERSHOP_REFRESH_TIMEOUT);

  const barbershop = useSelector(selectOrderBarbershop);
  const { image, queue, name, city, serviceGroups, services, extraServices } =
    barbershop;
  const hasBarbershopData = !!(serviceGroups && serviceGroups.length);

  /**
   * Barbershop loading
   */
  const loading = useSelector(selectOrderLoading);

  useEffect(() => {
    if (loading === "rejected") {
      navigateTo(EXIT_ROUTE, "slide-left");
    }
  }, [loading]);

  /**
   * Barbershop queue status
   */
  const status = useBarbershopQueueStatus(barbershop);

  /**
   * Queue time warning
   */
  const shouldShowQueueWarning = useMemo(() => queue === 0, [queue]);
  const [showQueueWarning, setShowQueueWarning] = useState(false);

  const handleQueueWarningConfirmClick = useCallback(() => {
    setShowQueueWarning(false);
    dispatch(acceptOrder());
  }, [dispatch]);

  /**
   * Accept & confirm order
   */
  const orderStatus = useSelector(selectOrderStatus);

  const handleAcceptClick = useCallback(() => {
    if (!loggedIn) {
      setShowLoginForm(true);
    } else if (shouldShowQueueWarning) {
      setShowQueueWarning(true);
    } else {
      dispatch(acceptOrder());
    }
  }, [dispatch, shouldShowQueueWarning, loggedIn]);

  useEffect(() => {
    if ("accepted" === orderStatus) {
      analytics.genericEvent("servicesSelected");
      analytics.pageView("/order-confirm", "Order Confirmation");
    }
  }, [orderStatus]);

  /**
   * Send and process order
   */
  const onOrderComplete = useCallback(() => {
    dispatch(clearProfileSync());
    setTimeout(() => navigateTo(SUCCESS_ROUTE, "slide-right"), 750);
  }, [dispatch]);

  /**
   * SEO & Analytics
   */
  useEffect(() => {
    if (activeLanguage && name) {
      setPageTitle([translate("title.base"), name]);
    }
  }, [name, activeLanguage, translate]);

  useEffect(() => {
    if (id && name) {
      const { path, title } = getAnalyticsOptions(id, name);
      analytics.pageView(path, title);
    }
  }, [id, name]);

  /**
   * Image
   */
  const fallbackImage = useRandomBrandImageUrl(id);
  const { windowHeight } = useDimensions();

  const imageHeight = useMemo(
    () => getImageHeight(windowHeight),
    [windowHeight]
  );

  /**
   * Favorites
   */
  const favoriteIds = useFavoriteBarbershopIds();
  const isFavorite = useMemo(() => favoriteIds.includes(id), [favoriteIds, id]);

  /**
   * Anchor links
   */
  const calculateAnchorLinkOffset = useCallback(
    () =>
      getTotalOffsetTop([contentRef, servicesRef]) -
      FIXED_HEADER_HEIGHT +
      ANCHOR_FINE_OFFSET,
    []
  );

  /**
   * Notices
   */
  const shouldShowHeaderNotice = useTrueOncePerSession(
    "barbershop-header-notice"
  );

  /**
   * Service info drawer
   */
  const {
    isOpen: showDrawer,
    content: drawerContent,
    open: openDrawer,
    close: closeDrawer,
  } = useDrawerState();

  const handleServiceInfoClick = useCallback(
    (service: Service) => {
      openDrawer(<ServiceInfo {...service} />);
    },
    [openDrawer]
  );

  const handleExtraServiceInfoClick = useCallback(
    (extraService: ExtraService) => {
      openDrawer(<ExtraServiceInfo {...extraService} />);
    },
    [openDrawer]
  );

  /**
   * Classnames
   */
  const componentClass = classNames("s-barbershop", {
    "is-loading": loading === "pending" && !hasBarbershopData,
  });

  const wrapperClass = classNames("s-barbershop__wrapper", {
    "h-scrollable": hasBarbershopData,
  });

  const servicesClass = classNames("s-barbershop__services", {
    "is-editable":
      status === "taking-orders" &&
      (orderStatus === "idle" || orderStatus === "pending"),
  });

  return (
    <div className={componentClass}>
      <div className={wrapperClass} ref={wrapperRef}>
        <div
          className="s-barbershop__image"
          style={{
            backgroundImage: `url(${false === image ? fallbackImage : image})`,
            height: imageHeight,
          }}
        />

        <div className="s-barbershop__header">
          {status === "taking-orders" && shouldShowHeaderNotice && (
            <ShowAndHide delay={1000} length={5000}>
              <div className="s-barbershop__header-notice">
                <NoticeBubble>
                  <h4>
                    <Translate id="barbershop.headerNoticeTitle" />
                  </h4>
                  <p>
                    <Translate id="barbershop.headerNoticeText" />
                  </p>
                </NoticeBubble>
              </div>
            </ShowAndHide>
          )}

          <div className="s-barbershop__timer">
            <BarbershopTimer
              status={status}
              loading={loading}
              onClick={() => fetchBarbershop(id)}
            >
              <Timer minutes={queue} size="regular" isOnPanel={false} />
            </BarbershopTimer>
          </div>
        </div>

        {status !== "unknown" && (
          <FixedHeader
            scrollWrapperRef={wrapperRef}
            offset={imageHeight}
            height={FIXED_HEADER_HEIGHT}
          >
            {(isVisible) => (
              <FixedHeaderContent
                className="s-barbershop__fixed-header-content"
                title={`${name}, ${city}`}
                isVisible={isVisible}
              >
                <BarbershopTimer
                  status={status}
                  loading={loading}
                  onClick={() => fetchBarbershop(id)}
                  size="tiny"
                >
                  <Timer minutes={queue} size="tiny" isOnPanel={false} />
                </BarbershopTimer>
              </FixedHeaderContent>
            )}
          </FixedHeader>
        )}

        <div className="s-barbershop__content" ref={contentRef}>
          <BarbershopDetails
            className="s-barbershop__details"
            barbershop={barbershop}
          />

          <StatusNotice
            className="s-barbershop__notice"
            status={status}
            queue={queue}
          />

          <div className="s-barbershop__favorite">
            <FavoriteToggle barbershop={barbershop} isFavorite={isFavorite} />
          </div>

          {hasBarbershopData ? (
            <div className={servicesClass} ref={servicesRef}>
              <div className="s-barbershop__service-group-selector">
                <VariableWidthSlider gutter={25} spacing={8}>
                  {serviceGroups.map((serviceGroup) => (
                    <AnchorLink
                      key={serviceGroup.id}
                      target={slugify(serviceGroup.title)}
                      offset={calculateAnchorLinkOffset}
                      scrollRef={wrapperRef}
                    >
                      <Button
                        variant="ghost-pill"
                        className="h-nowrap"
                        text={serviceGroup.title}
                      />
                    </AnchorLink>
                  ))}
                </VariableWidthSlider>
              </div>

              {serviceGroups.map((serviceGroup) => (
                <div
                  className="s-barbershop__service-group"
                  key={serviceGroup.id}
                  id={slugify(serviceGroup.title)}
                >
                  <ServiceGroupContainer
                    {...serviceGroup}
                    data={services}
                    render={(service) => (
                      <ServiceToggle
                        {...service}
                        key={service.id}
                        onInfoClick={handleServiceInfoClick}
                        data={extraServices}
                        render={(serviceExtraServices) => (
                          <ExtraServiceGroupContainer
                            title={translate("barbershop.serviceAddExtras")}
                            data={serviceExtraServices}
                            render={(extraService) => (
                              <ExtraServiceToggle
                                {...extraService}
                                key={extraService.id}
                                onInfoClick={handleExtraServiceInfoClick}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          ) : (
            <ServicesLoader />
          )}
        </div>
      </div>

      <div className="s-barbershop__back">
        <BackButton fallbackRoute={EXIT_ROUTE} />
      </div>

      <AnimatePresence>
        {orderStatus === "pending" && (
          <SlideUp
            className="s-barbershop__accept-order"
            duration={0.25}
            bounce={0}
          >
            <CriticalCTAButton
              text={translate("general.next")}
              onClick={handleAcceptClick}
            />
          </SlideUp>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showQueueWarning && (
          <Modal valign="middle">
            <ConfirmDialog
              title={translate("barbershop.confirmTimeZeroNoticeTitle")}
              description={translate(
                "barbershop.confirmTimeZeroNoticeText",
                {},
                { renderInnerHtml: true }
              )}
              cancelText={translate("general.cancel")}
              confirmText={translate("general.continue")}
              onCancel={() => setShowQueueWarning(false)}
              onConfirm={handleQueueWarningConfirmClick}
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {orderStatus === "accepted" && (
          <Modal contentPadding="tight">
            <OrderReview
              onImageClick={() => setShowBarbershopMap(true)}
              onComplete={onOrderComplete}
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showBarbershopMap && (
          <BarbershopMap
            barbershop={barbershop}
            className="s-barbershop__map"
            onClick={() => setShowBarbershopMap(false)}
            analyticsPathName={`/barbershop/${id}/map`}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showLoginForm && (
          <Modal contentPadding="tight" valign="middle">
            <LoginDialog
              title={translate("barbershop.loginFormTitle")}
              onComplete={() => {
                setShowLoginForm(false);
                handleAcceptClick();
              }}
              onClose={() => setShowLoginForm(false)}
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showDrawer && (
          <Drawer onClose={() => closeDrawer()}>{drawerContent}</Drawer>
        )}
      </AnimatePresence>

      <OrderErrorHandler id={id} name={name} translate={translate} />
    </div>
  );
};

export default withLocalize(Barbershop);

/**
 * Get image height based on window height.
 */
const getImageHeight = (windowHeight: number): number => {
  const { sm, md, lg } = IMAGE_RESPONSIVE_HEIGHT;

  if (windowHeight < 768) {
    return sm;
  } else if (windowHeight < 900) {
    return md;
  }

  return lg;
};
