import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";
import { withLocalize, Translate } from "react-localize-redux";
import ContentLoader from "react-content-loader";

import { loaderTheme } from "../utils/loaders";
import { ReactComponent as CheckIcon } from "../assets/images/icon-check.svg";
import { ReactComponent as CrossIcon } from "../assets/images/icon-cross.svg";
import Bounce from "../features/animations/Bounce";

const TIMER_SIZES = {
  tiny: 36,
  small: 52,
  regular: 90,
  large: 138,
};

const PULSE_SCALES = {
  tiny: 1.8,
  small: 1.6,
  regular: 1.5,
  large: 1.3,
};

class Timer extends React.Component {
  constructor(props) {
    super(props);

    this.pulseInitialized = false;
    this.pulse1 = React.createRef();
    this.pulse2 = React.createRef();
  }

  componentDidMount() {
    this.maybeSetupPulse();
  }

  componentDidUpdate() {
    this.maybeSetupPulse();
  }

  maybeSetupPulse() {
    if (this.pulseInitialized || !this.props.shouldPulse) {
      return false;
    }

    if (this.pulse1.current !== null && this.pulse2.current !== null) {
      gsap.fromTo(
        this.pulse1.current,
        {
          scale: 1,
          opacity: 1,
        },
        {
          scale: PULSE_SCALES[this.props.size],
          opacity: 0,
          duration: 2,
          ease: "expo.out",
          repeat: -1,
          repeatDelay: 1,
          delay: 0.25,
        }
      );

      gsap.fromTo(
        this.pulse2.current,
        {
          scale: 1,
          opacity: 1,
        },
        {
          scale: PULSE_SCALES[this.props.size],
          opacity: 0,
          duration: 2,
          ease: "expo.out",
          repeat: -1,
          repeatDelay: 1,
          delay: 0.6,
        }
      );

      this.pulseInitialized = true;
    }
  }

  getWaitClass(hasNoWait, minutes) {
    if (hasNoWait) {
      return "0";
    }

    if (minutes >= 60) {
      return "60";
    }

    if (minutes >= 30) {
      return "30";
    }

    if (minutes >= 15) {
      return "15";
    }

    if (minutes >= 5) {
      return "5";
    }

    return "0";
  }

  render() {
    const hasText = this.props.text && this.props.text !== null;
    const sizeClass = this.props.size ? this.props.size : "regular";
    const isZero = this.props.minutes === 0;

    const content = hasText
      ? this.props.text
      : this.props.minutes !== null && this.props.minutes !== false
      ? this.props.minutes !== 0
        ? this.props.minutes
        : this.props.translate("general.timeNow")
      : this.props.translate("general.timeUnavailable");

    const type = hasText || typeof content === "string" ? "string" : "number";

    const waitClass = this.getWaitClass(
      isZero || type === "string",
      this.props.minutes
    );

    const fontSizeClass =
      type === "string"
        ? content.length > 8
          ? "font-small"
          : "font-regular"
        : "font-regular";

    const componentClass = classNames({
      "c-timer": true,
      [`c-timer--${sizeClass}`]: true,
      [`c-timer--wait-${waitClass}`]: true,
      [`c-timer--${type}`]: true,
      [`c-timer--${fontSizeClass}`]: true,
      "c-timer--on-panel": this.props.isOnPanel,
    });

    if (this.props.success) {
      return (
        <div className={componentClass}>
          <TimerSuccessIndicator />
        </div>
      );
    }

    if (this.props.failure) {
      return (
        <div className={componentClass}>
          <TimerFailureIndicator />
        </div>
      );
    }

    if (typeof this.props.minutes === "undefined") {
      return <TimerLoader size={this.props.size} />;
    }

    return (
      <div className={componentClass}>
        <div className="c-timer__wait-indicator" />
        <div className="c-timer__wrapper">
          <div className="c-timer__time">{content}</div>

          {!this.props.noSuffix && (
            <React.Fragment>
              {this.props.size !== "tiny" && type === "number" ? (
                <div className="c-timer__unit">
                  <Translate id="general.minutesSuffix" />
                </div>
              ) : isZero && this.props.size !== "tiny" ? (
                <div className="c-timer__unit c-timer__unit--small">
                  <Translate id="general.timeNowSuffix" />
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>

        {this.props.shouldPulse && (
          <>
            <div ref={this.pulse1} className="c-timer__pulse" />
            <div ref={this.pulse2} className="c-timer__pulse" />
          </>
        )}
      </div>
    );
  }
}

Timer.propTypes = {
  size: PropTypes.string,
  translate: PropTypes.func,
  minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  success: PropTypes.bool,
  failure: PropTypes.bool,
  text: PropTypes.string,
  noSuffix: PropTypes.bool,
  isOnPanel: PropTypes.bool,
  shouldPulse: PropTypes.bool,
};

Timer.defaultProps = {
  isOnPanel: true,
  shouldPulse: false,
};

export default withLocalize(Timer);

const TimerLoader = ({ size = "regular" }) => {
  return (
    <ContentLoader
      viewBox="0 0 10 10"
      height={TIMER_SIZES[size]}
      width={TIMER_SIZES[size]}
      {...loaderTheme}
    >
      <circle cx="5" cy="5" r="5" />
    </ContentLoader>
  );
};

const TimerSuccessIndicator = () => {
  return (
    <div className="c-timer__status-indicator c-timer__status-indicator--success">
      <Bounce className="icon">
        <CheckIcon />
      </Bounce>
    </div>
  );
};

const TimerFailureIndicator = () => {
  return (
    <div className="c-timer__status-indicator c-timer__status-indicator--failure">
      <Bounce className="icon">
        <CrossIcon />
      </Bounce>
    </div>
  );
};
