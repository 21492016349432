import { useEffect, useRef } from "react";

export default function usePageVisibility(cb, runOnMount = true) {
  const ranOnce = useRef(false);

  const browserCompatApi = () => {
    let hidden, visibilityChange;
    if ("hidden" in document) {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if ("mozHidden" in document) {
      // Firefox up to v17
      hidden = "mozHidden";
      visibilityChange = "mozvisibilitychange";
    } else if ("webkitHidden" in document) {
      // Chrome up to v32, Android up to v4.4, Blackberry up to v10
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    return {
      hidden,
      visibilityChange,
    };
  };

  useEffect(() => {
    const { hidden, visibilityChange } = browserCompatApi();

    if (typeof cb !== "function")
      throw new Error("callback must be a function");

    const handleVisibilityChange = () => {
      cb(!document[hidden]);
    };

    document.addEventListener(visibilityChange, handleVisibilityChange);

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, [cb]);

  /**
   * Also run once on mount
   */
  useEffect(() => {
    if (runOnMount && !ranOnce.current) {
      ranOnce.current = true;
      cb(true);
    }
  }, [cb, runOnMount, ranOnce]);
}
