import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withLocalize, Translate } from "react-localize-redux";

import { closeNotification } from "../ducks/notification";
import Button from "./Button";

const Notification = ({ title, text, linkUrl, linkText, actions }) => {
  const handleComponentClick = (e) => {
    e.stopPropagation();

    actions.closeNotification();
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();

    if (linkUrl) {
      window.open(linkUrl, "_self");
    }

    actions.closeNotification();
  };

  return (
    <div className="c-notification" onClick={handleComponentClick}>
      <h2 className="c-notification__title">{title}</h2>
      <p className="c-notification__text">{text}</p>
      {linkUrl && linkText ? (
        <div className="c-notification__button">
          <Button text={linkText} onClick={handleButtonClick} size="small" />
          <div className="c-notification__close">
            <Translate id="general.overlayClose" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const y = state.notification;
  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        closeNotification,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Notification));
