import React from "react";
import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";

import RouteLink from "./RouteLink";
import { Indexed } from "../types";
import { selectUnreadMessagesCount } from "../ducks/messages";
import { selectUserLocale } from "../ducks/user";
import { ReactComponent as HomeIcon } from "../assets/images/icon-navigation-mroom.svg";
import { ReactComponent as BonusIcon } from "../assets/images/icon-navigation-bonus.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/icon-navigation-profile.svg";
import { ReactComponent as LoginIcon } from "../assets/images/icon-navigation-log-in.svg";
import bonusConfig from "../config/bonus.json";
import { BonusConfig } from "../config/types";
import { useIsLoggedIn } from "../features/user/use-is-logged-in";

/**
 * Routes that generates the navigation.
 */
enum RouteNames {
  Root = "home",
  Login = "login",
  Bonus = "bonus",
  Profile = "profile",
}

const ROUTES_WITH_BONUS = [
  RouteNames.Root,
  RouteNames.Bonus,
  RouteNames.Profile,
];

const ROUTES_WITHOUT_BONUS = [RouteNames.Root, RouteNames.Profile];
const ROUTES_FOR_GUESTS = [RouteNames.Root, RouteNames.Login];

const isRoot = (route: string) => {
  return "root" === route;
};

/**
 * Icons
 */
const ICONS = {
  home: <HomeIcon />,
  bonus: <BonusIcon />,
  profile: <ProfileIcon />,
  login: <LoginIcon />,
} as Indexed<React.ReactNode>;

/**
 * Translate ids
 */
const TRANSLATE_IDS = {
  home: "navigation.mrooms",
  bonus: "navigation.bonus",
  profile: "navigation.profile",
  login: "navigation.login",
} as Indexed<string>;

interface NavigationProps {
  currentRoute: string;
  onLoginClick?: () => void;
}

const Navigation: React.FC<NavigationProps> = ({
  currentRoute,
  onLoginClick,
}) => {
  const loggedIn = useIsLoggedIn();

  const { localesWithBonus, localesWithBonusTeaser } =
    bonusConfig as BonusConfig;

  const unreadMessagesCount = useSelector(selectUnreadMessagesCount);
  const locale = useSelector(selectUserLocale);

  let routes = [] as string[];

  if (!loggedIn) {
    routes = ROUTES_FOR_GUESTS;
  } else {
    routes =
      localesWithBonus.includes(locale) ||
      localesWithBonusTeaser.includes(locale)
        ? ROUTES_WITH_BONUS
        : ROUTES_WITHOUT_BONUS;
  }

  const componentClass = classNames("c-navigation", {
    "has-under-three-items": routes.length < 3,
  });

  return (
    <div className={componentClass}>
      <div className="c-navigation__inner">
        {routes.map((route, index) => {
          const current = isRoot(currentRoute) ? RouteNames.Root : currentRoute;

          const transition = routes.includes(current)
            ? routes.indexOf(route) > routes.indexOf(current)
              ? "slide-right"
              : "slide-left"
            : "slide-left";

          return (
            <NavigationItem
              key={index}
              route={route}
              isCurrent={route === current}
              translateId={TRANSLATE_IDS[route]}
              icon={ICONS[route]}
              transition={transition}
              badgeCount={route === "profile" ? unreadMessagesCount : null}
              onClick={
                route === RouteNames.Login && onLoginClick
                  ? onLoginClick
                  : undefined
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;

interface NavigationItemProps {
  route: string;
  isCurrent: boolean;
  translateId: string;
  icon?: React.ReactNode;
  transition: "slide-left" | "slide-right";
  badgeCount?: number | null;
  onClick?: () => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  route,
  isCurrent,
  translateId,
  icon,
  transition,
  badgeCount,
  onClick,
}) => {
  const componentClass = classNames("c-navigation__item", {
    "is-current": isCurrent,
  });

  const wrapperClass = "c-navigation__item-wrapper";

  const content = (
    <>
      <div className={"c-navigation__icon"}>{icon}</div>
      <span className="c-navigation__text">
        <Translate id={translateId} />
      </span>
      {!!badgeCount && (
        <span className="c-navigation__badge">{badgeCount}</span>
      )}
    </>
  );

  if (isCurrent) {
    return (
      <div className={componentClass}>
        <div className={wrapperClass}>{content}</div>
      </div>
    );
  }

  if (onClick) {
    return (
      <div className={componentClass}>
        <button onClick={onClick} className={wrapperClass}>
          {content}
        </button>
      </div>
    );
  }

  return (
    <div className={componentClass}>
      <RouteLink
        to={isRoot(route) ? "/" : `/${route}`}
        transition={transition}
        className={wrapperClass}
      >
        {content}
      </RouteLink>
    </div>
  );
};
