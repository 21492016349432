import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Router, Switch, Route } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { AnimatePresence } from "framer-motion";
import mitt from "mitt";

/**
 * Utilities and Components
 */
import userConstants from "./constants/user";
import { getActiveOrderIdFromProfile } from "./services/user";
import history, { setCurrentPathname } from "./utils/navigation";
import Transitions from "./components/Transitions";
import PrivateRoute from "./components/PrivateRoute";
import Notification from "./components/Notification";
import TrackingButton from "./features/tracking/TrackingButton";
import Navigation from "./components/Navigation";
import TokenUpdater from "./components/TokenUpdater";

/**
 * Pages
 */
import Home from "./pages/Home";
import Barbershop from "./pages/Barbershop";
import Credits from "./pages/Credits";
import EmailPermission from "./pages/EmailPermission";
import Login from "./pages/Login";
import Messages from "./pages/Messages";
import NotificationSettings from "./pages/NotificationSettings";
import OrderHistory from "./pages/OrderHistory";
import PageNotFound from "./pages/PageNotFound";
import Profile from "./pages/Profile";
import Receipt from "./pages/Receipt";
import Recovery from "./pages/Recovery";
import Register from "./pages/Register";
import UpdateLanguage from "./pages/UpdateLanguage";
import UpdatePassword from "./pages/UpdatePassword";
import Welcome from "./pages/Welcome";
import Tracking from "./pages/Tracking";
import Bonus from "./pages/Bonus";
import EmailSettings from "./pages/EmailSettings";
import InitialRouteSelector from "./pages/InitialRouteSelector";

/**
 * Translations
 */
import finnish from "./translations/fi.json";
import english from "./translations/en.json";
import norwegian from "./translations/no.json";
import swedish from "./translations/sv.json";
import danish from "./translations/dk.json";
import german from "./translations/de.json";

/**
 * Global State
 */
import { syncUserProfile } from "./ducks/user";
import LoginDialog from "./components/LoginDialog";
import Modal from "./components/Modal";

/**
 * Constants
 */
const ROUTES_WITH_NAVIGATION_BAR = [
  "root",
  "home",
  "search",
  "profile",
  "list", // @deprecated,
  "bonus",
  "tracking",
  "barbershop",
];

const TRACKING_BUTTON_EXCLUDED_ROUTES = ["login", "tracking"];

class App extends React.Component {
  constructor(props) {
    super(props);

    const allowedLanguages = userConstants.ALLOWED_LANGUAGES;
    const localLanguage = localStorage.getItem("language");
    const userLanguage = allowedLanguages.includes(localLanguage)
      ? localLanguage
      : "fi";

    this.props.initialize({
      languages: [
        { name: "Suomi", code: "fi" },
        { name: "English", code: "en" },
        // { name: "Norsk", code: "no" },
        // { name: "Svenska", code: "sv" },
        // { name: "Dansk", code: "dk" },
        // { name: "Deutsch", code: "de" },
      ],
      options: {
        defaultLanguage: userLanguage,
        renderToStaticMarkup,
      },
    });

    this.props.addTranslationForLanguage(finnish, "fi");
    this.props.addTranslationForLanguage(english, "en");
    this.props.addTranslationForLanguage(norwegian, "no");
    this.props.addTranslationForLanguage(swedish, "sv");
    this.props.addTranslationForLanguage(danish, "dk");
    this.props.addTranslationForLanguage(german, "de");

    this.state = {
      hasActiveOrder: this.hasActiveOrder(props.user.loggedIn),
      activeLocation: this.locationToClass(history.location.pathname),
      showLoginForm: false,
      // isMapInViewport: history.location.pathname === "/home", // @deprecated
    };

    // setup an event emitter for cross component communication
    window.emitter = mitt();

    this.closeLoginForm = this.closeLoginForm.bind(this);
  }

  componentDidMount() {
    history.listen((location) => {
      this.setState(() => ({
        activeLocation: this.locationToClass(location.pathname),
        // isMapInViewport: location.pathname === "/home", // @deprecated
      }));
    });

    this.props.actions.syncUserProfile();
    setCurrentPathname();
  }

  componentDidUpdate() {
    const hasActiveOrder = this.hasActiveOrder(this.props.user.loggedIn);

    if (hasActiveOrder !== this.state.hasActiveOrder) {
      this.setState(() => ({
        hasActiveOrder,
      }));
    }
  }

  locationToClass(location) {
    if (typeof location !== "string") {
      return false;
    }

    if ("/" === location) {
      return "root";
    }

    const slashToDash = location.replace(/^\/|\/$/g, "-");
    const noInitialDash = slashToDash.replace(/^-/, "");

    return noInitialDash;
  }

  hasActiveOrder(loggedIn) {
    if (!loggedIn) return false;

    const id = getActiveOrderIdFromProfile();
    return typeof id !== "undefined" && id !== null;
  }

  showNavigation() {
    /* This checks if the current location starts with any of the routes in the array
     * If it does, it returns true, otherwise false
     * Since we want to show the bar on shop pages and shops are barbershop/{id} we need to check if the current location starts with barbershop
     */
    let matchFound =
      ROUTES_WITH_NAVIGATION_BAR.filter((item) =>
        this.state.activeLocation.startsWith(item)
      ).length > 0;

    return matchFound;
    // Deprecated old one
    //return ROUTES_WITH_NAVIGATION_BAR.includes(this.state.activeLocation);
  }

  showTrackingButton() {
    return (
      this.state.hasActiveOrder &&
      !TRACKING_BUTTON_EXCLUDED_ROUTES.includes(this.state.activeLocation)
    );
  }

  closeLoginForm() {
    this.setState(() => ({
      showLoginForm: false,
    }));
  }

  render() {
    const appClass = classNames("app", {
      "show-tracking-button": this.showTrackingButton(),
    });

    const overlayClass = classNames({
      app__overlay: true,
      "show-navigation": this.showNavigation(),
      "show-notification": this.props.notification.show,
      "show-tracking-button": this.showTrackingButton(),
      // "show-map": this.state.isMapInViewport, // @deprecated
    });

    return (
      <div className={appClass}>
        <div className="app__core">
          <Router history={history}>
            <Route
              render={({ location }) => (
                <Transitions pageKey={location.key} {...location.transition}>
                  <Switch location={location}>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/recovery" component={Recovery} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/welcome" component={Welcome} />
                    <Route
                      exact
                      path="/profile/language"
                      component={UpdateLanguage}
                    />

                    {/* <PrivateRoute exact path="/tutorial" component={Tutorial} /> */}

                    <Route exact path="/home" component={Home} />

                    <Route exact path="/barbershop" component={PageNotFound} />

                    <Route path="/barbershop/:id" component={Barbershop} />

                    <PrivateRoute exact path="/tracking" component={Tracking} />

                    <PrivateRoute exact path="/receipt" component={Receipt} />

                    <PrivateRoute exact path="/bonus" component={Bonus} />

                    <PrivateRoute
                      exact
                      path="/profile/messages"
                      component={Messages}
                    />
                    <PrivateRoute
                      exact
                      path="/profile/email"
                      component={EmailSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/profile/email-permission"
                      component={EmailPermission}
                    />
                    <PrivateRoute
                      exact
                      path="/profile/credits"
                      component={Credits}
                    />
                    <PrivateRoute
                      exact
                      path="/profile/password"
                      component={UpdatePassword}
                    />
                    <PrivateRoute
                      exact
                      path="/profile/notifications"
                      component={NotificationSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/profile/history"
                      component={OrderHistory}
                    />
                    <PrivateRoute exact path="/profile" component={Profile} />

                    <Route exact path="/" component={InitialRouteSelector} />

                    <Route component={PageNotFound} />
                  </Switch>
                </Transitions>
              )}
            />
          </Router>
        </div>

        <div className={overlayClass}>
          <Navigation
            currentRoute={this.state.activeLocation}
            onLoginClick={() => {
              this.setState(() => ({
                showLoginForm: true,
              }));
            }}
          />
          <AnimatePresence>
            {this.showTrackingButton() && <TrackingButton />}
          </AnimatePresence>
          <AnimatePresence>
            {this.state.showLoginForm && (
              <Modal
                valign="middle"
                contentPadding="tight"
                onBackgroundClick={this.closeLoginForm}
              >
                <LoginDialog
                  title={this.props.translate("general.loginFormTitle")}
                  onLinkClick={this.closeLoginForm}
                  onClose={this.closeLoginForm}
                  onComplete={this.closeLoginForm}
                />
              </Modal>
            )}
          </AnimatePresence>
          <Notification />
        </div>

        <TokenUpdater />
      </div>
    );
  }
}

App.propTypes = {
  notification: PropTypes.object,
  actions: PropTypes.object,
  initialize: PropTypes.func,
  addTranslationForLanguage: PropTypes.func,
  loggedIn: PropTypes.bool,
  orderCanceled: PropTypes.bool,
  orderCleared: PropTypes.bool,
  results: PropTypes.array,
  user: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const y = {
    orderCanceled: state.deprecated__order.orderCanceled,
    orderCleared: state.deprecated__order.orderCleared,
    notification: state.notification,
    user: {
      loggedIn: state.user.loggedIn,
      profileSynced: state.user.profileSynced,
    },
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        syncUserProfile,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(App));
