import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class SubOption extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.clickHandler(this.props);
    }

    render() {
        const componentClass = classNames({
            "c-suboptions__item": true,
            "is-selected": this.props.selected
        });

        return (
            <div className={componentClass} onClick={this.handleClick}>
                {this.props.title} {this.props.units && `– ${this.props.units}`}
                <i className="c-suboptions__icon" />
            </div>
        );
    }
}

SubOption.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    selected: PropTypes.bool,
    price: PropTypes.number,
    units: PropTypes.string,
    clickHandler: PropTypes.func
};

export default SubOption;
