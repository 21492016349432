import React from "react";
import ContentLoader from "react-content-loader";

import MinimapLink from "../../components/MinimapLink";
import { loaderTheme } from "../../utils/loaders";
import { Barbershop } from "../../types";

const COMPONENT_HEIGHT = 74;

interface BarbershopMapButtonProps {
  className?: string;
  barbershop: false | Barbershop;
  onClick?: () => void;
}

const BarbershopMapButton: React.FC<BarbershopMapButtonProps> = ({
  className,
  barbershop,
  onClick,
}) => {
  const { name, address } = barbershop || {};
  const hasData = !!name;

  return (
    <div className={className}>
      {hasData ? (
        <MinimapLink name={name} address={address} clickHandler={onClick} />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BarbershopMapButton;

const Loader = () => (
  <ContentLoader
    viewBox={`0 0 315 ${COMPONENT_HEIGHT}`}
    width={315}
    height={COMPONENT_HEIGHT}
    {...loaderTheme}
    style={{ width: "100%", display: "block" }}
    preserveAspectRatio="none"
  >
    <rect x="0" y="0" rx="5" ry="5" width="315" height={COMPONENT_HEIGHT} />
  </ContentLoader>
);
