import React from "react";

import { ExtraService } from "../../types";
import { priceToUnits } from "../../utils/Helpers";

interface ExtraServiceDisplayProps extends ExtraService {}

const ExtraServiceDisplay: React.FC<ExtraServiceDisplayProps> = ({
  title,
  price,
}) => {
  return (
    <div className="c-extra-service-display">
      <p className="c-extra-service-display__title">{`+ ${title}`}</p>
      <p className="c-extra-service-display__price">{priceToUnits(price)}</p>
    </div>
  );
};

export default ExtraServiceDisplay;
