import React from "react";
import parse from "html-react-parser";

import { Service } from "../../types";
import ServiceToggle from "./ServiceToggle";

export interface ServiceInfoProps extends Service {}

const ServiceInfo: React.FC<ServiceInfoProps> = ({
  longDescription,
  ...rest
}) => {
  const hasLongDescription = !!longDescription;

  return (
    <div className="c-service-info">
      <div className="c-service-info__toggle">
        <ServiceToggle {...rest} description={""} size={"large"} />
      </div>
      <div className="c-service-info__description h-std">
        {hasLongDescription && parse(longDescription)}
      </div>
    </div>
  );
};

export default ServiceInfo;
