import React from "react";
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";

const MRoomShopLink: React.FC<LocalizeContextProps> = ({ translate }) => {
  const url = translate("links.shopURL") as string;

  return (
    <div className="c-mroomshop-link">
      <h3 className="c-mroomshop-link__title">
        <Translate id="links.shopTitle" />
      </h3>
      <p className="c-mroomshop-link__description">
        <Translate id="links.shopDescription" />
      </p>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="c-mroomshop-link__link"
      >
        <Translate id="links.shopText" />
      </a>
    </div>
  );
};

export default withLocalize(MRoomShopLink);
