import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BackButton from "../components/LegacyBackButton";
import OptionListing from "../components/OptionListing";
import { withLocalize } from "react-localize-redux";

class Receipt extends React.Component {
  constructor(props) {
    super(props);

    this.titleSet = false;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // set page title
    if (typeof newProps.activeLanguage !== "undefined" && !this.titleSet) {
      document.title =
        newProps.translate("title.base") +
        newProps.translate("title.separator") +
        newProps.translate("title.receipt");
      this.titleSet = true;
    }
  }

  buildServices(selected) {
    return selected.map((service) => {
      // map out all the selected options tied to the service
      return service.options.map((option, index) => {
        return (
          <OptionListing
            key={index}
            title={option.name}
            description={option.description}
            price={option.price}
            extras={option.extras}
          />
        );
      });
    });
  }

  render() {
    const services = this.buildServices(this.props.selected);

    return (
      <div className="s-receipt h-scrollable">
        <div className="s-receipt__back">
          <BackButton transition="switch-close" />
        </div>

        <div className="s-receipt__content">
          <div className="s-receipt__details">
            <h1 className="s-receipt__title">Kuitti</h1>
            <h3>M Room Kamppi</h3>
            <p>Lönnrotinkatu 3</p>
            <h3>Liitytty jonoon</h3>
            <p>20.4.2018, klo 18.30</p>
          </div>

          <div className="s-receipt__order">
            <h2 className="s-receipt__subtitle">Tilauksesi</h2>

            <div className="s-receipt__list">{services}</div>
          </div>
        </div>
      </div>
    );
  }
}

Receipt.propTypes = {
  selected: PropTypes.array,
  translate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const y = {
    selected: state.deprecated__order.selected,
  };

  return y;
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Receipt));
