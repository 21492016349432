import orderConstants from "../constants/order";
import * as orderService from "../services/order";
import ApiConstants from "../constants/api";

import { analytics } from "../utils/Analytics";

/**
 * Actions
 */
// Moved to orderSlice
export const FETCH_BARBERSHOP_REQUEST = "FETCH_BARBERSHOP_REQUEST";
export const FETCH_BARBERSHOP_SUCCESS = "FETCH_BARBERSHOP_SUCCESS";
export const FETCH_BARBERSHOP_FAILURE = "FETCH_BARBERSHOP_FAILURE";

// @deprecated
// export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
// export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
// export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

// @todo This receipt stuff should just be done in the component,
// no need for Redux.
export const FETCH_RECEIPTS_REQUEST = "FETCH_RECEIPTS_REQUEST";
export const FETCH_RECEIPTS_SUCCESS = "FETCH_RECEIPTS_SUCCESS";
export const FETCH_RECEIPTS_FAILURE = "FETCH_RECEIPTS_FAILURE";

// @todo Should be moved to orderTrackingSlice (updateOrder)
export const CHECK_STATUS_REQUEST = "CHECK_STATUS_REQUEST";
export const CHECK_STATUS_SUCCESS = "CHECK_STATUS_SUCCESS";
export const CHECK_STATUS_FAILURE = "CHECK_STATUS_FAILURE";

// Moved to orderSlice
export const ADD_SERVICE = "ADD_SERVICE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const ADD_EXTRA_SERVICE = "ADD_EXTRA_SERVICE";
export const REMOVE_EXTRA_SERVICE = "REMOVE_EXTRA_SERVICE";

// @todo Should be moved to orderSlice
export const SEND_ORDER_REQUEST = "SEND_ORDER_REQUEST";
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS";
export const SEND_ORDER_FAILURE = "SEND_ORDER_FAILURE";

// @todo Should be moved to orderTrackingSlice
export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

// @todo Should be moved to orderTrackingSlice
export const CHECK_IN_REQUEST = "CHECK_IN_REQUEST";
export const CHECK_IN_SUCCESS = "CHECK_IN_SUCCESS";
export const CHECK_IN_FAILURE = "CHECK_IN_FAILURE";

// @todo Figure out what this was for....
// Most likely no need for this to be in Redux
export const TRIGGER_QUEUE_CONFIRMATION_ANALYTICS =
  "TRIGGER_QUEUE_CONFIRMATION_ANALYTICS";

// Moved to orderSlice
export const CLEAR_BARBERSHOP = "CLEAR_BARBERSHOP";
export const CLEAR_SERVICES = "CLEAR_SERVICES";
export const CLEAR_ORDER = "CLEAR_ORDER";

// Not needed / moved to orderSlice
export const RESET_ORDER_STATE = "RESET_ORDER_STATE";
export const CLEAR_ORDER_ERROR = "CLEAR_ORDER_ERROR";

/**
 * Action creators
 */
export function fetchBarbershop(id, refreshing = false) {
  return function (dispatch) {
    dispatch(request());

    orderService.deprecated__getBarbershop(id).then(
      (barbershop) => {
        dispatch(success(barbershop));
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return {
      type: FETCH_BARBERSHOP_REQUEST,
      payload: {
        refreshing,
      },
    };
  }
  function success(response) {
    return { type: FETCH_BARBERSHOP_SUCCESS, barbershop: response };
  }
  function failure(response) {
    return { type: FETCH_BARBERSHOP_FAILURE, error: response };
  }
}

export function fetchReceipts() {
  return function (dispatch) {
    dispatch(request());

    orderService.getReceipts().then(
      (receipts) => {
        dispatch(success(receipts));
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return { type: FETCH_RECEIPTS_REQUEST };
  }
  function success(response) {
    return { type: FETCH_RECEIPTS_SUCCESS, receipts: response };
  }
  function failure() {
    return { type: FETCH_RECEIPTS_FAILURE };
  }
}

export function checkOrderStatus(orderId = false) {
  return function (dispatch) {
    dispatch(request());

    if (orderId) {
      // if orderId is provided get the individual order details
      orderService.deprecated__getOrder(orderId).then(
        (order) => {
          orderService.deprecated__saveOrder(order);

          dispatch(success(order));
        },
        (errors) => {
          dispatch(failure(errors));
        }
      );
    } else {
      // if no orderId provided, get the latest order of the user
      orderService.deprecated__getOrders("", true).then(
        (orders) => {
          if (orders.length) {
            orderService.deprecated__saveOrder(orders[0]);
            dispatch(success(orders[0]));
          } else {
            dispatch(success());
          }
        },
        (errors) => {
          dispatch(failure(errors));
        }
      );
    }
  };

  function request() {
    return { type: CHECK_STATUS_REQUEST };
  }
  function success(response) {
    return { type: CHECK_STATUS_SUCCESS, order: response };
  }
  function failure() {
    return { type: CHECK_STATUS_FAILURE };
  }
}

export function clearOrderError(error) {
  return { type: CLEAR_ORDER_ERROR, error: error };
}

export function resetOrderState() {
  return { type: RESET_ORDER_STATE };
}

export function addService(data) {
  return { type: ADD_SERVICE, service: data };
}

export function removeService(data) {
  return { type: REMOVE_SERVICE, service: data };
}

export function addExtraService(data) {
  return { type: ADD_EXTRA_SERVICE, service: data };
}

export function removeExtraService(data) {
  return { type: REMOVE_EXTRA_SERVICE, service: data };
}

export function sendOrder(barbershop, selected) {
  return function (dispatch) {
    dispatch(request());

    orderService.deprecated__sendOrder(barbershop, selected).then(
      (order) => {
        orderService.deprecated__saveOrder(order, barbershop);
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: SEND_ORDER_REQUEST };
  }
  function success() {
    return { type: SEND_ORDER_SUCCESS };
  }
  function failure(response) {
    return { type: SEND_ORDER_FAILURE, error: response };
  }
}

export function cancelOrder(orderId) {
  return function (dispatch) {
    dispatch(request());

    orderService.deprecated__cancelOrder(orderId).then(
      (response) => {
        orderService.deprecated__clearOrder();

        dispatch(success());
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return { type: CANCEL_ORDER_REQUEST };
  }
  function success() {
    return { type: CANCEL_ORDER_SUCCESS };
  }
  function failure() {
    return { type: CANCEL_ORDER_FAILURE };
  }
}

export function checkIn(orderId) {
  return function (dispatch) {
    dispatch(request());

    orderService.checkIn(orderId).then(
      (response) => {
        dispatch(success());
      },
      (errors) => {
        dispatch(failure(errors));
      }
    );
  };

  function request() {
    return { type: CHECK_IN_REQUEST };
  }
  function success() {
    return { type: CHECK_IN_SUCCESS };
  }
  function failure() {
    return { type: CHECK_IN_FAILURE };
  }
}

export function triggerQueueConfirmationAnalytics(barbershop, order) {
  analytics.deprecated__queueConfirmation(barbershop, order);

  return { type: TRIGGER_QUEUE_CONFIRMATION_ANALYTICS };
}

export function clearBarbershop() {
  return { type: CLEAR_BARBERSHOP };
}

export function clearServices() {
  return { type: CLEAR_SERVICES };
}

export function clearOrder() {
  return function (dispatch) {
    orderService.deprecated__clearOrder();
    dispatch(success());
  };

  function success() {
    return { type: CLEAR_ORDER };
  }
}

/**
 * Initial state of the store
 */

export const initialState = {
  orders: [],
  receipts: [],
  barbershop: {},
  selected: [],

  active: false,
  statusChecked: false,
  statusError: false,

  errors: {
    token: false,
    barbershop: false,
    timeSlot: false,
    sendOrder: false,
  },
  errorMessage: "",

  hasSelected: false,
  orderReceived: false,
  orderCanceled: false,
  orderCleared: false,
  checkedIn: false,
  triggerQueueConfirmationAnalytics: false, // this is a nasty hack we have to do because POST order doesn't return creation date

  loading: false,
};

/**
 * Reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BARBERSHOP_REQUEST: {
      const y = Object.assign({}, state);

      if (!action.payload.refreshing) {
        y.loading = true;
      }

      y.errors.barbershop = false;

      return y;
    }

    case FETCH_BARBERSHOP_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.errors.barbershop = true;

      return y;
    }

    case FETCH_BARBERSHOP_SUCCESS: {
      const y = Object.assign({}, state);

      y.barbershop = action.barbershop;
      y.loading = false;
      y.errors.barbershop = false;

      return y;
    }

    // case FETCH_ORDERS_REQUEST: {
    //   const y = Object.assign({}, state);

    //   y.loading = true;

    //   return y;
    // }

    // case FETCH_ORDERS_FAILURE: {
    //   const y = Object.assign({}, state);

    //   y.loading = false;

    //   return y;
    // }

    // case FETCH_ORDERS_SUCCESS: {
    //   const y = Object.assign({}, state);

    //   y.orders = action.orders;
    //   y.loading = false;

    //   return y;
    // }

    case FETCH_RECEIPTS_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;

      return y;
    }

    case FETCH_RECEIPTS_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;

      return y;
    }

    case FETCH_RECEIPTS_SUCCESS: {
      const y = Object.assign({}, state);

      y.receipts = action.receipts;
      y.loading = false;

      return y;
    }

    case CHECK_STATUS_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;
      y.statusChecked = false;
      y.statusError = false;

      return y;
    }

    case CHECK_STATUS_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.statusChecked = false;
      y.statusError = true;

      return y;
    }

    case CHECK_STATUS_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.statusChecked = true;

      // set or clear active order
      if (
        action.order &&
        action.order.status === orderConstants.status.ACTIVE
      ) {
        y.active = action.order;

        // mark user checked in or not
        y.checkedIn = action.order && action.order.checkedIn;
      } else {
        y.active = false;
      }

      return y;
    }

    case TRIGGER_QUEUE_CONFIRMATION_ANALYTICS: {
      const y = Object.assign({}, state);

      y.triggerQueueConfirmationAnalytics = false;

      return y;
    }

    case ADD_SERVICE: {
      const y = Object.assign({}, state);

      // make a copy to mutate
      let x = [...y.selected];

      // let's see if the service already has selected options
      const service = x.findIndex(
        (service) => service.id === action.service.id
      );

      // Add or replace if service is selected
      if (service > -1) {
        if (action.service.allowMultiple) {
          x[service].options = [...x[service].options, action.service.option];
        } else {
          x[service].options = [action.service.option];
        }
      } else {
        x = [
          ...x,
          {
            id: action.service.id,
            name: action.service.name,
            options: [action.service.option],
          },
        ];
      }

      // add back to main object
      y.selected = x;

      y.hasSelected = true;

      return y;
    }

    case REMOVE_SERVICE: {
      const y = Object.assign({}, state);

      // make a copy to mutate
      let x = [...y.selected];

      // make sure the service is selected
      const service = x.findIndex(
        (service) => service.id === action.service.id
      );

      // remove from array if we have a match
      if (service > -1) {
        if (action.service.allowMultiple) {
          // make a copy to mutate
          let z = [...x[service].options];

          // find out which option to remove
          const option = z.findIndex(
            (option) => option.id === action.service.option
          );

          z.splice(option, 1);

          if (!z.length) {
            // remove service if last selected
            x.splice(service, 1);
          } else {
            // otherwise add back to main object
            x[service].options = z;
          }
        } else {
          x.splice(service, 1);
        }
      }

      // add back to main object
      y.selected = x;

      y.hasSelected = y.selected.length ? true : false;

      return y;
    }

    case ADD_EXTRA_SERVICE: {
      const y = Object.assign({}, state);

      // make a copy to mutate
      let x = [...y.selected];

      // let's find the parent service
      const service = x.findIndex(
        (service) => service.id === action.service.id
      );

      // add to extras if service is selected
      if (service > -1) {
        // make a copy to mutate
        let z = [...x[service].options];

        // find out which option to add the extra to
        const option = z.findIndex(
          (option) => option.id === action.service.option
        );

        z[option].extras = [
          ...z[option].extras,
          {
            id: action.service.extra.id,
            name: action.service.extra.title,
            price: action.service.extra.price,
          },
        ];

        // add back to main object
        x[service].options = z;
      }

      // add back to main object
      y.selected = x;

      return y;
    }

    case REMOVE_EXTRA_SERVICE: {
      const y = Object.assign({}, state);

      // make a copy to mutate
      let x = [...y.selected];

      // let's find the parent service
      const service = x.findIndex(
        (service) => service.id === action.service.id
      );

      // remove from extras if service is selected
      if (service > -1) {
        // make a copy to mutate
        let z = [...x[service].options];

        // find out which option to add the extra to
        const option = z.findIndex(
          (option) => option.id === action.service.option
        );

        z[option].extras = z[option].extras.filter((extra) => {
          return extra.id !== action.service.extra.id;
        });

        // add back to main object
        x[service].options = z;
      }

      // add back to main object
      y.selected = x;

      return y;
    }

    case CLEAR_BARBERSHOP: {
      const y = Object.assign({}, state);

      y.barbershop = {};
      y.selected = [];

      y.errors.token = false;
      y.errors.barbershop = false;
      y.errors.timeslot = false;
      y.errors.sendOrder = false;

      y.hasSelected = false;
      y.orderReceived = false;
      y.orderCanceled = false;
      y.checkedIn = false;

      return y;
    }

    case CLEAR_SERVICES: {
      const y = Object.assign({}, state);

      y.selected = [];
      y.hasSelected = false;
      y.orderReceived = false;

      return y;
    }

    case SEND_ORDER_REQUEST: {
      const y = Object.assign({}, state);

      y.orderCanceled = false;
      y.orderCleared = false;
      y.triggerQueueConfirmationAnalytics = false;
      y.loading = true;

      return y;
    }

    case SEND_ORDER_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.orderReceived = true;
      y.triggerQueueConfirmationAnalytics = true;

      return y;
    }

    case SEND_ORDER_FAILURE: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.triggerQueueConfirmationAnalytics = false;

      if (action.error && action.error.data) {
        const errorMessage = action.error.data.error;

        // There's no more room in the barbershop for all the services
        if (errorMessage === ApiConstants.MROOM_API_ERROR_TIMESLOT) {
          y.errors.timeSlot = true;
        } else {
          y.errors.sendOrder = true;
        }

        y.errorMessage = errorMessage;
      } else {
        y.errors.sendOrder = true;
      }

      return y;
    }

    case CANCEL_ORDER_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;

      return y;
    }

    case CANCEL_ORDER_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.barbershop = {};
      y.selected = [];
      y.active = false;
      y.checkedIn = false;
      y.hasSelected = false;
      y.statusChecked = false;
      y.orderReceived = false;
      y.orderCanceled = true;
      y.orderCleared = false;

      return y;
    }

    case CHECK_IN_REQUEST: {
      const y = Object.assign({}, state);

      y.loading = true;

      return y;
    }

    case CHECK_IN_SUCCESS: {
      const y = Object.assign({}, state);

      y.loading = false;
      y.checkedIn = true;

      return y;
    }

    case CLEAR_ORDER: {
      let y = Object.assign({}, state);

      y.barbershop = {};
      y.selected = [];
      y.hasSelected = false;
      y.orderReceived = false;
      y.orderCanceled = false;
      y.orderCleared = true;
      y.checkedIn = false;
      y.loading = false;

      return y;
    }

    case RESET_ORDER_STATE: {
      let y = Object.assign({}, state);

      y = initialState;

      return y;
    }

    case CLEAR_ORDER_ERROR: {
      const y = Object.assign({}, state);

      // if resetting specific orders
      if (action.error) {
        y.errors = {
          ...y.errors,
          ...action.error,
        };
      } else {
        y.errors.token = false;
        y.errors.barbershop = false;
        y.errors.timeSlot = false;
        y.errors.sendOrder = false;
      }

      y.errorMessage = "";

      return y;
    }

    default:
      return state;
  }
}

/**
 * Selector to return state only
 */
export const filterOrders = (state) => {
  // create a new copy of orders to mutate
  let orders = [...state.orders];

  // sort the orders
  orders.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return orders;
};

export const sortReceipts = (state) => {
  // create a new copy of orders to mutate
  const receipts = [...state.receipts];

  // sort the receipts
  receipts.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return receipts;
};
