/**
 * External dependencies
 */
import React from "react";
import { Translate } from "react-localize-redux";

/**
 * Internal dependencies
 */
import { navigateTo } from "../utils/navigation";

interface BackButtonProps {
  fallbackRoute?: string;
  transition?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
  fallbackRoute,
  transition = "switch-close",
}) => {
  const previousPathname = window.previousPathname;
  const currentPathname = window.currentPathname;

  const handleClick = () => {
    /**
     * Need to do some dirty work here to make sure the back button works as expected.
     * If we jump from register to barbershop, we want to go back to frontpage not back to register when pressing backbutton, pretty edge case but still.
     * Same goes with recorevy page, we are kind of in a limbo state there, so we want to go back to frontpage.
     */

    if (
      previousPathname === "/register" ||
      (previousPathname === "/recovery" &&
        currentPathname.startsWith("/barbershop"))
    ) {
      navigateTo(fallbackRoute, transition);
    } else if (previousPathname && previousPathname !== currentPathname) {
      navigateTo(previousPathname, transition);
    } else if (fallbackRoute) {
      navigateTo(fallbackRoute, transition);
    }
  };

  return (
    <button className="c-back-button" onClick={handleClick}>
      <Translate id="navigation.back" />
    </button>
  );
};

export default BackButton;
