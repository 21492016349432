import React from "react";

import { Service, ServiceGroup, RenderComponent } from "../../types";
import { useServiceGroupServices } from "./use-service-group-services";

interface ServiceGroupContainerProps extends ServiceGroup {
  data: Service[];
  render: (service: Service, index?: number) => RenderComponent;
}

const ServiceGroupContainer: React.FC<ServiceGroupContainerProps> = ({
  id,
  title,
  description,
  data,
  render,
}) => {
  const services = useServiceGroupServices(id, data);

  return (
    <div className="c-service-group-container">
      <div className="c-service-group-container__header">
        <h2 className="c-service-group-container__title">{title}</h2>
        <p className="c-service-group-container__description">{description}</p>
      </div>

      <ul className="c-service-group-container__services">
        {services.map((service, index) => (
          <li key={service.id} className="c-service-group-container__service">
            {render(service, index)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceGroupContainer;
