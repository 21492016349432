import React from "react";
import { TranslateString } from "../types";

interface SliderGroupProps {
  title?: TranslateString;
  description?: TranslateString;
}

const SliderGroup: React.FC<SliderGroupProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="c-slider-group">
      {!!title && <h2 className="c-slider-group__title">{title}</h2>}

      <div className="c-slider-group__slider">{children}</div>

      {!!description && (
        <p className="c-slider-group__description">{description}</p>
      )}
    </div>
  );
};

export default SliderGroup;
