import React, { Children } from "react";
import { useKeenSlider } from "keen-slider/react";

interface VariableWidthSliderProps {
  gutter?: number;
  spacing?: number;
}

const VariableWidthSlider: React.FC<VariableWidthSliderProps> = ({
  gutter = 25,
  spacing = 8,
  children,
}) => {
  const arrayChildren = Children.toArray(children);

  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: "free-snap",
    selector: ".c-variable-width-slider__slide",
    rtl: false,
    slides: { perView: "auto", spacing },
  });

  return (
    <div
      className="c-variable-width-slider"
      style={{ paddingLeft: gutter, paddingRight: gutter }}
    >
      <div ref={sliderRef} className="keen-slider">
        {arrayChildren.map((child, index) => (
          <div key={index} className="c-variable-width-slider__slide">
            <div
              className="c-variable-width-slider__slide-inner"
              style={{
                paddingRight: index === arrayChildren.length - 1 ? gutter : 0,
              }}
            >
              {child}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VariableWidthSlider;
