import { useEffect, useRef, useCallback } from "react";

export type Delay = number | null;
export type TimerHandler = (...args: any[]) => void;

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */

export const useInterval = (callback: TimerHandler, delay: Delay) => {
  const savedCallbackRef = useRef<TimerHandler>();
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      intervalIdRef.current = setInterval(handler, delay);
      return () => {
        if (intervalIdRef.current !== null) {
          clearInterval(intervalIdRef.current);
        }
      };
    }
  }, [delay]);

  const refresh = useCallback(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      if (intervalIdRef.current !== null) {
        clearInterval(intervalIdRef.current);
      }

      handler();

      intervalIdRef.current = setInterval(handler, delay);
    }
  }, [delay]);

  const clear = useCallback(() => {
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current);
    }
  }, []);

  return { refresh, clear };
};
