import { Barbershop, IndexedUncertainNumbers } from "../../types";

export const sortBarbershopsByDistances = (
  barbershops: Barbershop[],
  distances: IndexedUncertainNumbers
): Barbershop[] => {
  const arr = [...barbershops];

  arr.sort((a, b) => {
    const distA: number | null = distances[a.id];
    const distB: number | null = distances[b.id];

    if (distA === distB) {
      return sortByCity(a, b);
    }

    if (typeof distA !== "number") {
      return 1;
    }

    if (typeof distB !== "number") {
      return -1;
    }

    return distA < distB ? -1 : 1;
  });

  return arr;
};

export const sortByCity = (a: Barbershop, b: Barbershop) => {
  if (a.city < b.city) {
    return -1;
  }
  if (a.city > b.city) {
    return 1;
  }
  return sortByName(a, b);
};

export const sortByName = (a: Barbershop, b: Barbershop) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const searchBarbershops = (
  query: string,
  barbershops: Barbershop[]
): Barbershop[] => {
  if (query === "") {
    return barbershops;
  }

  return barbershops.filter(({ name, address, city }) => {
    const searchTargets = [
      name.toLowerCase(),
      address.toLowerCase(),
      city.toLowerCase(),
    ];

    return searchTargets.some((value) => value.includes(query.toLowerCase()));
  });
};
